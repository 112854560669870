/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import initialState from 'store/initialState';

export const storesSlice = createSlice({
  name: 'stores',
  initialState: initialState.stores,
  reducers: {
    initStores: (state) => {
      state.list = state?.list || [];
      state.nearests = state?.nearests || [];
      state.error = '';
      state.loaded = false;
      state.loading = true;
    },
    successStores: (state, action) => {
      state.list = action.payload?.list || [];
      state.nearests = action.payload?.nearests || [];
      state.loaded = true;
      state.loading = false;
    },
    setCoords: (state, action) => {
      state.coords = action.payload;
    },
    initStore: (state) => {
      state.store = null;
      state.loaded = false;
      state.loading = true;
    },
    successStore: (state, action) => {
      state.store = action.payload;
      state.loaded = true;
      state.loading = false;
    },
    error: (state, action) => {
      state.error = action.payload;
      state.loaded = true;
      state.loading = false;
    },
    clearError: (state) => {
      state.error = '';
    },
    clearStore: (state) => {
      state.store = null;
    },
    clear: (state) => {
      state.searchCoords = null;
      state.list = [];
      state.nearests = [];
      state.selected = null;
      state.coords = {
        latitude: null,
        longitude: null,
      };
      state.error = '';
      state.loaded = false;
      state.loading = false;
    },
  },
});

export const storesActions = storesSlice.actions;

export default storesSlice.reducer;
