/* eslint-disable import/no-unresolved */
import PropTypes from 'prop-types';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation } from 'swiper/modules';
import 'swiper/css/navigation';
import 'swiper/css';
import './styles.scss';

const Carousel = (props) => {
  const {
    slides,
    className,
    showNext,
    autoPlay,
    autoHeight,
    ...rest
  } = props;

  return (
    <div>
      <Swiper
        className={`carousel ${className} ${showNext ? 'show-next-slide' : ''}`}
        slidesPerView={1}
        spaceBetween={7}
        autoHeight={autoHeight}
        autoplay={autoPlay || false}
        modules={[Autoplay, Navigation]}
        navigation={showNext}
        {...rest}
      >
        {slides.map((slide, index) => (
          <SwiperSlide key={index}>
            {slide}
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

Carousel.displayName = 'Carousel';
Carousel.propTypes = {
  slides: PropTypes.array.isRequired,
  className: PropTypes.string,
  showNext: PropTypes.bool,
  autoHeight: PropTypes.bool,
  autoPlay: PropTypes.object,
};

Carousel.defaultProps = {
  className: '',
  showNext: false,
  autoHeight: true,
  autoPlay: null,
};

export default Carousel;
