/* eslint-disable no-unused-vars */
import ApiFetch from 'utils/apiFetch';

const ApiFetchInstance = ApiFetch();

export const URLS = {
  vehiclesYears: '/valuations/vehicles/years',
  vehiclesBrands: '/valuations/vehicles/brands',
  vehiclesModels: '/valuations/vehicles/models',
  vehiclesVersions: '/valuations/vehicles/versions',
  vehiclesPrices: '/valuations/vehicles/prices',
  vehiclesValuation: '/valuations/vehicles/valuation',
  products: '/valuations/products',
  simmulate: '/valuations/vehicles/loans/simulate',
};

export default {
  async getVehiclesYears() {
    return ApiFetchInstance.get(URLS.vehiclesYears);
  },
  async getVehiclesBrands(year) {
    return ApiFetchInstance.get(`${URLS.vehiclesBrands}?year=${year}`);
  },
  async getVehiclesModels(year, brand) {
    return ApiFetchInstance.get(`${URLS.vehiclesModels}?year=${year}&brand_id=${brand}`);
  },
  async getVehiclesVersions(year, brand, model) {
    return ApiFetchInstance.get(`${URLS.vehiclesVersions}?year=${year}&brand_id=${brand}&model_id=${model}`);
  },
  async getVehiclesPrices(year, brand, model, version) {
    return ApiFetchInstance.get(`${URLS.vehiclesPrices}?year=${year}&brand_id=${brand}&model_id=${model}&version_id=${version}`);
  },
  async getVehiclesValuation(year, brand, model, version, product, productType, productClass, term) {
    return ApiFetchInstance.get(`${URLS.vehiclesValuation}?year=${year}&brand_id=${brand}&model_id=${model}&version_key=${version}&product_id=${product}&product_type=${productType}&product_class=${productClass}&term=${term}`);
  },
  async getVehicleSimulate(amount, maxTerm, versionId, productId, officeId) {
    return ApiFetchInstance.get(`${URLS.simmulate}?amount=${amount}&max_term=${maxTerm}&version_id=${versionId}&product_id=${productId}&office_id=${officeId}`);
  },
  async getProducts(officeId) {
    return ApiFetchInstance.get(`${URLS.products}?office_id=${officeId}`);
  },
};
