import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { ROUTE_PATH } from 'routes';
import { createPassword } from 'modules/session/actions';
import { isPasswordValid } from 'utils/validations';
import Layout from 'components/Layout';
import Button from 'components/UI/Button';
import SuccessModal from 'components/FeedbackModals/SuccessModal';
import ErrorModal from 'components/FeedbackModals/ErrorModal';
import InputPassword from 'components/UI/InputPassword';
import { validatePassword } from 'utils/validatePassword';
import PasswordRuleList from 'components/PasswordRuleList';

function ResetPassword({ literals }) {
  const { isApp } = useSelector((state) => state.app);
  const [newPassword, setNewPassword] = useState('');
  const [repeatNewPassword, setRepeatNewPassword] = useState('');
  const [sending, setSending] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const { search } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const token = new URLSearchParams(search).get('token');
    if (!token) {
      navigate(ROUTE_PATH.LOGIN);
    }
  }, [navigate, search]);

  const createPasswordHandler = () => {
    setSending(true);
    const token = new URLSearchParams(search).get('token');
    createPassword(token, newPassword)
      .then((success) => {
        if (success) {
          setShowSuccessModal(true);
        } else {
          setShowErrorModal(true);
        }
      })
      .catch(() => {
        setShowErrorModal(true);
      })
      .finally(() => {
        setSending(false);
      });
  };

  const handleCloseSuccessModal = () => {
    setShowSuccessModal(false);
    navigate(ROUTE_PATH.LOGIN);
  };

  const validation = validatePassword(newPassword, repeatNewPassword);

  return (
    <>
      <Layout
        onBack={isApp ? true : null}
        rightMenu={isApp}
      >
        <h1 className='my-3'>{literals.title}</h1>
        <InputPassword
          name='newPassword'
          className='mt-4'
          placeholder={literals.createPassword}
          label={literals.createPassword}
          value={newPassword}
          onChange={setNewPassword}
          required
          error={(newPassword && !isPasswordValid(newPassword)) ? literals.invalidPassword : ''}
        />
        <InputPassword
          name='repeatNewPassword'
          className='my-4'
          placeholder={literals.newPassword}
          label={literals.newPassword}
          value={repeatNewPassword}
          onChange={setRepeatNewPassword}
          required
          error={repeatNewPassword && (newPassword !== repeatNewPassword) ? literals.passwordsDontMatch : ''}
        />
        <p className='mt-4'>{literals.passwordRules.title}</p>
        <PasswordRuleList validation={validation} />
        <div className='pt-5 mb-5'>
          <Button
            disabled={!validation.success}
            loading={sending}
            onClick={createPasswordHandler}
            text={literals.common.continue}
          />
        </div>
      </Layout>
      {showSuccessModal && (
        <SuccessModal
          description={literals.successDescription}
          onCloseNavigateRoute={ROUTE_PATH.LOGIN}
          onClose={handleCloseSuccessModal}
        >
          <Button
            className='mt-3'
            text={literals.common.continue}
            onClick={handleCloseSuccessModal}
          />
        </SuccessModal>
      )}
      {showErrorModal && (
        <ErrorModal
          description={errorMessage || literals.errorDescription}
          onClose={() => {
            setErrorMessage('');
            setShowErrorModal(false);
          }}
        />
      )}
    </>
  );
}

ResetPassword.propTypes = {
  literals: PropTypes.object.isRequired,
};

/* *************************************** */
/* ********       CONTAINER       ******** */
/* *************************************** */

function mapStateToProps(state) {
  return {
    literals: {
      ...state.i18n.literals.auth.resetPassword,
      common: {
        ...state.i18n.literals.common,
      },
    },
  };
}

export default connect(mapStateToProps)(ResetPassword);
