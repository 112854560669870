import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useLiterals from 'utils/hooks/useLiterals';
import Layout from 'components/Layout';
import { getContracts } from 'modules/contracts/actions';
import { getSections } from 'modules/sections/actions';
import { ROUTE_PATH } from 'routes';
import { renderIconCategories } from 'components/PaymentBox';
import documentIcon from 'assets/icons/document.svg';
import Box from 'components/UI/Box/Box';
import SimpleCardListPaginated from 'components/SimpleCardList/SimpleCardListPaginated';
import { translate } from 'utils/translate';
import { formatDate } from 'utils/formatDate';
import Button from 'components/UI/Button';

const PAGE_SIZE = 6;

const Payments = () => {
  const literals = useLiterals('payments');
  const contracts = useSelector((state) => state.contracts);
  const sections = useSelector((state) => state.sections);
  const dispatch = useDispatch();

  const multiplePayment = useMemo(() => {
    return contracts?.list?.length > 1 && contracts.list.filter((contract) => {
      return contract.CapitalRestante > 0 && !contract.seRealizoPago && contract.OpcionesPago.length > 0;
    }).length > 1;
  }, [contracts]);

  useEffect(() => {
    if (!contracts.loaded) {
      dispatch(getContracts());
    }
    if (!sections.loaded) {
      dispatch(getSections());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const numContracts = contracts?.list?.length || 0;
  const numSections = sections?.list?.length || 0;

  return (
    <Layout
      title={literals.title}
      bottomMenu
      onBack
    >
      <div className='documents'>
        <div className='d-flex justify-content-between align-items-center mb-3 carousel-title'>
          <h3 className='m-0'>
            {`${numContracts ? `${numContracts} ` : ''}${literals.contracts}`}
          </h3>
          {multiplePayment && (
            <div>
              <Button
                size='sm'
                className='py-1 px-3'
                to={ROUTE_PATH.PAYMENTS_MULTIPLE}
                text={literals.multiplePayment}
              />
            </div>
          )}
        </div>
        {contracts?.list?.length ? (
          <SimpleCardListPaginated
            pageSize={PAGE_SIZE}
            cards={(contracts?.list || []).map((contract) => ({
              id: contract.Numero,
              title: contract.Numero,
              image: renderIconCategories(contract.Ramo, contract.isAuto, true),
              description: `${contract.DesEstatus} | ${formatDate(contract?.FechaVencimiento, 'DD/MM/YYYY')} | ${contract.NombreSucursal}`,
              link: ROUTE_PATH.setContract(contract.Numero),
            }))}
          />
        ) : (
          <Box padding='sm' className='text-center'>
            <p className='m-2'>{literals.noContracts}</p>
          </Box>
        )}
        <h3 className='my-3'>
          {`${numSections ? `${numSections} ` : ''}${literals.sections}`}
        </h3>
        {sections?.list?.length ? (
          <SimpleCardListPaginated
            pageSize={PAGE_SIZE}
            cards={(sections?.list || []).map((section) => ({
              id: section.idApartado,
              image: documentIcon,
              title: `${section.id} ${section.description.length > 1 ? translate(literals.sectionProducts, { number: section.description.length }) : ''}`,
              subtitle: section.description.reduce((acc, material) => `${acc} ${material.description}`, ''),
              description: `${section.expirationDate} | ${section.branch}`,
              link: ROUTE_PATH.setSection(section.id),
            }))}
          />
        ) : (
          <Box padding='sm' className='text-center'>
            <p className='m-2'>{literals.noSections}</p>
          </Box>
        )}
      </div>
    </Layout>
  );
};

export default Payments;
