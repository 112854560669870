import PropTypes from 'prop-types';
import './styles.scss';
import { useEffect, useState } from 'react';

const Box = (props) => {
  const {
    className,
    children,
    tabs,
    onChangeTab,
    padding,
    ...rest
  } = props;

  const [activeTab, setActiveTab] = useState(
    tabs?.length
      ? tabs?.find((tab) => tab.active)?.value || tabs[0]?.value
      : null,
  );

  useEffect(() => {
    if (activeTab) {
      onChangeTab(activeTab);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  return (
    <div className={`box ${className} padding-${padding}`} {...rest}>
      {tabs?.length && (
        <div className='box-tabs mb-3'>
          {tabs.map((tab) => (
            <div
              key={tab.value}
              className={`font-bold font-size-sm lh-1 ${activeTab === tab.value ? 'active' : ''}`}
              onClick={() => setActiveTab(tab.value)}
            >
              {tab.label}
            </div>
          ))}
        </div>
      )}
      <div className='box-content'>
        {children}
      </div>
    </div>
  );
};

Box.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  tabs: PropTypes.array,
  onChangeTab: PropTypes.func,
  padding: PropTypes.oneOf(['sm', 'md', 'lg']),
};

Box.defaultProps = {
  className: '',
  tabs: null,
  padding: 'md',
  onChangeTab: () => {},
};

export default Box;
