import { useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { ROUTE_PATH } from 'routes';
import Button from 'components/UI/Button';
import Layout from 'components/Layout';

const ErrorLayout = (props) => {
  const {
    title,
    status,
    error,
    literals,
    children,
  } = props;

  const [showDetails, setShowDetails] = useState(false);
  const navigate = useNavigate();

  const renderBoundaryError = () => {
    if (!error?.error && !error?.info) {
      return null;
    }
    return (
      <div className='boundary-error-message mt-5' style={{ display: 'none' }}>
        <b onClick={() => setShowDetails(!showDetails)}>{literals.showDetails}</b>
        {
          showDetails && (
            <p className='mb-5'>
              {error?.error ? (
                `error: ${error?.error?.toString()}`
              ) : null}
              <hr />
              {error?.info ? (
                `info: ${JSON.stringify(error?.info)}`
              ) : null}
            </p>
          )
        }
      </div>
    );
  };

  const handleGoBack = () => {
    navigate(ROUTE_PATH.BASE);
  };

  return (
    <Layout rightMenu={false} className='text-center'>
      <div className='text-center pt-5'>
        <h2 className='mb-4'>{status}</h2>
        <h1 className='text-align-center mb-3'>{title}</h1>
        {children}
        {renderBoundaryError()}
        <Button
          text={literals.back}
          onClick={handleGoBack}
          className='mt-5'
          type='primary'
        />
      </div>
    </Layout>
  );
};

ErrorLayout.propTypes = {
  title: PropTypes.string,
  status: PropTypes.string,
  error: PropTypes.object,
  literals: PropTypes.object.isRequired,
  children: PropTypes.node,
};

ErrorLayout.defaultProps = {
  title: '',
  status: 500,
  error: null,
  children: null,
};

export default ErrorLayout;
