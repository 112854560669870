import Layout from 'components/Layout';
import CmsBlocks from 'components/CmsBlocks';
import useLiterals from 'utils/hooks/useLiterals';
import { ROUTE_PATH } from 'routes';
import Button from 'components/UI/Button';
import { useLocation } from 'react-router-dom';

const NotFound = () => {
  const literals = useLiterals('notFound');
  const location = useLocation();

  return (
    <Layout bottomMenu onBack>
      <div>
        <CmsBlocks
          key={`path-${location.pathname}`}
          renderOnError={(
            <div className='text-center'>
              <h1 className='pt-5 mb-5'>{literals.title}</h1>
              <p className='mb-5'>{literals.description}</p>
              <Button to={ROUTE_PATH.HOME} text={literals.backToHome} />
            </div>
          )}
        />
      </div>
    </Layout>
  );
};

export default NotFound;
