import {
  useEffect, useMemo, useRef, useState,
} from 'react';
import Collapse from 'react-bootstrap/Collapse';
import useLiterals from 'utils/hooks/useLiterals';
import { useSelector, useDispatch } from 'react-redux';
import Layout from 'components/Layout';
import Card from 'components/UI/Card';
import { ReactComponent as PencilIcon } from 'assets/icons/pencil.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import userImg from 'assets/images/default_avatar.jpg';
import {
  editUser, changeEmailPhone, confirmChangeEmailPhone,
  editAvatar,
} from 'modules/session/actions';
import Input from 'components/UI/Input';
import Button from 'components/UI/Button';
import OtpModal from 'routes/Auth/components/OtpModal';
import { OTP_TYPES } from 'constants/signup';
import { getCMSDocumentSrc } from 'utils/getCMSDocumentSrc';
import './styles.scss';
import ErrorModal from 'components/FeedbackModals/ErrorModal';
import { getLibraryPermission } from 'utils/events';

const PROFILE_FIELDS = {
  NAME: 'name',
  PHONE: 'phone',
  ADDRESS: 'address',
  EMAIL: 'email',
  INE: 'ine',
};

const Profile = () => {
  const literals = useLiterals('profile');
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.session);
  const [error, setError] = useState(false);
  const [editField, setEditField] = useState(null);
  const [userInfo, setUserInfo] = useState({});
  const [loading, setLoading] = useState(false);
  const [showOtpModal, setShowOtpModal] = useState(false);
  const [permissions, setPermissions] = useState(null);
  const [email, setEmail] = useState(null);
  const [phone, setPhone] = useState(null);
  const [otp, setOtp] = useState('');
  const [otpType, setOtpType] = useState(null);
  const [showMessageError, setShowMessageError] = useState(false);
  const avatar = useMemo(() => (user?.avatar ? getCMSDocumentSrc({ hash: user?.avatar }) : ''), [user?.avatar]);
  const fileInputRef = useRef(null);

  useEffect(() => {
    getLibraryPermission()
      .then((permission) => {
        setPermissions(permission);
      });
  }, []);

  useEffect(() => {
    setUserInfo({
      firstName: user?.firstName,
      lastName: user?.lastName,
    });
    setEmail(user?.email);
    setPhone(user?.phone);
  }, [user]);

  const handleChangeSettingsView = (view) => {
    if (editField === view) {
      setEditField(null);
    } else setEditField(view);
  };

  const handleEditName = () => {
    setLoading(true);
    dispatch(editUser({
      first_name: userInfo.firstName,
      last_name: userInfo.lastName,
    }))
      .then(() => {
        setLoading(false);
        setEditField(null);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handleRequestOtp = (type) => {
    setLoading(true);
    setOtpType(type);
    const data = {};
    if (type === OTP_TYPES.EMAIL) {
      data.email = email;
    } else {
      data.phone = `+52${phone}`;
    }
    changeEmailPhone(data)
      .then(() => {
        setLoading(false);
        setShowOtpModal(true);
      })
      .catch(() => {
        setError(true);
        setLoading(false);
      });
  };

  const handleVerifyOtp = () => {
    setLoading(true);
    const data = {
      otp,
    };
    if (otpType === OTP_TYPES.EMAIL) {
      data.email = email;
    } else {
      data.phone = phone;
    }
    dispatch(confirmChangeEmailPhone(data))
      .then(() => {
        setLoading(false);
        setShowOtpModal(false);
        setEditField(null);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handleEditAvatar = (event) => {
    const newImage = event.target.files[0];
    setLoading(true);
    dispatch(editAvatar(newImage))
      .then((success) => {
        if (!success) {
          setShowMessageError('errorUploadingImage');
        }
      }).finally(() => {
        setLoading(false);
      });
  };

  const handleEditUserInfo = (attr, value) => {
    if (value && !/^[A-Za-zÀ-ÿ '-]+$/.test(value)) return;
    setUserInfo({ ...userInfo, [attr]: value.toUpperCase() });
  };

  const renderEditName = () => {
    return (
      <Collapse in={editField === PROFILE_FIELDS.NAME}>
        <div>
          <Input
            label={literals.common.name}
            className='mt-2'
            value={userInfo?.firstName}
            onChange={(value) => handleEditUserInfo('firstName', value)}
          />
          <Input
            label={literals.common.lastName}
            className='mt-2'
            value={userInfo?.lastName}
            onChange={(value) => handleEditUserInfo('lastName', value)}
          />
          <Button
            className='mt-3'
            variant='primary'
            disabled={!userInfo.firstName || !userInfo.lastName || (userInfo.firstName === user?.firstName && userInfo.lastName === user?.lastName)}
            onClick={handleEditName}
            loading={loading}
            text={literals.common.save}
          >
            {literals.common.save}
          </Button>
        </div>
      </Collapse>
    );
  };

  const renderEditEmail = () => {
    return (
      <Collapse in={editField === PROFILE_FIELDS.EMAIL}>
        <div>
          <Input
            type='email'
            label={literals.email}
            className='mt-2'
            value={email}
            onChange={(value) => setEmail(value)}
          />
          <Button
            className='mt-3'
            variant='primary'
            disabled={!email || email === user?.email}
            onClick={() => handleRequestOtp(OTP_TYPES.EMAIL)}
            loading={loading}
            text={literals.common.save}
          >
            {literals.common.save}
          </Button>
        </div>
      </Collapse>
    );
  };

  const renderEditPhone = () => {
    return (
      <Collapse in={editField === PROFILE_FIELDS.PHONE}>
        <div>
          <Input
            type='tel'
            label={literals.phone}
            className='mt-2'
            value={phone}
            onChange={(value) => setPhone(value)}
          />
          <Button
            className='mt-3'
            variant='primary'
            disabled={!phone || phone === user?.phone}
            onClick={() => handleRequestOtp(OTP_TYPES.PHONE)}
            loading={loading}
            text={literals.common.save}
          >
            {literals.common.save}
          </Button>
        </div>
      </Collapse>
    );
  };

  const handleClickEditAvatar = () => {
    if (permissions) {
      fileInputRef.current.click();
    } else {
      setShowMessageError('noLibraryPermissions');
    }
  };

  return (
    <Layout
      title={literals.myProfile}
      bottomMenu
      onBack
    >
      <div className='profile'>
        {showOtpModal && (
          <OtpModal
            onClose={() => setShowOtpModal(false)}
            show={showOtpModal}
            otpType={otpType}
            setOtp={setOtp}
            handleVerifyOtp={handleVerifyOtp}
            isLoading={loading}
          />
        )}
        <div className='avatar-wrapper mb-4'>
          <label onClick={handleClickEditAvatar}>
            <img
              src={avatar}
              alt='card'
              className=''
              onError={
                (e) => {
                  e.target.onerror = null;
                  e.target.src = userImg;
                }
              }
            />
          </label>
          <input className='d-none' ref={fileInputRef} type='file' id='fileInput' accept='capture=camera,image/*' onChange={handleEditAvatar} />
          <PencilIcon className='edit-icon' onClick={handleClickEditAvatar} />
        </div>
        <Card
          title={literals.common.name}
          description={`${user?.firstName || ''} ${user?.lastName || ''}`}
          icon={editField !== PROFILE_FIELDS.NAME ? <PencilIcon onClick={() => handleChangeSettingsView(PROFILE_FIELDS.NAME)} /> : <CloseIcon onClick={() => handleChangeSettingsView(PROFILE_FIELDS.NAME)} />}
          body={renderEditName()}
        />
        <Card
          title={literals.phone}
          description={user?.phone || '-'}
          icon={editField !== PROFILE_FIELDS.PHONE ? <PencilIcon onClick={() => handleChangeSettingsView(PROFILE_FIELDS.PHONE)} /> : <CloseIcon onClick={() => handleChangeSettingsView(PROFILE_FIELDS.PHONE)} />}
          body={renderEditPhone()}
        />
        <Card
          title={literals.email}
          description={user?.email || '-'}
          icon={editField !== PROFILE_FIELDS.EMAIL ? <PencilIcon onClick={() => handleChangeSettingsView(PROFILE_FIELDS.EMAIL)} /> : <CloseIcon onClick={() => handleChangeSettingsView(PROFILE_FIELDS.EMAIL)} />}
          body={renderEditEmail()}
        />
        <Card title={literals.ine} description={user?.ine || '-'} />
      </div>
      {error && <ErrorModal title={literals.common.genericError} onClose={() => setError(false)} />}
      {showMessageError && <ErrorModal title={literals[showMessageError]} onClose={() => setShowMessageError(false)} />}
    </Layout>
  );
};

export default Profile;
