/* eslint-disable no-unused-vars */
import ApiFetch from 'utils/apiFetch';

const ApiFetchInstance = ApiFetch();

export const URLS = {
  clients: '/users/clients',
  states: '/clients/states',
  municipalities: '/clients/municipalities',
  colonies: '/clients/colonies',
};

export default {
  async isUserRegistered(id, type) {
    return ApiFetchInstance.get(`${URLS.clients}/${id}?type=${type}`);
  },
  async getStates() {
    return ApiFetchInstance.get(URLS.states);
  },
  async getMunicipalities(stateId) {
    return ApiFetchInstance.get(`${URLS.municipalities}?state=${stateId}`);
  },
  async getColonies(stateId, municipalityId) {
    return ApiFetchInstance.get(`${URLS.colonies}?state=${stateId}&municipality=${municipalityId}`);
  },
};
