import useLiterals from 'utils/hooks/useLiterals';
import Card from 'components/UI/Card';

const Language = () => {
  const literals = useLiterals('settings');

  return (
    <>
      <Card
        title={literals.spanish}
        onClick={() => { }}
        showAccessIcon
      />
      <Card
        title={literals.english}
        onClick={() => { }}
        showAccessIcon
      />
    </>
  );
};

export default Language;
