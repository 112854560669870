import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { ReactComponent as LessIcon } from 'assets/icons/less.svg';
import { formatCurrency } from 'utils/formatCurrency';
import Translate from 'components/Translate';
import useLiterals from 'utils/hooks/useLiterals';
import InputAmount from '../InputAmount';

const InputAmountSteps = (props) => {
  const {
    value,
    originalValue,
    min,
    max,
    step,
    error,
    onChange,
  } = props;
  const literals = useLiterals('common');

  const minAmount = useMemo(() => (
    originalValue ? Math.min(min, originalValue) : min
  ), [min, originalValue]);

  const disabledLess = !min || value <= minAmount;
  const disabledMore = !max || value >= max;

  const handlePaymentControl = (amountStep) => {
    let newAmount = parseFloat(value + amountStep) || null;
    if (newAmount !== null) {
      if (max && amountStep > 0 && newAmount > max) {
        newAmount = max;
      } else if (minAmount && amountStep < 0 && newAmount < minAmount) {
        newAmount = minAmount;
      }
      onChange(parseFloat(newAmount.toFixed(2)));
    } else {
      onChange(null);
    }
  };

  const handleOnChange = (v) => {
    onChange(v === undefined ? null : parseFloat(v) || 0);
  };

  return (
    <>
      <div className='d-flex gap-2'>
        <div
          className={`payment-input ${disabledLess ? 'disabled' : ''}`}
          onClick={() => !disabledLess && handlePaymentControl(-step)}
        >
          <LessIcon />
        </div>
        <InputAmount
          className='w-100'
          placeholder={literals.insertAmount}
          value={value}
          onChange={handleOnChange}
          min={minAmount}
          max={max}
          disabled={!max}
        />
        <div
          className={`payment-input ${disabledMore ? 'disabled' : ''}`}
          onClick={() => !disabledMore && handlePaymentControl(+step)}
        >
          <PlusIcon />
        </div>
      </div>
      {error && (
        <Translate
          component='div'
          className='payment-input-error pt-2'
          literal={originalValue ? literals.amountRangeErrorWithOriginal : literals.amountRangeError}
          vars={{
            amount: formatCurrency(originalValue),
            min: formatCurrency(min),
            max: formatCurrency(max),
          }}
        />
      )}
    </>
  );
};

InputAmountSteps.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  originalValue: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
  onChange: PropTypes.func,
  error: PropTypes.bool,
};

InputAmountSteps.defaultProps = {
  value: 0,
  originalValue: 0,
  min: 0,
  max: 0,
  step: 20,
  error: false,
  onChange: () => {},
};

export default InputAmountSteps;
