import useLiterals from 'utils/hooks/useLiterals';
import { useNavigate } from 'react-router-dom';
import Layout from 'components/Layout';
import Box from 'components/UI/Box/Box';
import Button from 'components/UI/Button';
import { ReactComponent as IconMoney } from 'assets/icons/money.svg';
import { ReactComponent as OkIcon } from 'assets/icons/ok.svg';
import { ReactComponent as UmbrellaIcon } from 'assets/icons/umbrella.svg';
import { ReactComponent as ClockIcon } from 'assets/icons/clock.svg';
import { ReactComponent as PercentageIcon } from 'assets/icons/percentage.svg';
import { ReactComponent as CarIcon } from 'assets/icons/car-2.svg';
import { ReactComponent as PersonIcon } from 'assets/icons/person.svg';
import { ReactComponent as CalendarIcon } from 'assets/icons/calendar.svg';
import { ReactComponent as TripsIcon } from 'assets/icons/trips.svg';
import { ReactComponent as RatingIcon } from 'assets/icons/rating.svg';
import { ReactComponent as ScreenshotIcon } from 'assets/icons/screenshot.svg';
import { ReactComponent as LocationIcon } from 'assets/icons/location.svg';
import { ZOHO_SCRIPT_TYPES } from 'constants/zohoScripts';
import { ROUTE_PATH } from 'routes';

import './styles.scss';
import { useMemo } from 'react';

const ExecutiveDriver = () => {
  const literals = useLiterals('executiveDriver');
  const navigate = useNavigate();
  const benefits = useMemo(() => [
    { icon: IconMoney, text: literals.benefits.gas },
    { icon: OkIcon, text: literals.benefits.noPayments },
    { icon: UmbrellaIcon, text: literals.benefits.coverageInsurance },
    { icon: ClockIcon, text: literals.benefits.paymentCapacity },
    { icon: PercentageIcon, text: literals.benefits.interestRate },
    { icon: CarIcon, text: literals.benefits.cars },
  ], [literals.benefits]);

  const requirements = useMemo(() => [
    { text: literals.requirements.age, icon: PersonIcon },
    { text: literals.requirements.threeMonths, icon: CalendarIcon },
    { text: literals.requirements.trips, icon: TripsIcon },
    { text: literals.requirements.rating, icon: RatingIcon },
    { text: literals.requirements.billing, icon: ScreenshotIcon },
    { text: literals.requirements.location, icon: LocationIcon },
  ], [literals.requirements]);

  const EXECUTIVE_DRIVER_PHONE = '8000001234';

  const renderBenefits = () => {
    return (
      <div>
        <h3 className='text-primary mb-3'>{literals.benefits.title}</h3>
        <div className='benefits'>
          {benefits.map((benefit, index) => (
            <div className='d-flex gap-3 mt-2 align-items-center' key={`benefit-${index}`}>
              <benefit.icon className='executive-driver-icon' />
              <div className='font-size-xs font-bold'>{benefit.text}</div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const renderRequirements = () => {
    return (
      <div className='mt-4'>
        <h3 className='text-primary mb-3'>{literals.requirements.title}</h3>
        <div className='requirements'>
          {requirements.map((requirement, index) => (
            <div className='d-flex gap-3 mt-2 align-items-center' key={`requirement-${index}`}>
              <requirement.icon className='executive-driver-icon' />
              <div className='font-size-xs font-bold'>{requirement.text}</div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <Layout
      title={literals.title}
      bottomMenu
      onBack
    >
      <div className='executive-driver'>
        <h2 className='text-primary'>{literals.subtitle}</h2>
        <div className='mt-3'>{literals.info1}</div>
        <div className='mt-3'>{literals.info2}</div>
        <Box className='mt-3'>
          {renderBenefits()}
          {renderRequirements()}
          <Button
            type='secondary'
            className='w-100 mt-4'
            to={`tel:${EXECUTIVE_DRIVER_PHONE}`}
            text={literals.call}
          />
          <Button
            className='w-100 mt-3'
            onClick={() => navigate(`${ROUTE_PATH.APPOINTMENT}?type=${ZOHO_SCRIPT_TYPES.DRIVER}`)}
            text={literals.weContactYou}
          />
        </Box>
      </div>
    </Layout>
  );
};

export default ExecutiveDriver;
