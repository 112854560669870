/* eslint-disable no-unused-vars */
import ApiFetch from 'utils/apiFetch';

const ApiFetchInstance = ApiFetch();

export const URLS = {
  getWatchesCatalog: '/valuations/catalog',
  getWatchesSimulate: '/valuations/watches/simulate',
};

export default {
  async getWatchesCatalog(catalog = 'RELOJ_MARCA') {
    return ApiFetchInstance.get(`${URLS.getWatchesCatalog}?catalog=${catalog}`);
  },
  async getWatchesSimulate(brand, averagePrice) {
    return ApiFetchInstance.get(`${URLS.getWatchesSimulate}?brand=${brand}&value=${averagePrice}`);
  },
};
