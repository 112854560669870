/* eslint-disable no-unused-vars */
import ApiFetch from 'utils/apiFetch';
import { preparePath } from 'utils/preparePath';
// import getPlacesResponse from './mockups/getPlacesResponse';
// import getPlaceResponse from './mockups/getPlaceResponse';

const ApiFetchInstance = ApiFetch();

export const URLS = {
  offices: '/valuations/offices',
  placesNearby: '/maps/nearby',
  place: '/maps/:id/detail',
};

export default {
  getStores(search = '') {
    return ApiFetchInstance.get(preparePath(URLS.offices, null, { search }));
  },
  getPlacesNearby(latitude, longitude, radius) {
    /*
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(getPlacesResponse);
      }, Math.floor(Math.random() * 1000));
    });
    */
    return ApiFetchInstance.get(preparePath(URLS.placesNearby, null, { latitude, longitude, radius }));
  },
  getPlace(id) {
    /*
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(getPlaceResponse);
      }, Math.floor(Math.random() * 1000));
    });
    */
    return ApiFetchInstance.get(preparePath(URLS.place, { id }));
  },
};
