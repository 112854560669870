import { useMemo } from 'react';
import PropTypes from 'prop-types';
import useLiterals from 'utils/hooks/useLiterals';
import { QUOTER_TYPE } from 'constants/services';
import carsImg from 'assets/images/cars_s.jpg';
import jewelryImg from 'assets/images/jewelry_s.jpg';
import diamondsImg from 'assets/images/diamonds.jpg';
import watchesImg from 'assets/images/watches.jpg';
import uberImg from 'assets/images/uber.jpg';
import SimpleCardList from 'components/SimpleCardList';
import { ROUTE_PATH } from 'routes';

const Quotes = ({ hide }) => {
  const literals = useLiterals('quotes');
  const getImage = (id) => {
    switch (id) {
      case QUOTER_TYPE.CARS:
        return { img: carsImg };
      case QUOTER_TYPE.JEWELRY:
        return { img: jewelryImg };
      case QUOTER_TYPE.DIAMONDS:
        return { img: diamondsImg };
      case QUOTER_TYPE.WATCHES:
        return { img: watchesImg };
      case QUOTER_TYPE.EXECUTIVE_DRIVER:
        return { img: uberImg };
      default:
        return null;
    }
  };

  const quotes = useMemo(() => {
    return Object.keys(QUOTER_TYPE).map((quote) => {
      const image = getImage(QUOTER_TYPE[quote]);
      if (hide === QUOTER_TYPE[quote]) {
        return null;
      }
      return {
        id: QUOTER_TYPE[quote],
        title: literals[QUOTER_TYPE[quote]].title,
        description: literals[QUOTER_TYPE[quote]].description,
        image: image.img,
        link: QUOTER_TYPE[quote] !== QUOTER_TYPE.EXECUTIVE_DRIVER ? ROUTE_PATH.setQuoter(QUOTER_TYPE[quote]) : ROUTE_PATH.EXECUTIVE_DRIVER,
      };
    }).filter((quote) => quote);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hide]);

  return (
    <SimpleCardList cards={quotes} />
  );
};

Quotes.propTypes = {
  hide: PropTypes.string,
};

Quotes.defaultProps = {
  hide: '',
};

export default Quotes;
