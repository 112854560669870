/* eslint-disable react/jsx-no-target-blank */
import PropTypes from 'prop-types';
import {
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import { useSelector } from 'react-redux';
import './styles.scss';
import Button from 'components/UI/Button';
import DocumentMessage from './DocumentMessage';

const EmbedPdf = (props) => {
  const {
    src,
    downloadable,
    downloadSrc,
  } = props;

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [zoom, setZoom] = useState(1);
  const [width, setWidth] = useState(0);
  const [loading, setLoading] = useState(false);
  const [lastTouchDistance, setLastTouchDistance] = useState(null);
  const [lastTouchZoom, setLastTouchZoom] = useState(null);
  const ref = useRef(null);
  const timeout = useRef({});
  const literals = useSelector((state) => ({
    common: state.i18n.literals.common,
    errors: state.i18n.literals.errors,
  }));

  const options = useMemo(() => ({
    cMapUrl: `https://unpkg.com/pdfjs-dist@${pdfjs.version}/cmaps/`,
    cMapPacked: true,
    withCredentials: true,
  }), []);

  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;
    return () => {
      clearTimeout(timeout.current);
    };
  }, []);

  useEffect(() => {
    setWidth(ref.current ? ref.current.offsetWidth - 20 : 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref.current]);

  const onDocumentLoadSuccess = ({ numPages: newNumPages }) => {
    setNumPages(newNumPages);
  };

  const handleChangePage = (offset) => {
    const newPage = pageNumber + offset;
    if (newPage < 1 || newPage > numPages || loading) return;
    setPageNumber(newPage);
    setLoading(true);
  };

  const handleChangeZoom = (offset) => {
    const newZoom = zoom + offset;
    if (newZoom < 1 || loading) return;
    if (timeout.current) {
      clearTimeout(timeout.current);
      timeout.current = setTimeout(() => {
        setLoading(true);
        setZoom(newZoom);
      }, 50);
    }
  };

  const handleOnLoadPage = () => {
    setLoading(false);
  };

  const handleWheel = (event) => {
    if (event.ctrlKey) {
      event.preventDefault();
      const delta = Math.sign(event.deltaY);
      if (delta > 0) {
        handleChangeZoom(-1);
      } else if (delta < 0) {
        handleChangeZoom(1);
      }
    }
  };

  const handleTouchStart = (event) => {
    if (event.touches.length === 2) {
      const touchDistance = Math.hypot(
        event.touches[0].clientX - event.touches[1].clientX,
        event.touches[0].clientY - event.touches[1].clientY,
      );
      setLastTouchDistance(touchDistance);
    }
  };

  const handleTouchMove = (event) => {
    if (event.touches.length === 2) {
      const touchDistance = Math.hypot(
        event.touches[0].clientX - event.touches[1].clientX,
        event.touches[0].clientY - event.touches[1].clientY,
      );
      const delta = touchDistance - lastTouchDistance;
      setLastTouchDistance(touchDistance);
      setLastTouchZoom(delta > 0 ? 1 : -1);
    }
  };

  const handleTouchEnd = () => {
    setLastTouchDistance(null);
    if (lastTouchZoom === null || loading) return;
    handleChangeZoom(lastTouchZoom);
    setLastTouchZoom(null);
  };

  return (
    <div className='embed-pdf' ref={ref}>
      {width && (
        <>
          <div
            className='document'
            onWheel={handleWheel}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
            onDoubleClick={() => !loading && !lastTouchDistance && handleChangeZoom(1)}
          >
            <Document
              key='embed-pdf'
              file={src}
              onLoadSuccess={onDocumentLoadSuccess}
              loading={<DocumentMessage text={literals.common.loading} />}
              options={options}
              error={<DocumentMessage text={literals.errors.fileNotFound} />}
            >
              <Page
                key='embed-pdf-page'
                pageNumber={pageNumber}
                width={width}
                scale={zoom}
                loading={<DocumentMessage text={literals.common.loading} />}
                onLoadSuccess={handleOnLoadPage}
              />
            </Document>
            {numPages && (
              <div className='document-zoom'>
                <div className={`white ${loading || zoom === 1 ? 'disabled' : ''}`} onClick={() => handleChangeZoom(-1)}>
                  -
                </div>
                <div className={`white ${loading ? 'disabled' : ''}`} onClick={() => handleChangeZoom(1)}>
                  +
                </div>
              </div>
            )}
          </div>
          {numPages && (
            <div className='pager mt-2'>
              <div className={`btn-page ${loading || pageNumber === 1 ? 'disabled' : ''}`} onClick={() => handleChangePage(-1)}>
                {'<'}
              </div>
              <span>{`${literals.common.page} ${pageNumber}/${numPages}`}</span>
              <div className={`btn-page ${loading || pageNumber === numPages ? 'disabled' : ''}`} onClick={() => handleChangePage(1)}>
                {'>'}
              </div>
            </div>
          )}
          {downloadable && numPages && (
            <div className='mt-4'>
              <Button type='secondary' to={downloadSrc || src} linkProps={{ download: true, target: '_blank' }}>
                {literals.common.download}
              </Button>
            </div>
          )}
        </>
      )}
    </div>
  );
};

EmbedPdf.propTypes = {
  src: PropTypes.string.isRequired,
  // name: PropTypes.string,
  downloadable: PropTypes.bool,
  downloadSrc: PropTypes.string,
};

EmbedPdf.defaultProps = {
  // name: '',
  downloadable: false,
  downloadSrc: null,
};

export default EmbedPdf;
