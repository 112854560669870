import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import watchesImg from 'assets/images/watches_multiple.jpg';
import Box from 'components/UI/Box/Box';
import Button from 'components/UI/Button';
import Select from 'components/UI/Select';
import InputAmount from 'components/UI/InputAmount';
import { getWatchesCatalog } from 'modules/quotes/watchesQuoter/actions';
import { loadingActions } from 'modules/loading';

const WatchesQuoter = (props) => {
  const {
    literals,
    form,
    loading,
    onChange,
    onSubmit,
  } = props;
  const { watches: literalsWatches } = literals;
  const dispatch = useDispatch();
  const disabled = !form.brand || !form.averagePrice;
  const [watchCatalog, setWatchCatalog] = useState([]);

  useEffect(() => {
    dispatch(loadingActions.show());
    getWatchesCatalog()
      .then((response) => {
        dispatch(loadingActions.hide());
        setWatchCatalog(response || []);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box className='watchsQuoter-box mb-3'>
      <img className='mb-4 no-top-padding no-left-padding' src={watchesImg} alt='Watches' />
      <h2 className='mb-3'>{literalsWatches.title}</h2>
      <p className='mb-4'>{literalsWatches.info}</p>
      <form onSubmit={onSubmit}>
        <Select
          className='mb-4'
          value={form.brand || ''}
          placeholder={literalsWatches.brand}
          onChange={(v) => onChange('brand', v)}
          options={watchCatalog.map((option) => ({ value: option.Clave, label: option.Descripcion }))}
          required
        />
        <InputAmount
          className='mb-4 w-100'
          placeholder={literalsWatches.averagePrice}
          value={form.averagePrice}
          onChange={(v) => onChange('averagePrice', v)}
          required
        />
        <Button isSubmitBtn type='primary' text={literalsWatches.getQuote} disabled={disabled} loading={loading} />
      </form>
    </Box>
  );
};

WatchesQuoter.propTypes = {
  literals: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default WatchesQuoter;
