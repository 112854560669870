import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
import {
  GOOGLE_API_KEY, DEFAULT_COORDS, DEFAULT_ZOOM,
  acquireUserLocation,
} from 'utils/googleMaps';
import MarkerUserIcon from 'assets/icons/marker_user.svg';
import MapMarkerStoreIcon from 'assets/icons/marker_mp.png';

const MapComponent = (props) => {
  const {
    data,
    width,
    zoom,
    userPosition,
    coords,
    height,
    className,
    onMove,
    onChangeZoom,
    onMarkerClick,
  } = props;

  const mapRef = useRef(null);

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: GOOGLE_API_KEY,
  });

  const [userLocation, setUserLocation] = useState(null);

  useEffect(() => {
    if (isLoaded) {
      if (userPosition) {
        setUserLocation({ ...userPosition });
      } else {
        acquireUserLocation().then((v) => {
          setUserLocation(v);
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoaded]);

  const handleOnLoad = (map) => {
    mapRef.current = map;
  };

  const handleOnMove = () => {
    if (mapRef.current) {
      const newPosition = mapRef.current.getCenter();
      if (newPosition) {
        onMove({ latitude: newPosition.lat(), longitude: newPosition.lng() });
      }
    }
  };

  const handleOnChangeZoom = () => {
    if (mapRef.current) {
      onChangeZoom(mapRef.current.zoom);
    }
  };

  const showItemDetails = (item) => {
    onMarkerClick(item);
  };

  return (
    <div className={`gmap-wrapper ${className}`}>
      {isLoaded && (
        <GoogleMap
          key='map'
          mapContainerStyle={{ width, height }}
          center={{
            lat: parseFloat(coords.latitude),
            lng: parseFloat(coords.longitude),
          }}
          zoom={zoom}
          options={{
            disableDefaultUI: true,
            gestureHandling: 'greedy',
            zoomControl: true,
          }}
          onLoad={handleOnLoad}
          onDragEnd={handleOnMove}
          onZoomChanged={handleOnChangeZoom}
        >
          {userLocation && (
            <>
              <Marker
                key='user'
                icon={MarkerUserIcon}
                position={{
                  lat: parseFloat(userLocation.latitude),
                  lng: parseFloat(userLocation.longitude),
                }}
              />
              {(data || []).map((item, index) => {
                return (
                  <Marker
                    key={index}
                    icon={item?.isUser ? MarkerUserIcon : MapMarkerStoreIcon}
                    position={{
                      lat: parseFloat(item.latitude),
                      lng: parseFloat(item.longitude),
                    }}
                    onClick={() => showItemDetails(item)}
                  />
                );
              })}
            </>
          )}
        </GoogleMap>
      )}
    </div>
  );
};
MapComponent.propTypes = {
  data: PropTypes.array.isRequired,
  coords: PropTypes.object,
  zoom: PropTypes.number,
  width: PropTypes.string,
  userPosition: PropTypes.object,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  onMove: PropTypes.func,
  onChangeZoom: PropTypes.func,
  onMarkerClick: PropTypes.func,
};
MapComponent.defaultProps = {
  width: '100%',
  height: '600px',
  coords: DEFAULT_COORDS,
  zoom: DEFAULT_ZOOM,
  userPosition: null,
  className: '',
  onMove: () => { },
  onChangeZoom: () => { },
  onMarkerClick: () => { },
};
export default MapComponent;
