import { SET_IS_APP, SET_REDIRECT, UPDATE_PREV_PATH } from '.';

export const updatePrevPath = (value) => (dispatch) => {
  dispatch({ type: UPDATE_PREV_PATH, payload: value });
};

export const setRedirect = (value) => (dispatch) => {
  dispatch({ type: SET_REDIRECT, payload: value });
};

export const setIsApp = (value) => (dispatch) => {
  dispatch({ type: SET_IS_APP, payload: value });
};
