import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import useLiterals from 'utils/hooks/useLiterals';
import Button from 'components/UI/Button';
import Modal from 'components/Modal';
import Input from 'components/UI/Input';
import { createMultipleReference, createReference } from 'modules/bancomer/actions';
import { ReactComponent as CardIcon } from 'assets/icons/card.svg';
import ErrorModal from 'components/FeedbackModals/ErrorModal';
import { API_BASE_URL } from 'constants/apiConf';
import { sendSentryLog } from 'utils/sendSentryLog';
import './styles.scss';
import { CONTRACT, SECTION } from 'constants/global';

const FORM = {
  mp_account: window.BANCOMER.MP_ACCOUNT,
  mp_product: '1',
  mp_order: 'B00001',
  mp_reference: '',
  mp_node: '2',
  mp_concept: '3',
  mp_currency: '1',
  mp_amount: '0',
  mp_urlsuccess: `${API_BASE_URL}/bbva/payments/success`,
  mp_urlfailure: `${API_BASE_URL}/bbva/payments/failure`,
  mp_signature: '',
};

const BBVA = ({
  id,
  type,
  payment,
  orders,
  amount,
  isMultiple,
  onClose,
}) => {
  const literals = useLiterals('contractPay');
  const [formValue, setFormValue] = useState(FORM);
  const [shouldSubmit, setShouldSubmit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (shouldSubmit) {
      sendSentryLog({ formValue }, 'BBVA Form Value');
      document.getElementById('payment-form-button').click();
    }
  }, [shouldSubmit]);

  const handleConfirmModal = () => {
    setLoading(true);
    const callback = (response) => {
      setLoading(false);
      if (response) {
        setFormValue({
          ...formValue,
          mp_amount: response.amount,
          mp_reference: response.id,
          mp_order: response.external_order,
          mp_signature: response.signature,
        });
        setShouldSubmit(true);
      } else {
        setError(true);
      }
    };

    if (isMultiple) {
      createMultipleReference(amount, orders).then(callback);
    } else {
      createReference({
        contractType: type,
        contract_id: type === CONTRACT ? id : null,
        section_id: type === SECTION ? id : null,
        product: formValue.mp_product,
        amount: amount || payment?.amount,
        monto: payment?.amount,
        currency: formValue.mp_currency,
        paymentKey: payment?.paymentKey || null,
      }).then(callback);
    }
  };

  const renderForm = () => {
    return (
      <form id='payment-form' method='post' action={window.BANCOMER.URL} style={{ display: 'none' }}>
        <label className='mb-1'>ID Cliente</label>
        <Input className='mb-1' type='text' name='mp_account' value={formValue.mp_account} placeholder='ID Cliente' />
        <label className='mb-1'>ID Producto</label>
        <Input className='mb-1' type='text' name='mp_product' value={formValue.mp_product} placeholder='ID Producto' required />
        <label className='mb-1'>Orden</label>
        <Input className='mb-1' type='text' name='mp_order' value={formValue.mp_order} placeholder='Orden' required />
        <label className='mb-1'>Referencia</label>
        <Input className='mb-1' type='text' name='mp_reference' value={formValue.mp_reference} placeholder='Referencia' required />
        <label className='mb-1'>Nodo</label>
        <Input className='mb-1' type='text' name='mp_node' value={formValue.mp_node} placeholder='Nodo' required />
        <label className='mb-1'>Concepto</label>
        <Input className='mb-1' type='text' name='mp_concept' value={formValue.mp_concept} placeholder='Concepto' required />
        <label className='mb-1'>Importe</label>
        <Input className='mb-1' type='text' name='mp_amount' value={formValue.mp_amount} placeholder='Importe' required />
        <label className='mb-1'>Moneda</label>
        <Input className='mb-1' type='text' name='mp_currency' value={formValue.mp_currency} placeholder='Moneda' required />
        <label className='mb-1'>URL Pago exitoso</label>
        <Input className='mb-1' type='text' name='mp_urlsuccess' value={formValue.mp_urlsuccess} placeholder='URL Pago exitoso' required />
        <label className='mb-1'>URL Pago no exitoso</label>
        <Input className='mb-1' type='text' name='mp_urlfailure' value={formValue.mp_urlfailure} placeholder='URL Pago no exitoso' required />
        <label className='mb-1'>Firma</label>
        <Input className='mb-3' type='text' name='mp_signature' value={formValue.mp_signature} placeholder='URL Pago no exitoso' required />
        <button id='payment-form-button' type='submit' />
      </form>
    );
  };

  return (
    <>
      <Modal
        title={literals.bancomer.confirmTitle}
        onCloseModal={onClose}
        onConfirm={handleConfirmModal}
      >
        <div className='text-center'>
          <CardIcon className='my-3' />
          {renderForm()}
          <div className='text-gray mb-4'>{literals.bancomer.confirmDescription}</div>
          <Button
            disabled={loading}
            onClick={onClose}
            type='secondary'
            text={literals.common.cancel}
            className='mb-3'
          />
          <Button
            loading={loading}
            onClick={handleConfirmModal}
            type='primary'
            text={literals.common.continue}
          />
        </div>
      </Modal>
      {error && (
        <ErrorModal
          title={literals.bancomer.failureReferenceTitle}
          description={literals.bancomer.failureReferenceDescription}
          onClose={() => setError(false)}
        />
      )}
    </>
  );
};

BBVA.propTypes = {
  id: PropTypes.string,
  type: PropTypes.string,
  payment: PropTypes.object,
  orders: PropTypes.array,
  amount: PropTypes.number.isRequired,
  isMultiple: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

BBVA.defaultProps = {
  id: '',
  type: '',
  payment: null,
  orders: [],
  isMultiple: false,
};

export default BBVA;
