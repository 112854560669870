import { useMemo } from 'react';
import PropTypes from 'prop-types';
import userImg from 'assets/images/default_avatar.jpg';
import Box from 'components/UI/Box/Box';
import Button from 'components/UI/Button';
import { getCMSDocumentSrc } from 'utils/getCMSDocumentSrc';
import { ROUTE_PATH } from 'routes';
import { Link } from 'react-router-dom';

const UserBox = ({ user, literals, onClick }) => {
  const avatar = useMemo(() => (user?.avatar ? getCMSDocumentSrc({ hash: user?.avatar }) : ''), [user?.avatar]);
  return (
    <Box className='user-box mb-3' onClick={!user ? null : onClick} padding='sm'>
      {user?.id ? (
        <Link className='user-box-info' to={ROUTE_PATH.PROFILE}>
          <div className='user-box-image' style={{ backgroundImage: `url("${avatar}")` }}>
            {!avatar && (
              <img
                src={userImg}
                alt='userImg'
                onError={
                  (e) => {
                    e.target.onerror = null;
                    e.target.src = userImg;
                  }
                }
              />
            )}
          </div>
          <h4 className='m-0'>{user.firstName}</h4>
        </Link>
      ) : (
        <>
          <p className='m-0 lh-1'>{literals.guestUser}</p>
          <div>
            <Button
              size='xs'
              text={literals.registerOrAccess}
              to={ROUTE_PATH.LOGIN}
            />
          </div>
        </>
      )}
    </Box>
  );
};

UserBox.propTypes = {
  literals: PropTypes.object.isRequired,
  user: PropTypes.object,
  onClick: PropTypes.func,
};

UserBox.defaultProps = {
  user: null,
  onClick: () => {},
};

export default UserBox;
