import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Lottie from 'react-lottie';
import * as animationData from 'assets/animations/info.json';
import Modal from 'components/Modal';
import Translate from 'components/Translate';
import './styles.scss';

function ErrorModal({
  literals, description, onClose, title,
}) {
  return (
    <Modal
      onCloseModal={onClose}
      className='feedback-modal'
    >
      <div className='text-center'>
        <Lottie
          options={{
            loop: false,
            autoplay: true,
            animationData,
            rendererSettings: {
              preserveAspectRatio: 'xMidYMid slice',
            },
          }}
          height={90}
          width={90}
        />
        <h3 className='mt-1'>
          {title || literals.successTitle}
        </h3>
        {description && (
          <Translate component='h3' className='text-gray font-regular mt-4' literal={description} />
        )}
      </div>
    </Modal>
  );
}

ErrorModal.propTypes = {
  literals: PropTypes.object.isRequired,
  description: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string,
};
ErrorModal.defaultProps = {
  title: '',
  description: '',
};

/* *************************************** */
/* ********       CONTAINER       ******** */
/* *************************************** */

function mapStateToProps(state) {
  return {
    literals: {
      ...state.i18n.literals.feedbackModal,
      ...state.i18n.literals.common,
    },
  };
}

export default connect(mapStateToProps)(ErrorModal);
