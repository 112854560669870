import { useSelector } from 'react-redux';

const useConfiguration = () => {
  const configuration = useSelector((state) => state.configuration);

  const getConfigurationValue = (name, isObject = false) => {
    const value = configuration.list.find((item) => item.name === name)?.value;

    // eslint-disable-next-line no-nested-ternary
    return value && isObject ? JSON.parse(value) : (value || null);
  };

  return { getConfigurationValue };
};

export default useConfiguration;
