/* eslint-disable no-unused-vars */
import ApiFetch from 'utils/apiFetch';

const ApiFetchInstance = ApiFetch();

export const URLS = {
  jewelsSimulate: '/valuations/jewels/simulate',
  getJewelsCatalog: '/valuations/catalog',
};

export default {
  async getJewelsSimulate(material, weight) {
    return ApiFetchInstance.get(`${URLS.jewelsSimulate}?material=${material}&weight=${weight}`);
  },
  async getJewelsCatalog() {
    return ApiFetchInstance.get(`${URLS.getJewelsCatalog}?catalog=ALHAJA_MATERIAL`);
  },
};
