import Layout from 'components/Layout';

const Store = () => {
  return (
    <Layout
      headerMenu={false}
      onBack
      bottomMenu
    >
      <iframe
        className='embed-page'
        title='Tienda Luz Savinon'
        src='https://www.tiendaluzsavinon.com'
      />
    </Layout>
  );
};

export default Store;
