import { useEffect, useState } from 'react';
import useLiterals from 'utils/hooks/useLiterals';
import { useSelector } from 'react-redux';
import Button from 'components/UI/Button';
import { ReactComponent as FaceID } from 'assets/icons/faceID.svg';
import { generateSessionToken } from 'modules/session/actions';
import { deleteStoreData, getStoreData } from 'utils/events';
import { STORAGE_USER_DATA } from 'constants/local';
import useLocalStorage from 'utils/hooks/useLocalStorage';
import { RESPONSE_STATUS } from 'constants/global';
import SuccessModal from 'components/FeedbackModals/SuccessModal';
import ErrorModal from 'components/FeedbackModals/ErrorModal';
import Modal from 'components/Modal';
import { formatDate } from 'utils/formatDate';

const BiometricAccess = () => {
  const literals = useLiterals('settings');
  const { user } = useSelector((state) => state.session);
  const [userData, setUserData] = useLocalStorage(STORAGE_USER_DATA);
  const [status, setStatus] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showToken, setShowToken] = useState(0);
  const [tokenInfo, setTokenInfo] = useState(null);

  const handleOnClick = () => {
    if (userData.biometric) {
      deleteStoreData('MLS-Token');
      setUserData({ ...userData, biometric: false });
    } else {
      setLoading(true);
      generateSessionToken(user, (success) => {
        if (success) {
          setUserData({ ...userData, biometric: true });
          setStatus(RESPONSE_STATUS.SUCCESS);
        } else {
          setStatus(RESPONSE_STATUS.ERROR);
        }
        setLoading(false);
      });
    }
  };

  useEffect(() => {
    if (showToken >= 10) {
      getStoreData('MLS-Token').then((item) => {
        setTokenInfo(item);
      });
    }
  }, [showToken]);

  const handleShowToken = () => {
    setShowToken((v) => (v + 1));
  };

  return (
    <>
      <h3 className='text-gray font-regular mb-2'>{literals.faceIdAccess}</h3>
      <h1 className='mt-0 mb-2'>{userData.biometric ? literals.faceIdEnabled : literals.faceIdDisabled}</h1>
      <div className='mt-5 d-flex justify-content-center'>
        <FaceID onClick={handleShowToken} />
      </div>
      <Button
        loading={loading}
        className='mt-5'
        onClick={handleOnClick}
        text={userData.biometric ? literals.disableFaceId : literals.enableFaceId}
      />
      {status === RESPONSE_STATUS.SUCCESS && (
        <SuccessModal
          description={literals.faceIDSuccess}
          onClose={() => setStatus(null)}
        />
      )}
      {status === RESPONSE_STATUS.ERROR && (
        <ErrorModal
          description={literals.faceIDError}
          onClose={() => setStatus(null)}
        />
      )}
      {showToken >= 10 && (
        <Modal onCloseModal={() => { setShowToken(0); setTokenInfo(null); }} closeOnBackgroundClick={false}>
          <p>{`user: ${tokenInfo?.userId || '-'}`}</p>
          <p>{`expires: ${tokenInfo?.expire || '-'} ${tokenInfo?.expire ? formatDate(tokenInfo.expire * 1000) : ''}`}</p>
          <p>{`token: ${tokenInfo?.token || '-'}`}</p>
        </Modal>
      )}
    </>
  );
};

export default BiometricAccess;
