import useConfiguration from 'hooks/useConfiguration';
import { verifyHour } from 'modules/payCash/actions';
import { useEffect, useState } from 'react';

const useOutOfOffice = () => {
  const { getConfigurationValue } = useConfiguration();
  const schedule = getConfigurationValue('paymentSchedule', true);
  const [isOutOfHour, setIsOutOfHour] = useState(false);

  useEffect(() => {
    verifyHour().then((response) => {
      setIsOutOfHour(!response);
    }).catch(() => {
      setIsOutOfHour(true);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isOutOfHour,
    schedule,
  };
};

export default useOutOfOffice;
