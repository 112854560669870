import Layout from 'components/Layout';

const Billing = () => {
  return (
    <Layout
      headerMenu={false}
      onBack
      bottomMenu
    >
      <iframe
        className='embed-page'
        title='Facturación Montepio Luz Savinon'
        src='https://facturacion.montepio.org.mx'
      />
    </Layout>
  );
};

export default Billing;
