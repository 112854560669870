import PropTypes from 'prop-types';
import Button from 'components/UI/Button';
import Modal from 'components/Modal';
import Lottie from 'react-lottie';
import * as animationData from 'assets/animations/done.json';
import { ReactComponent as FaceID } from 'assets/icons/faceID.svg';

const BiometricModal = (props) => {
  const {
    literals,
    success,
    loading,
    onConfirm,
    onClose,
  } = props;

  return (
    <Modal
      position='end'
      className='w-100'
      animation='slide-up-enter'
      onCloseModal={onClose}
      closeOnBackgroundClick={false}
      showCloseButton={false}
    >
      {success ? (
        <>
          <div className='text-center mb-4'>
            <Lottie
              options={{
                loop: false,
                autoplay: true,
                animationData,
                rendererSettings: {
                  preserveAspectRatio: 'xMidYMid slice',
                },
              }}
              height={90}
              width={90}
            />
          </div>
          <h3 className='font-regular mb-4'>{literals.login.activateBiometricSuccess}</h3>
          <Button
            className='w-100 text-80 mb-3'
            text={literals.common.continue}
            onClick={onClose}
          />
        </>
      ) : (
        <>
          <div className='text-center mb-4'>
            <FaceID className='mt-3' width={50} height={50} />
          </div>
          <h1 className='text-primary mb-4'>{literals.login.activateBiometric}</h1>
          <h3 className='font-regular mb-4'>{literals.login.activateBiometricDescription}</h3>
          <Button
            className='w-100 text-80 mb-3'
            text={literals.login.activate}
            onClick={onConfirm}
            loading={loading}
          />
          <Button
            className='w-100 text-80 mb-3'
            type='secondary'
            onClick={onClose}
            text={literals.common.cancel}
            disabled={loading}
          />
        </>
      )}
    </Modal>
  );
};

BiometricModal.propTypes = {
  literals: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  success: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default BiometricModal;
