export const QUOTER_TYPE = {
  CARS: 'cars',
  DIAMONDS: 'diamonds',
  WATCHES: 'watches',
  EXECUTIVE_DRIVER: 'executiveDriver',
  JEWELRY: 'jewelry',
};

export const SHOPPING_TYPE = {
  ONLINE: 'online',
  PHYSICAL: 'physical',
  AUCTIONS: 'auctions',
};

export const OPPORTUNITIES_TYPE = {
  FINSUS: 'finsus',
};
