import { getLocationPermission, getCurrentPosition } from './events';

export const getUserPosition = async (defaultCoords = null) => {
  const hasPermissions = await getLocationPermission();
  if (hasPermissions) {
    if (window.onAndroidApp() || window.onIOSApp()) {
      const { latitude, longitude } = await getCurrentPosition();
      return latitude ? { latitude, longitude } : defaultCoords;
    }
    if (navigator.geolocation) {
      return new Promise((resolve) => {
        try {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              const latitude = position.coords.latitude;
              const longitude = position.coords.longitude;
              resolve({
                latitude,
                longitude,
              });
            },
            () => {
              resolve(defaultCoords);
            },
            {
              timeout: 3000,
            },
          );
        } catch (error) {
          resolve(defaultCoords);
        }
      });
    }
  }

  return defaultCoords;
};
