import { useState } from 'react';
import PropTypes from 'prop-types';
import Translate from 'components/Translate';
import Carousel from 'components/UI/Carousel';
import './styles.scss';
import Buttons from 'components/UI/Buttons';

const Slides = (props) => {
  const {
    slides,
    actions,
  } = props;

  const [active, setActive] = useState(0);

  const handleOnSlideChange = (e) => {
    setActive(e.activeIndex);
  };

  const slidesList = slides.map((slide) => {
    const {
      id,
      title,
      description,
      justify,
      image,
    } = slide;

    return (
      <div key={`slide-${id}`}>
        {slide.image ? (
          <div className={`slide-img mb-5 justify-content-${justify || 'center'}`}>
            <img src={image} alt={title} />
          </div>
        ) : null}
        <div className='slide-step mb-5'>
          {slides.map((_, index) => (
            <span key={`dot-${index}`} className={active === index ? 'active' : ''} />
          ))}
        </div>
        <div className='slide'>
          {title && <Translate component='h1' className='mb-3' literal={title} />}
          {description && <Translate component='p' className='mb-0' literal={description} />}
        </div>
      </div>
    );
  });

  return (
    <>
      <div className='slides mb-5'>
        <Carousel
          slides={slidesList}
          onSlideChange={handleOnSlideChange}
          autoPlay={{
            delay: 15000,
            disableOnInteraction: true,
          }}
          autoHeight={false}
        />
      </div>
      {actions.length ? (<Buttons list={actions} />) : null}
    </>
  );
};

Slides.propTypes = {
  slides: PropTypes.array.isRequired,
  actions: PropTypes.array,
};

Slides.defaultProps = {
  actions: [],
};

export default Slides;
