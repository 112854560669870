/* eslint-disable no-param-reassign */
import axios from 'axios';
import { API_BASE_URL } from 'constants/apiConf';
import { URLS } from 'modules/session/repository';
import { ROUTE_PATH } from 'routes';

const formatMessageError = (error) => {
  return {
    statusCode: error?.response?.status || 400,
    data: {
      status: error?.response?.data?.status || 'ERROR',
      error: error.response?.data?.error || true,
    },
  };
};

const ApiFetch = (baseURL) => {
  if (!baseURL) {
    // eslint-disable-next-line no-param-reassign
    baseURL = API_BASE_URL;
  }
  const instance = axios.create({
    baseURL,
    withCredentials: true,
  });

  instance.interceptors.request.use((config) => {
    return config;
  });

  // Más información: https://github.com/axios/axios#interceptors
  instance.interceptors.response.use(
    (res) => {
      return res.data;
    },
    (err) => {
      if (err?.response?.status === 401 && ![URLS.login, URLS.signinWithToken, URLS.session, URLS.logout].includes(err.config.url)) {
        return new Promise((_, reject) => {
          fetch(`${baseURL}${URLS.session}`, { method: 'GET', credentials: 'include' }).then((res) => {
            if (res?.status === 401) {
              window.location.href = `${ROUTE_PATH.SESSION_CLOSED}?noSplashScreen=1`;
            }
            reject(formatMessageError(err));
          });
        });
      }
      return Promise.reject(formatMessageError(err));
    },
  );

  return instance;
};

export default ApiFetch;
