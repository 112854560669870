import Debugger from 'utils/debugger';
import JewelsQuoterApi from './repository';

export async function getJewelsSimulate(form) {
  try {
    const response = await JewelsQuoterApi.getJewelsSimulate(form?.material, form?.weight);
    return response?.obj_data || null;
  } catch (error) {
    Debugger.error(error);
    return false;
  }
}

export async function getJewelsCatalog() {
  try {
    const response = await JewelsQuoterApi.getJewelsCatalog();
    return response;
  } catch (error) {
    Debugger.error(error);
    return false;
  }
}
