import { loadingActions } from 'modules/loading';
import { DEFAULT_ZOOM } from 'utils/googleMaps';
import { getNearest } from 'utils/distance';
import { storesActions } from '.';
import StoresApi from './repository';

export const getPlacesNearby = (latitude, longitude, zoom = 1) => async (dispatch) => {
  const meters = {
    7: 50000,
    8: 40000,
    9: 30000,
    10: 20000,
    [DEFAULT_ZOOM]: 15000,
  };

  const radius = meters[zoom] || (zoom > DEFAULT_ZOOM ? meters[DEFAULT_ZOOM] : meters[7]);
  dispatch(storesActions.initStores());
  try {
    let list = [];
    const response = await StoresApi.getPlacesNearby(latitude, longitude, radius);
    if (response?.results?.length) {
      list = response?.results.reduce((acc, item) => {
        if (item?.geometry?.location) {
          acc.push({
            id: item.place_id,
            name: item.name,
            location: {
              latitude: item.geometry.location.lat,
              longitude: item.geometry.location.lng,
            },
            address: item.vicinity,
          });
        }
        return acc;
      }, []);
    }
    const nearests = getNearest(latitude, longitude, list, 5);
    dispatch(storesActions.successStores({ list, nearests }));
  } catch (error) {
    dispatch(storesActions.error(error?.message || error));
  }
};

export const getPlace = (id, data = {}) => async (dispatch) => {
  dispatch(storesActions.initStore());
  try {
    const response = await StoresApi.getPlace(id);
    if (response?.result) {
      const auxResult = { ...response.result, ...data };
      const store = {
        id: auxResult.place_id,
        name: `${auxResult.name} ${Math.floor(Math.random() * 100)}`,
        location: {
          latitude: auxResult.geometry?.location?.lat || null,
          longitude: auxResult.geometry?.location?.lng || null,
        },
        address: auxResult.formatted_address || '',
        phone: auxResult.formatted_phone_number || null,
        openNow: auxResult.opening_hours?.open_now || false,
        openingHours: auxResult.current_opening_hours?.weekday_text || [],
      };
      dispatch(storesActions.successStore(store));
      return store;
    }
    dispatch(storesActions.error('storeNotFound'));
  } catch (error) {
    dispatch(storesActions.error(error?.message || error));
  }

  return false;
};

export const getStores = (search) => async (dispatch) => {
  dispatch(loadingActions.show());

  return StoresApi.getStores(search)
    .then((response) => {
      dispatch(storesActions.successStores({
        list: (response || [])
          .filter((item) => item.latitude && item.longitude && item.latitude !== '0.000000000000' && item.longitude !== '0.000000000000')
          .map((item) => ({
            ...item,
            address: `${item.street} ${item.number}, ${item.neighborhood}, ${item.municipality} ${item.postalCode}`,
          })),
      }));
      dispatch(loadingActions.hide());
      return true;
    })
    .catch((error) => {
      dispatch(storesActions.error(error?.message || error));
      dispatch(loadingActions.hide());
      return false;
    });
};

export const setStore = (store) => (dispatch) => {
  dispatch(storesActions.successStore(store));
};

export const cleanStore = () => (dispatch) => {
  dispatch(storesActions.clearStore());
};

export const updateCoords = (coords) => (dispatch) => {
  dispatch(storesActions.setCoords(coords));
};
