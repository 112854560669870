import { useMemo, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { GA_EVENTS } from 'constants/gaEvents';
import { sendGaEvent } from 'utils/sendGaEvent';
import useLiterals from 'utils/hooks/useLiterals';
import Layout from 'components/Layout';
import Box from 'components/UI/Box/Box';
import Modal from 'components/Modal';
import StoresList from 'components/StoresList';
import { ROUTE_PATH } from 'routes';
import { BOTTOM_MENU_ACTIONS } from 'components/Layout/components/BottomMenu';
import Button from 'components/UI/Button';
import StoresMap from 'components/StoresMap';
import { cleanStore } from 'modules/stores/actions';
import StoreContact from './Components/StoreContact';

const STEP_FORM = 'form';

const Stores = () => {
  const literals = useLiterals('stores');
  const navigate = useNavigate();
  const { store } = useSelector((state) => state.stores);
  const [contactForm, setContactForm] = useState({});
  const [showSuccess, setShowSuccess] = useState(false);
  const { step } = useParams();
  const showForm = step === STEP_FORM;
  const dispatch = useDispatch();

  const bottomMenu = useMemo(() => {
    if (showForm) {
      return false;
    }
    if (store) {
      return {
        type: BOTTOM_MENU_ACTIONS.APPOINTMENT,
        label: literals.schedule,
        onClick: () => navigate(`${ROUTE_PATH.APPOINTMENT}?name=${store.name}&address=${store.address}&phone=${store.phone1}`),
      };
    }
    return true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showForm, store]);

  useEffect(() => {
    sendGaEvent(GA_EVENTS.STORES_LIST);
  }, []);

  const handleSubmit = () => {
    setShowSuccess(true);
  };

  const handleShowForm = () => {
    navigate(ROUTE_PATH.APPOINTMENT);
  };

  const handleCloseSuccess = () => {
    setShowSuccess(false);
    navigate(ROUTE_PATH.setStores(), { replace: true });
    setContactForm({});
  };

  const handleCancelForm = () => {
    navigate(ROUTE_PATH.setStores(), { replace: true });
    setContactForm({});
  };

  return (
    <Layout
      title={literals.title}
      onBack
      bottomMenu={bottomMenu}
    >
      <div className='shops'>
        <Box>
          {showForm ? (
            <StoreContact
              literals={literals}
              store={store}
              form={contactForm}
              onChange={(p, v) => setContactForm((prev) => ({ ...prev, [p]: v }))}
              onAppointment={handleSubmit}
              onCancel={handleCancelForm}
            />
          ) : (
            <>
              <StoresMap
                searcher={{
                  title: literals.search,
                  placeholder: literals.addressSearch,
                }}
              />
              {store && (
                <div>
                  {store.phone1 ? (
                    <Button
                      type='secondary'
                      text={literals.call}
                      className='mt-3'
                      to={`tel:${store.phone1}`}
                    />
                  ) : null}
                  <Button
                    className='mt-3'
                    text={literals.scheduleAppointment}
                    onClick={handleShowForm}
                  />
                  <Button
                    className='mt-3'
                    type='secondary'
                    text={literals.searchOther}
                    onClick={() => dispatch(cleanStore())}
                  />
                </div>
              )}
            </>
          )}
        </Box>
        {!showForm && (
          <StoresList />
        )}
        {showSuccess && (
          <Modal
            onCloseModal={handleCloseSuccess}
            showSuccessIcon
            position='center'
          >
            <div className='text-center'>
              <h3 className='text-uppercase mb-4'>{literals.appointmentSuccess}</h3>
              <p className='font-bold mb-2'>{literals.shop}</p>
              <p className='text-gray mb-3'>{store.name}</p>
              <p className='font-bold mb-2'>{literals.shopAddress}</p>
              <p className='text-gray mb-3'>{store.address}</p>
              <p className='font-bold mb-2'>{literals.contactNumber}</p>
              <p className='text-gray mb-3'>{store.phone}</p>
              <p className='font-bold mb-2'>{literals.appointmentDate}</p>
              <p className='text-gray'>{`${contactForm.date} ${contactForm.hour || ''}`}</p>
            </div>
          </Modal>
        )}
      </div>
    </Layout>
  );
};

export default Stores;
