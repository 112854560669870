import { Navigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { onLogout } from 'modules/session/actions';
import { ROUTE_PATH } from 'routes';

function Logout() {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => state.session.authenticated);

  const logout = () => {
    dispatch(onLogout());
  };

  logout();

  if (!isAuthenticated) {
    return (
      <Navigate to={ROUTE_PATH.BASE} />
    );
  }

  return <div />;
}

export default Logout;
