import PropTypes from 'prop-types';
import ringImg from 'assets/images/ring.jpg';
import Box from 'components/UI/Box/Box';
import Input from 'components/UI/Input';
import Button from 'components/UI/Button';
import Select from 'components/UI/Select';

const JewelryQuoter = (props) => {
  const {
    literals,
    form,
    loading,
    onChange,
    onSubmit,
  } = props;
  const { jewelry: literalsJewelry } = literals;
  const disabled = !form.material || !form.weight;

  return (
    <Box className='jewelryQuoter-box mb-3'>
      <img className='mb-4' src={ringImg} alt='Ring' />
      <h2 className='mb-3'>{literalsJewelry.title}</h2>
      <p className='mb-4'>{literalsJewelry.info}</p>
      <form onSubmit={onSubmit}>
        <Select
          className='mb-4'
          value={form.material || ''}
          placeholder={literalsJewelry.carats}
          onChange={(v) => onChange('material', v)}
          options={[
            { value: '8K ORO', label: '8K ORO' },
            { value: '10K ORO', label: '10K ORO' },
            { value: '14K ORO', label: '14K ORO' },
            { value: '18K ORO', label: '18K ORO' },
          ]}
          required
        />
        <Input
          type='number'
          className='mb-4'
          placeholder={literalsJewelry.weightInGrams}
          value={form.weight || ''}
          onChange={(v) => {
            const regex = /^\d*\.?\d{0,3}$/;
            if (regex.test(v) || v === '') {
              onChange('weight', v);
            }
          }}
          required
        />
        <Button isSubmitBtn type='primary' text={literalsJewelry.getQuote} disabled={disabled} loading={loading} />
      </form>
    </Box>
  );
};

JewelryQuoter.propTypes = {
  literals: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default JewelryQuoter;
