import Proptypes from 'prop-types';
import {
  Children, useEffect, useMemo, useState,
} from 'react';
import { getCmsPage } from 'modules/cms/actions';
import { useDispatch, useSelector } from 'react-redux';
import useCmsPreview from 'hooks/useCmsPreview';
import { CMS_MESSAGES } from 'constants/cms';
import { motion } from 'framer-motion';
import Block from './components/Block';

const CmsBlocks = (props) => {
  const {
    children,
    animate,
    renderOnError,
    blockProps,
  } = props;

  const [previewBlocks, setPreviewBlocks] = useState(null);
  const dispatch = useDispatch();
  const path = document.location.pathname.replace(/\//g, '');
  const { pages: { data: pages } } = useSelector((state) => state.cms);
  const { value: previewMessage } = useCmsPreview(CMS_MESSAGES.UPDATE_BLOCKS);
  const numChilds = useMemo(() => (children ? Children.toArray(children).filter(Boolean).length : 0), [children]);
  const page = pages[path] || null;
  const isReady = page?.loaded;

  useEffect(() => {
    if (!pages[path]) {
      dispatch(getCmsPage(path));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (Array.isArray(previewMessage)) {
      setPreviewBlocks(previewMessage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [previewMessage]);

  if (page?.error) {
    return renderOnError;
  }

  if (!page || page?.loading) {
    return null;
  }

  return (
    <>
      {isReady && Children.map(children, (child, i) => (
        <motion.div
          key={`block-${i}`}
          {...(animate || {})}
          custom={i}
        >
          {child}
        </motion.div>
      ))}
      {(previewBlocks || page?.blocks || []).map((block, i) => {
        const auxProps = {
          key: block.id,
          type: block.type,
          value: block.value,
          blockProps,
        };
        if (!animate) {
          return (<Block {...auxProps} />);
        }
        return (
          <motion.div
            key={`block-${i + numChilds}`}
            {...(animate || {})}
            custom={i + numChilds}
          >
            <Block {...auxProps} />
          </motion.div>
        );
      }) || null}
    </>
  );
};

CmsBlocks.propTypes = {
  animate: Proptypes.object,
  children: Proptypes.node,
  renderOnError: Proptypes.node,
  blockProps: Proptypes.object,
};

CmsBlocks.defaultProps = {
  children: null,
  animate: null,
  renderOnError: null,
  blockProps: {},
};

export default CmsBlocks;
