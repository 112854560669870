import { loadingActions } from 'modules/loading';
import { SECTION } from 'constants/global';
import { sectionsActions } from '.';
import SectionsApi from './repository';

export const getSections = () => async (dispatch) => {
  dispatch(loadingActions.show());

  return SectionsApi.getSections()
    .then((sections) => {
      if (!sections) {
        dispatch(sectionsActions.error('No sections found'));
        dispatch(loadingActions.hide());
        return false;
      }
      const auxSections = (sections || []).map((section) => {
        const auxDate = section.expirationDate.split('/').reverse().join('-');
        return {
          ...section,
          auxType: SECTION,
          auxOrder: new Date(auxDate).getTime(),
        };
      });
      auxSections.sort((a, b) => a.auxOrder - b.auxOrder);

      dispatch(sectionsActions.success(auxSections));
      dispatch(loadingActions.hide());
      return true;
    })
    .catch((error) => {
      dispatch(sectionsActions.error(error?.message || error));
      dispatch(loadingActions.hide());
      return false;
    });
};

export const getSection = (sectionId) => {
  return SectionsApi.getSection(sectionId);
};

export const getSectionPayments = (id, status = []) => {
  return SectionsApi.getSectionPayments(id, status);
};

export const getSectionReference = (id, referenceId) => {
  return SectionsApi.getReference(id, referenceId);
};

export const getSectionNotePDF = (id, referenceId) => {
  return SectionsApi.getNotePDF(id, referenceId)
    .then((blob) => {
      window.share('note.pdf', blob, 'application/pdf');
      return true;
    }).catch((error) => {
      console.error(error);
      return false;
    });
};
