import { Component } from 'react';
import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
import { ROUTE_PATH } from 'routes';
import { sendSentryLog } from 'utils/sendSentryLog';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ hasError: true }, () => {
      if (window.location.hostname === 'localhost') {
        console.error('<----- ERROR ---->');
        console.error(error ? error.toString() : '');
        console.error(errorInfo.componentStack);
      } else {
        const errorType = error ? error.toString() : 'ErrorBoundary';
        sendSentryLog(error, errorType);
      }
    });
  }

  resetState = () => {
    this.setState({ hasError: false });
  };

  render() {
    const { children } = this.props;
    const { hasError } = this.state;

    if (hasError) {
      this.resetState();
      return (
        <Navigate replace to={ROUTE_PATH.setError(500)} />
      );
    }
    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ErrorBoundary;
