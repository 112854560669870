import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'utils/translate';

const Translate = (props) => {
  const {
    literal,
    vars,
    component,
    ...rest
  } = props;

  const phrase = useMemo(() => {
    return translate(literal, vars).replace(/\n/g, '<br/>');
  }, [literal, vars]);

  return React.createElement(component, { dangerouslySetInnerHTML: { __html: phrase }, ...rest });
};

Translate.propTypes = {
  literal: PropTypes.string,
  vars: PropTypes.object,
  component: PropTypes.oneOf(['span', 'p', 'h1', 'h2', 'h3', 'h4', 'h5', 'div']),
};

Translate.defaultProps = {
  literal: '',
  vars: {},
  component: 'p',
};

export default Translate;
