import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import CurrencyInput from 'react-currency-input-field';
import './styles.scss';

const floatValue = (value) => {
  if (typeof value === 'string') {
    return parseFloat(value.replace(/,/g, ''));
  }
  return value;
};

function InputAmount(props) {
  const {
    className,
    classNameInput,
    label,
    placeholder,
    onChange,
    value,
    min,
    max,
    disabled,
    error,
    required,
  } = props;

  const [amount, setAmount] = useState(value);

  useEffect(() => {
    if (value !== amount) {
      try {
        const valueFloat = floatValue(value);
        const amountFloat = floatValue(amount);
        if (valueFloat !== amountFloat) {
          setAmount(value || null);
        }
      } catch (e) {
        console.error(e);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const handleOnChange = (v) => {
    setAmount(v);
    onChange(v);
  };

  const handleOnBlur = () => {
    let newValue = null;
    if (typeof amount === 'string') {
      if (amount.endsWith('.')) {
        newValue = amount.slice(0, -1);
      } else if (amount.includes('.') && amount.split('.')[1].length === 1) {
        newValue = `${amount}0`;
      }
    }
    const amountFloat = floatValue(amount);
    if (min !== null && (amountFloat < min || ['', null, undefined].includes(amount))) {
      newValue = min;
    } else if (max !== null && amountFloat > max) {
      newValue = max;
    }
    if (newValue !== null) {
      handleOnChange(newValue);
    }
  };

  return (
    <div className={`ui-input input-amount ${className} ${error ? 'invalid-value' : ''}`}>
      {label && (
        <label className='mb10'>
          {label}
          {required && '*'}
        </label>
      )}
      <CurrencyInput
        id='currency-input'
        className={`form-control ${classNameInput}`}
        placeholder={placeholder ? `${placeholder}${required ? '*' : ''}` : '$0.00'}
        value={amount}
        decimalsLimit={2}
        onValueChange={(v) => handleOnChange(v)}
        decimalSeparator='.'
        groupSeparator=','
        prefix='$'
        allowNegativeValue={false}
        intlConfig={{ locale: 'es-MX', currency: 'MXN' }}
        disabled={disabled}
        onBlur={handleOnBlur}
      />
    </div>
  );
}

InputAmount.propTypes = {
  className: PropTypes.string,
  classNameInput: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  onChange: PropTypes.func.isRequired,
  min: PropTypes.number,
  max: PropTypes.number,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  required: PropTypes.bool,
};

InputAmount.defaultProps = {
  className: '',
  classNameInput: '',
  label: '',
  placeholder: '',
  value: null,
  min: null,
  max: null,
  disabled: false,
  error: false,
  required: false,
};

export default InputAmount;
