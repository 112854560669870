import PropTypes from 'prop-types';

const DocumentMessage = ({ text }) => {
  return (
    <div className='text-center m-5'>
      <p>{text}</p>
    </div>
  );
};

DocumentMessage.propTypes = {
  text: PropTypes.string.isRequired,
};

export default DocumentMessage;
