/* eslint-disable no-use-before-define */
import {
  useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  cleanStore, setStore, updateCoords,
} from 'modules/stores/actions';
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
import Input from 'components/UI/Input';
import Button from 'components/UI/Button';
import NearestStores from 'components/NearestStores';
import useLiterals from 'utils/hooks/useLiterals';
import useNearestStores from 'utils/hooks/useNearestsStores';
import {
  DEFAULT_ZOOM, SEARCH_ZOOM, acquireUserLocation,
} from 'utils/googleMaps';
import Map from './components/Map';

import './styles.scss';

const StoresMap = (props) => {
  const {
    searcher,
    height,
    userPosition,
    showMap,
    showNearests,
    showInfo,
  } = props;

  const literals = useLiterals('stores');
  const {
    list: stores,
    store,
    coords: coordsSaved,
  } = useSelector((state) => state.stores);

  const dispatch = useDispatch();
  const [search, setSearch] = useState('');
  const [coords, setCoords] = useState(null);
  const nearestsStores = useNearestStores(coords || {});
  const [zoom, setZoom] = useState(DEFAULT_ZOOM);
  const urlParams = new URLSearchParams(window.location.search);
  const storeParam = urlParams.get('store');

  useEffect(() => {
    if (coordsSaved?.latitude) {
      setCoords({ ...coordsSaved });
    } else if (userPosition?.latitude) {
      setCoords(userPosition);
      dispatch(updateCoords(userPosition));
    } else {
      acquireUserLocation().then((userCoords) => {
        setCoords(userCoords);
        dispatch(updateCoords(userCoords));
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (storeParam && stores?.length > 0) {
      const storeData = stores.find((s) => s?.id === storeParam);
      if (storeData) {
        handleShowStore(storeData);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stores, storeParam]);

  useEffect(() => {
    if (store && store?.latitude) {
      const newCords = {
        latitude: Number(store.latitude),
        longitude: Number(store.longitude),
      };
      dispatch(updateCoords(newCords));
      setCoords(newCords);
      setZoom(SEARCH_ZOOM);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store]);

  const handleShowStore = (selected) => {
    if (selected.id === store?.id) {
      return;
    }
    dispatch(setStore(selected));
  };

  const handleOnMove = (newCoords) => {
    setCoords(newCoords);
    dispatch(updateCoords(newCoords));
  };

  const handleSearch = () => {
    const geocoder = new window.google.maps.Geocoder();
    if (!search) {
      if (store) {
        dispatch(cleanStore());
      }
    } else {
      geocoder.geocode({ address: `Mexico ${search}` }, (results, status) => {
        if (status === 'OK') {
          const newCoords = {
            latitude: results[0].geometry.location.lat(),
            longitude: results[0].geometry.location.lng(),
          };
          setCoords(newCoords);
          dispatch(updateCoords(newCoords));
          if (store) {
            dispatch(cleanStore());
          }
        } else {
          console.error(`Geocode was not successful for the following reason: ${status}`);
        }
      });
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  return (
    <div className='stores-map-wrapper'>
      {showMap && (
        <>
          <div className='position-relative'>
            {coords && (
              <Map
                data={stores || []}
                coords={coords}
                height={height}
                zoom={zoom}
                userPosition={userPosition}
                onMove={handleOnMove}
                onMarkerClick={handleShowStore}
              />
            )}
          </div>
          {searcher && (
            <>
              {searcher.title && <p className='h1 mt-4'>{searcher.title}</p>}
              <div className='searcher mt-4'>
                <Input
                  placeholder={searcher.placeholder}
                  value={search}
                  onChange={(v) => setSearch(v)}
                  onKeyDown={handleKeyDown}
                />
                <Button className='no-hover-btn' isIconButton onClick={handleSearch}>
                  <SearchIcon />
                </Button>
              </div>
            </>
          )}
        </>
      )}
      {(!store || !showInfo) && showNearests && (
        <NearestStores
          className='mt-4'
          nearests={nearestsStores}
          onClick={(s) => handleShowStore(s)}
        />
      )}
      {store && showInfo && (
        <div className={`mt-${showMap ? 4 : 0}`}>
          <h4>{literals.shop}</h4>
          <p className='text-gray-dark'>{store.name}</p>
          <h4>{literals.shopAddress}</h4>
          <p className='text-gray-dark'>{store.address}</p>
          <h4>{literals.contactNumber}</h4>
          <p className='text-gray-dark'>
            {store.phone1 || '-'}
            {store.phone2 ? (
              <>
                <br />
                {store.phone2}
              </>
            ) : null}
          </p>
          {store.email && (
            <>
              <h4>{literals.email}</h4>
              <p>{store.email || '-'}</p>
            </>
          )}
          <h4>{literals.timetable}</h4>
          <p className='text-gray-dark'>
            {`L-V: ${store?.weekdaySchedule || '-'}`}
            <br />
            {`S: ${store?.saturdaySchedule || '-'}`}
            <br />
            {`D: ${store?.sundaySchedule || '-'}`}
          </p>
        </div>
      )}
    </div>
  );
};

StoresMap.propTypes = {
  height: PropTypes.number,
  searcher: PropTypes.object,
  userPosition: PropTypes.object,
  showMap: PropTypes.bool,
  showNearests: PropTypes.bool,
  showInfo: PropTypes.bool,
};

StoresMap.defaultProps = {
  searcher: null,
  height: 250,
  userPosition: null,
  showMap: true,
  showNearests: true,
  showInfo: true,
};

export default StoresMap;
