import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import diamondsImg from 'assets/images/diamonds_multiple.jpg';
import Box from 'components/UI/Box/Box';
import Input from 'components/UI/Input';
import Button from 'components/UI/Button';
import { getDiamondsCatalog } from 'modules/quotes/diamondsQuoter/actions';
import { loadingActions } from 'modules/loading';

const DiamondsQuoter = (props) => {
  const {
    literals,
    form,
    loading,
    onChange,
    onSubmit,
  } = props;
  const { diamonds: literalsDiamonds } = literals;
  const dispatch = useDispatch();
  const disabled = !form.weight || !form.color?.id || !form.purity || !form.cut || !form.amount;
  const [diamondCatalog, setDiamondCatalog] = useState([]);

  useEffect(() => {
    dispatch(loadingActions.show());
    getDiamondsCatalog()
      .then((response) => {
        dispatch(loadingActions.hide());
        setDiamondCatalog(response?.obj_data || []);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box className='jewelryQuoter-box mb-3'>
      <img className='mb-4 no-top-padding no-left-padding' src={diamondsImg} alt='Diamonds' />
      <h2 className='mb-3'>{literalsDiamonds.title}</h2>
      <p className='mb-4'>{literalsDiamonds.info}</p>
      <form onSubmit={onSubmit}>
        <Input
          type='number'
          className='mb-4'
          placeholder={literalsDiamonds.weightInCarats}
          value={form.weight || ''}
          onChange={(v) => onChange('weight', v)}
          required
        />
        <Input
          type='number'
          className='mb-4'
          placeholder={literalsDiamonds.amount}
          value={form.amount || ''}
          onChange={(v) => onChange('amount', v)}
          required
        />
        <h3>{literalsDiamonds.diamondColor}</h3>
        <div className='question-diamonds row mb-4'>
          {diamondCatalog.filter((x) => x.NombreCatalogo === 'COLOR').map((option, index) => (
            <div className='col col-2' key={`COLOR-${index}`}>
              <div
                className={`d-flex flex-column align-items-center ${form.color?.id === option.IdWeb ? 'selected' : ''}`}
                onClick={() => {
                  onChange('color', {
                    id: form.color?.id === option.IdWeb ? '' : option.IdWeb,
                    name: `${option.IdWeb[0]}-${option.IdWeb[option.IdWeb.length - 1]}`,
                  });
                }}
              >
                <div className='answer-image '>
                  <img src={require(`assets/images/diamonds/${option.IdWeb?.toLowerCase()}.jpg`)} alt={option.label} />
                </div>
                <p className='mb-0 mt-1'><b>{`${option.IdWeb[0]}-${option.IdWeb[option.IdWeb.length - 1]}`}</b></p>
              </div>
            </div>
          ))}
        </div>
        <h3>{literalsDiamonds.purity}</h3>
        <div className='question-diamonds row mb-4'>
          {diamondCatalog.filter((x) => x.NombreCatalogo === 'CLARIDAD').map((option, index) => (
            <div className='col col-4' key={`CLARIDAD-${index}`}>
              <div
                className={`mb-3 d-flex flex-column align-items-center ${form.purity === option.IdWeb ? 'selected' : ''}`}
                onClick={() => onChange('purity', form.purity === option.IdWeb ? '' : option.IdWeb)}
              >
                <div className='answer-image purity'>
                  <img src={require(`assets/images/diamonds/${option.IdWeb?.toLowerCase()}.jpg`)} alt={option.label} />
                </div>
                <p className='mb-0 mt-1 text-center'><b>{option.IdWeb}</b></p>
              </div>
            </div>
          ))}
        </div>
        <h3>{literalsDiamonds.diamondCut}</h3>
        <div className='question-diamonds row mb-4'>
          {diamondCatalog.filter((x) => x.NombreCatalogo === 'CORTE').map((option, index) => (
            <div className='col col-4' key={`CORTE-${index}`}>
              <div
                className={`mb-3 d-flex flex-column align-items-center ${form.cut === option.IdWeb ? 'selected' : ''}`}
                onClick={() => onChange('cut', form.cut === option.IdWeb ? '' : option.IdWeb)}
              >
                <div className='answer-image cut'>
                  <img src={require(`assets/images/diamonds/${option.IdWeb?.toLowerCase()}.jpg`)} alt={option.label} />
                </div>
                <p className='mb-0 mt-1'><b>{option.IdWeb}</b></p>
              </div>
            </div>
          ))}
        </div>
        <Button isSubmitBtn type='primary' text={literalsDiamonds.getQuote} disabled={disabled} loading={loading} />
      </form>
    </Box>
  );
};

DiamondsQuoter.propTypes = {
  literals: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default DiamondsQuoter;
