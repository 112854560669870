import Debugger from 'utils/debugger';
import WatchesQuoterApi from './repository';

export async function getWatchesCatalog() {
  try {
    const response = await WatchesQuoterApi.getWatchesCatalog();
    if (response?.obj_data && response?.obj_data.length > 0 && response?.obj_data[0].Informacion) {
      const uniqueData = Object.values(response?.obj_data[0].Informacion.reduce((acc, obj) => {
        acc[obj.Clave] = obj;
        return acc;
      }, {}));
      return uniqueData;
    }
    return [];
  } catch (error) {
    Debugger.error(error);
    return false;
  }
}

export async function getWatchesSimulate(form) {
  try {
    const response = await WatchesQuoterApi.getWatchesSimulate(encodeURIComponent(form.brand), form.averagePrice);
    return response?.obj_data || null;
  } catch (error) {
    Debugger.error(error);
    return false;
  }
}
