import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import Button from 'components/UI/Button';
import useLiterals from 'utils/hooks/useLiterals';
import Translate from 'components/Translate';
import { TIMEZONE } from 'constants/common';
import './styles.scss';

const PaymentOutOfHour = ({ schedule }) => {
  const navigate = useNavigate();
  const literals = useLiterals('common');

  let now = new Date();
  now.setHours(now.getUTCHours() + TIMEZONE);
  now = `${now.getHours().toString().padStart(2, '0')}:${now.getMinutes().toString().padStart(2, '0')}`;

  return (
    <div className='payments-out-of-hour'>
      <Translate className='text-primary pt-3' literal={literals.outOfHour} vars={{ now }} />
      {(schedule || []).map((hours, i) => (
        <div key={`day-${i}`} className='text-gray d-flex align-items-center mb-2'>
          <p className='m-0 pe-2'>{`${literals.days[i] || i}:`}</p>
          <div className='hours d-flex'>
            {hours.length ? hours.map((h, j) => (
              <p key={`h-${j}`} className='text-gray'>
                {`${h[0]} - ${h[1]}`}
              </p>
            )) : (
              <p className='text-gray'>-</p>
            )}
          </div>
        </div>
      ))}
      <Button onClick={() => navigate(-1)} text={literals.back} className='mt-3' />
    </div>
  );
};

PaymentOutOfHour.propTypes = {
  schedule: PropTypes.array.isRequired,
};

export default PaymentOutOfHour;
