// import { SUCCESS, FAIL } from 'store';

export const RESET = 'app/reset';
export const UPDATE_PREV_PATH = 'app/update_prev_path';
export const SET_IS_APP = 'app/set_is_app';
export const SET_REDIRECT = 'app/redirect';

export const initialState = {
  prevPath: null,
  redirect: null,
  error: null,
  isApp: false,
};

const reducer = (state = initialState, { type, payload } = {}) => {
  switch (type) {
    case UPDATE_PREV_PATH:
      return {
        ...state,
        prevPath: payload,
      };
    case SET_REDIRECT:
      return {
        ...state,
        redirect: payload,
      };
    case SET_IS_APP:
      return {
        ...state,
        isApp: payload,
      };
    case RESET:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
