import Debugger from 'utils/debugger';
import ClientsApi from './repository';

export async function isUserRegistered(form) {
  try {
    const response = await ClientsApi.isUserRegistered(form.clientValue, form.clientType);
    return {
      exists: true,
      info: response,
    };
  } catch (error) {
    Debugger.error(error);
    return {
      exists: false,
      info: error.statusCode || 404,
    };
  }
}

export async function getStates() {
  try {
    const response = await ClientsApi.getStates();
    return response;
  } catch (error) {
    Debugger.error(error);
    return [];
  }
}

export async function getMunicipalities(stateId) {
  try {
    const response = await ClientsApi.getMunicipalities(stateId);
    return response;
  } catch (error) {
    Debugger.error(error);
    return [];
  }
}

export async function getColonies(stateId, municipalityId) {
  try {
    const response = await ClientsApi.getColonies(stateId, municipalityId);
    return response;
  } catch (error) {
    Debugger.error(error);
    return [];
  }
}
