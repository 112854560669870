/* eslint-disable */
import Debugger from 'utils/debugger';
import { loadingActions } from 'modules/loading';
import { PAWNED_CATEGORY } from 'constants/global';
import { llmsActions } from '.';
import LlmsApi from './repository';

export const llmsIdentifyPhoto = (photo) => async (dispatch) => {
  try {
    // dispatch(loadingActions.show());
    const conversation = await LlmsApi.createConversation(window.LLMS_VISION_ID);
    if (conversation && conversation.id) {
      const formData = new FormData();
      formData.append('file', photo, photo.name);
      const uploadPhotoResponse = await LlmsApi.uploadPhoto(window.LLMS_VISION_ID, conversation.id, formData);
      if (uploadPhotoResponse) {
        const categories = Object.values(PAWNED_CATEGORY).map((value) => `"${value}"`).join(', ');
        const prompt = '';
        const res = await LlmsApi.sendPrompt(window.LLMS_VISION_ID, conversation.id, prompt);

        const result = {};
        result.visionChatbot = res.vision;
        result.brand = {
          "Clave": res.get_brand_id || null,
        };
        result.model = {
          "Clave": res.get_model_id || null,
        };
        result.version = {
          "Clave": res.get_version_id || null,
        };

        if (res.all_brands && res.all_brands.obj_data && res.all_brands.obj_data.length && result.brand && result.brand.Clave) {
          const foundBrand = res.all_brands.obj_data.find((item) => item.Clave === result.brand.Clave);
          if (foundBrand) {
            result.brand.Nombre = foundBrand.Nombre;
          }
        }

        if (res.all_models && res.all_models.obj_data && res.all_models.obj_data.length && result.model && result.model.Clave) {
          const foundModel = res.all_models.obj_data.find((item) => item.Clave === result.model.Clave);
          if (foundModel) {
            result.model.Nombre = foundModel.Nombre;
          }
        }

        if (res.all_versions && res.all_versions.obj_data && res.all_versions.obj_data.length && result.version && result.version.Clave) {
          const foundVersion = res.all_versions.obj_data.find((item) => item.Clave === result.version.Clave);
          if (foundVersion) {
            result.version.Nombre = foundVersion.Nombre;
          }
        }

        if (res.watches && res.watches.length && result.brand && result.brand.Clave) {
          const foundWatch = res.watches.find((item) => item.Clave === result.brand.Clave);
          if (foundWatch) {
            result.brand.Descripcion = foundWatch.Descripcion;
          }
        }

        // const visionResponse = res.find((item) => item.name === 'Vision');
        // if (visionResponse) {
        //   const visionChatbot = JSON.parse(visionResponse.result[0].result);
        //   result.visionChatbot = visionChatbot;
        // }
        // const brandResponse = res.find((item) => item.name === 'get_brand_id');
        // console.log('brandResponse', brandResponse);
        // if (brandResponse && brandResponse?.result[0]?.entry_variables?.all_brands) {
        //   const brand = brandResponse.result[0].entry_variables.all_brands?.obj_data?.find((item) => item.Clave === brandResponse.result[0].result);
        //   result.brand = brand;
        // }
        // console.log('brandResponse?.result[0]?.entry_variables?.all_brands_unique', brandResponse?.result[0]?.entry_variables?.all_brands_unique);
        // if (brandResponse && brandResponse?.result[0]?.entry_variables?.all_brands_unique) {
        //   const brandUnique = brandResponse.result[0].entry_variables.all_brands_unique?.find((item) => item.Clave === brandResponse.result[0].result);
        //   console.log('brandUnique', brandUnique);
        //   result.brand = brandUnique;
        // }
        // const modelResponse = res.find((item) => item.name === 'get_model_id');
        // console.log('modelResponse', modelResponse);
        // if (modelResponse && modelResponse?.result[0]?.entry_variables?.all_models) {
        //   const model = modelResponse.result[0].entry_variables.all_models?.obj_data?.find((item) => item.Clave === modelResponse.result[0].result);
        //   result.model = model;
        // }
        // const versionResponse = res.find((item) => item.name === 'get_version_id');
        // console.log('versionResponse', versionResponse);
        // if (versionResponse && versionResponse?.result[0]?.entry_variables?.all_versions) {
        //   const version = versionResponse.result[0].entry_variables.all_versions?.obj_data?.find((item) => item.Clave === versionResponse.result[0].result);
        //   result.version = version;
        // }
        console.log('result', result);
        dispatch(llmsActions.success(result));
        // dispatch(loadingActions.hide());
        return result;
      }
    }
    // dispatch(loadingActions.hide());
  } catch (error) {
    Debugger.error(error);
    // dispatch(loadingActions.hide());
    return false;
  }
};