import PropTypes from 'prop-types';
import Card from 'components/Card';
import Buttons from 'components/UI/Buttons';
import { motion } from 'framer-motion';
import { EFFECT_LIST_BOX_UP_IN_VIEW } from 'constants/effects';

const WelcomeView = (props) => {
  const {
    preTitle,
    title,
    cards,
    actions,
  } = props;

  return (
    <>
      {preTitle && (
        <p className='text-gray'>{preTitle}</p>
      )}
      {title && (
        <h1 className='mb-4'>{title}</h1>
      )}
      {cards?.length ? cards.map((card, i) => (
        <motion.div
          key={`card-${card.id}`}
          {...EFFECT_LIST_BOX_UP_IN_VIEW}
          custom={i}
        >
          <Card key={`card-${card.id}`} {...card} />
        </motion.div>
      )) : null}
      {actions?.length ? (
        <Buttons className='mt-4 mb-4' list={actions} />
      ) : null}
    </>
  );
};

WelcomeView.propTypes = {
  preTitle: PropTypes.string,
  title: PropTypes.string,
  cards: PropTypes.array,
  actions: PropTypes.array,
};

WelcomeView.defaultProps = {
  preTitle: '',
  title: '',
  cards: [],
  actions: [],
};

export default WelcomeView;
