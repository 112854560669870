import { useEffect, useState } from 'react';
import { canUseBiometrics } from 'utils/events';

export const useBiometricAvailable = () => {
  const [biometricAvailable, setBiometricAvailable] = useState(null);

  useEffect(() => {
    canUseBiometrics().then((v) => {
      setBiometricAvailable(v === true);
    }).catch(() => {
      setBiometricAvailable(false);
    });
  }, []);

  return biometricAvailable;
};
