/* eslint-disable no-param-reassign */
export const EVENT_TYPE_BIOMETRIC_AVAILABILITY = 'biometric-availability';
export const EVENT_TYPE_BIOMETRIC_TYPE = 'biometric-type';
export const EVENT_TYPE_CAMERA_PERMISSIONS = 'camera-permissions';
export const EVENT_TYPE_LOCATION_PERMISSIONS = 'location-permissions';
export const EVENT_TYPE_LOCATION_DATA = 'location-data';
export const EVENT_TYPE_NOTIFICATIONS_PERMISSIONS = 'notifications-permissions';
export const EVENT_TYPE_LIBRARY_PERMISSIONS = 'library-permissions';
export const EVENT_TYPE_NOTIFICATIONS_TOKEN = 'notifications-token';
export const EVENT_TYPE_STORAGE_RESPONSE = 'storage-response';
export const EVENT_TYPE_BIOMETRIC_RESPONSE = 'biometric-response';
export const EVENT_TYPE_BACK_BUTTON = 'back-button';

export function saveStoreData(key, value) {
  if (typeof value === 'object') {
    value = JSON.stringify(value);
  }

  window.saveValue(key, value);
}

export function getStoreData(key) {
  return new Promise((resolve) => {
    const callback = (event) => {
      document.removeEventListener(EVENT_TYPE_STORAGE_RESPONSE, callback);
      resolve(event.detail.data);
    };

    document.addEventListener(EVENT_TYPE_STORAGE_RESPONSE, callback);
    window.getValue(key);
  });
}

export function deleteStoreData(key) {
  window.deleteValue(key);
}

export function showBiometrics(key) {
  return new Promise((resolve, reject) => {
    const callback = (event) => {
      document.removeEventListener(EVENT_TYPE_BIOMETRIC_RESPONSE, callback);
      if (event.detail.success) {
        resolve(event.detail.data);
      } else {
        reject(event.detail.data);
      }
    };

    document.addEventListener(EVENT_TYPE_BIOMETRIC_RESPONSE, callback);
    window.showBiometrics(key);
  });
}

export function canUseBiometrics() {
  return new Promise((resolve) => {
    const callback = (event) => {
      document.removeEventListener(EVENT_TYPE_BIOMETRIC_AVAILABILITY, callback);
      if (event.detail.biometricsAvailable) {
        resolve(true);
      } else {
        resolve(false);
      }
    };
    document.addEventListener(EVENT_TYPE_BIOMETRIC_AVAILABILITY, callback);
    window.canUseBiometrics();
  });
}

export function checkBiometricType() {
  return new Promise((resolve) => {
    const callback = (event) => {
      document.removeEventListener(EVENT_TYPE_BIOMETRIC_TYPE, callback);
      resolve(event.detail);
    };
    document.addEventListener(EVENT_TYPE_BIOMETRIC_TYPE, callback);
    window.checkBiometricType();
  });
}

export function getLocationPermission() {
  return new Promise((resolve) => {
    const callback = (event) => {
      document.removeEventListener(EVENT_TYPE_LOCATION_PERMISSIONS, callback);
      resolve(event.detail.permission);
    };

    document.addEventListener(EVENT_TYPE_LOCATION_PERMISSIONS, callback);
    window.getLocationPermission();
  });
}

export function getCurrentPosition() {
  return new Promise((resolve) => {
    const callback = (event) => {
      document.removeEventListener(EVENT_TYPE_LOCATION_DATA, callback);
      resolve(event.detail);
    };

    document.addEventListener(EVENT_TYPE_LOCATION_DATA, callback);
    window.getCurrentPosition();
  });
}

export function getNotificationPermission() {
  return new Promise((resolve) => {
    const callback = (event) => {
      document.removeEventListener(EVENT_TYPE_NOTIFICATIONS_PERMISSIONS, callback);
      resolve(event.detail.permission);
    };

    document.addEventListener(EVENT_TYPE_NOTIFICATIONS_PERMISSIONS, callback);
    window.getNotificationPermission();
  });
}

export function getFCMToken() {
  return new Promise((resolve) => {
    const callback = (event) => {
      document.removeEventListener(EVENT_TYPE_NOTIFICATIONS_TOKEN, callback);
      resolve(event.detail.token);
    };

    document.addEventListener(EVENT_TYPE_NOTIFICATIONS_TOKEN, callback);
    window.getFCMToken();
  });
}

export function getCameraPermission() {
  return new Promise((resolve) => {
    const callback = (event) => {
      document.removeEventListener(EVENT_TYPE_CAMERA_PERMISSIONS, callback);
      resolve(event.detail.permission);
    };

    document.addEventListener(EVENT_TYPE_CAMERA_PERMISSIONS, callback);
    window.getCameraPermission();
  });
}

export function getLibraryPermission() {
  return new Promise((resolve) => {
    const callback = (event) => {
      document.removeEventListener(EVENT_TYPE_LIBRARY_PERMISSIONS, callback);
      resolve(event.detail.permission);
    };

    document.addEventListener(EVENT_TYPE_LIBRARY_PERMISSIONS, callback);
    window.getPhotoLibraryPermission();
  });
}

export function openFinsus() {
  window.openFinsus();
}
