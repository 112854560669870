import { useSelector } from 'react-redux';

const useLiterals = (name) => {
  const literals = useSelector((state) => ({
    ...state.i18n.literals[name],
    common: name !== 'common' ? state.i18n.literals.common : null,
  }));

  if (name === 'common') {
    delete literals.common;
  }

  return literals;
};

export default useLiterals;
