import PropTypes from 'prop-types';
import useLiterals from 'utils/hooks/useLiterals';
import Spinner from 'react-bootstrap/Spinner';
import './styles.scss';

const Select = (props) => {
  const {
    label,
    value,
    options,
    onChange,
    name,
    required,
    className,
    autoComplete,
    placeholder,
    error,
    disabled,
    canBeEmpty,
    loading,
  } = props;

  const literals = useLiterals('select');

  const handleChange = (e) => {
    onChange(e.target.value);
  };

  return (
    <div className={`ui-input ui-select ${className} ${loading ? 'loading' : ''}`}>
      {(label && false) && (
        <label htmlFor={name} className='form-label'>
          {label}
          {required && '*'}
        </label>
      )}
      <select
        required={required}
        value={value}
        onChange={handleChange}
        name={name}
        className={`form-control ${error ? 'is-invalid' : ''}`}
        placeholder={placeholder}
        autoComplete={autoComplete}
        disabled={disabled}
      >
        {(canBeEmpty || !value) && <option value=''>{`${placeholder || label || literals.selectOneOption}${required ? '*' : ''}`}</option>}
        {options.map((option) => (
          <option key={option.value} value={option.value}>{option.label}</option>
        ))}
      </select>
      <label className='chevron'>
        {loading && <Spinner animation='border' role='status' variant='primary' />}
      </label>
      {error && (
        <div className='invalid-feedback'>
          {error}
        </div>
      )}
    </div>
  );
};

Select.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string,
  options: PropTypes.array,
  label: PropTypes.string,
  required: PropTypes.bool,
  className: PropTypes.string,
  autoComplete: PropTypes.string,
  placeholder: PropTypes.string,
  error: PropTypes.string,
  disabled: PropTypes.bool,
  canBeEmpty: PropTypes.bool,
  loading: PropTypes.bool,
};

Select.defaultProps = {
  value: '',
  name: '',
  label: '',
  required: false,
  options: [],
  className: '',
  autoComplete: '',
  placeholder: '',
  error: '',
  disabled: false,
  canBeEmpty: true,
  loading: false,
};

export default Select;
