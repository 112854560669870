import { useDispatch, useSelector } from 'react-redux';
import Card from 'components/UI/Card';
import { setStore } from 'modules/stores/actions';
import './styles.scss';
import useNearestStores from 'utils/hooks/useNearestsStores';

const MAX_STORES = 20;

const StoresList = () => {
  const {
    coords,
  } = useSelector((state) => state.stores);

  const dispatch = useDispatch();

  const nearestsStores = useNearestStores(coords || {}, MAX_STORES);
  const handleSelectStore = (store) => {
    dispatch(setStore(store));
  };

  return (
    <div className='mt-3'>
      {(nearestsStores || []).map((store) => (
        <Card
          key={store.id}
          title={store?.name}
          description={store?.address}
          className='mt-2'
          showAccessIcon
          onClick={() => {
            window.scrollTo(0, 0);
            handleSelectStore(store);
          }}
        />
      ))}
    </div>
  );
};
export default StoresList;
