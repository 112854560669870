/* eslint-disable no-unused-vars */
import ApiFetch from 'utils/apiFetch';

const ApiFetchInstance = ApiFetch(window.CMS_API_URL);

export const URLS = {
  globalConfiguration: 'cms/global_configurations',
};

export default {
  getGlobalConfiguration() {
    return ApiFetchInstance.get(URLS.globalConfiguration);
  },
};
