/* eslint-disable eqeqeq */
import { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import carsImg from 'assets/images/cars.jpg';
import Box from 'components/UI/Box/Box';
import Button from 'components/UI/Button';
import Input from 'components/UI/Input';
import InputAmount from 'components/UI/InputAmount';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { ReactComponent as LessIcon } from 'assets/icons/less.svg';
import { ROUTE_PATH } from 'routes';
import { ZOHO_SCRIPT_TYPES } from 'constants/zohoScripts';
import { getVehicleSimulate } from 'modules/quotes/carsQuoter/actions';
import { loadingActions } from 'modules/loading';
import { formatCurrency } from 'utils/formatCurrency';
import Translate from 'components/Translate';

const STEP = 1000;
const TYPE_PRODUCT_1_MIN_AMOUNT = 20000;
const TYPE_PRODUCT_2_MIN_AMOUNT = 25000;

const CarsResume = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    literals,
    form,
    quoterResponse,
  } = props;
  const { cars: literalsCars } = literals;
  const [loanAmount, setLoanAmount] = useState();
  const [monthlyTerm, setMonthlyTerm] = useState();
  const [allMonthlyTerms, setAllMonthlyTerms] = useState([]);
  const [allBiweeklyPayments, setAllBiweeklyPayments] = useState([]);
  const [biweeklypayment, setBiweeklypayment] = useState();
  const [timer, setTimer] = useState(null);
  const [isInitialized, setIsInitialized] = useState(false);
  const isPersonalizedProduct = useMemo(() => form.productObject?.TipoProducto === '2', [form.productObject]);
  const actualMonthlyTerm = useMemo(() => allMonthlyTerms.findIndex((x) => x == monthlyTerm), [allMonthlyTerms, monthlyTerm]);
  const minAmount = useMemo(() => (form.productObject?.TipoProducto === '2' ? TYPE_PRODUCT_2_MIN_AMOUNT : TYPE_PRODUCT_1_MIN_AMOUNT), [form.productObject]);
  const maxAmount = quoterResponse?.vehicleValuation || 0;

  const intialize = (simulation) => {
    if (isPersonalizedProduct) {
      if (simulation?.obj_data?.CorridaFinancieraPersonalizado?.PlazosMontos) {
        setAllMonthlyTerms(Object.keys(simulation?.obj_data?.CorridaFinancieraPersonalizado?.PlazosMontos));
        setBiweeklypayment(simulation?.obj_data?.CorridaFinancieraPersonalizado?.PlazosMontos[monthlyTerm || form.productObject?.PlazoMaximo]);
        setAllBiweeklyPayments(simulation?.obj_data?.CorridaFinancieraPersonalizado?.PlazosMontos);
      }
    } else if (simulation?.obj_data?.CorridaFinancieraTradicional) {
      setAllMonthlyTerms(simulation?.obj_data?.CorridaFinancieraTradicional);
      setBiweeklypayment(simulation?.obj_data?.CorridaFinancieraTradicional[0]?.Pago);
      setAllBiweeklyPayments(simulation?.obj_data?.CorridaFinancieraTradicional);
    }
  };

  useEffect(() => {
    if (isInitialized && loanAmount >= minAmount && loanAmount <= maxAmount) {
      if (timer) clearTimeout(timer);
      const newTimer = setTimeout(() => {
        dispatch(loadingActions.show());
        getVehicleSimulate(form, loanAmount, form.productObject?.PlazoMaximo).then((response) => {
          intialize(response?.simulation);
          dispatch(loadingActions.hide());
        });
      }, 1500);
      setTimer(newTimer);
      return () => clearTimeout(newTimer);
    }
    return () => { };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loanAmount]);

  useEffect(() => {
    setMonthlyTerm(form?.productObject?.PlazoMaximo);
    setLoanAmount(quoterResponse?.vehicleValuation);
    intialize(quoterResponse?.simulation);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quoterResponse, isPersonalizedProduct, form]);

  const handleChangeLoanAmount = (value) => {
    const newAmount = Number(value) || null;
    setLoanAmount(newAmount);
    setIsInitialized(true);
  };

  const handleChangeMonthlyTerm = (value) => {
    if (value > 0) {
      if (isPersonalizedProduct) {
        setBiweeklypayment(allBiweeklyPayments[value]);
      }
      setMonthlyTerm(value);
      setIsInitialized(true);
    }
  };

  return (
    <Box className='watchsQuoter-box mb-3'>
      <img className='mb-4 no-left-padding' src={carsImg} alt='cars' />
      <h2 className='mb-3'>{literalsCars.title}</h2>
      <div className='d-flex justify-content-between text-end'>
        <p className='h4'>{literalsCars.year}</p>
        <p className='text-gray font-regular'>{form.year}</p>
      </div>
      <div className='d-flex justify-content-between text-end'>
        <p className='h4'>{literalsCars.brand}</p>
        <p className='text-gray font-regular'>{form.brandName}</p>
      </div>
      <div className='d-flex justify-content-between text-end'>
        <p className='h4'>{literalsCars.model}</p>
        <p className='text-gray font-regular'>{form.modelName}</p>
      </div>
      <div className='d-flex justify-content-between text-end'>
        <p className='h4'>{literalsCars.version}</p>
        <p className='text-gray font-regular'>{form.versionName}</p>
      </div>
      <div className='d-flex justify-content-between text-end'>
        <p className='h4'>{literalsCars.product}</p>
        <p className='text-gray font-regular'>{form?.productObject?.DescripcionProducto}</p>
      </div>
      <p className='h4'>{literalsCars.biweeklypayment}</p>
      <p className='h1 text-primary mb-4'>{biweeklypayment ? formatCurrency(biweeklypayment) : '-'}</p>
      <p className='h4'>{literals.loanAmount}</p>
      <div className='d-flex gap-2 mb-2'>
        <div
          className={`payment-input ${loanAmount <= minAmount && 'disabled'}`}
          onClick={() => handleChangeLoanAmount(loanAmount - STEP > minAmount ? loanAmount - STEP : minAmount)}
        >
          <LessIcon />
        </div>
        <InputAmount
          className='w-100'
          value={loanAmount}
          min={minAmount}
          max={maxAmount}
          onChange={handleChangeLoanAmount}
          error={loanAmount < minAmount}
        />
        <div
          className={`payment-input ${loanAmount >= maxAmount && 'disabled'}`}
          onClick={() => handleChangeLoanAmount(loanAmount + STEP < maxAmount ? loanAmount + STEP : maxAmount)}
        >
          <PlusIcon />
        </div>
      </div>
      {loanAmount < minAmount && (
        <Translate className='text-danger mb-2' literal={literalsCars.minAmount} vars={{ amount: formatCurrency(minAmount) }} />
      )}
      {loanAmount > maxAmount && (
        <Translate className='text-danger mb-2' literal={literalsCars.maxAmount} vars={{ amount: formatCurrency(maxAmount) }} />
      )}
      <p className='h4'>{literalsCars.monthlyTerm}</p>
      <div className='d-flex gap-2 mb-2'>
        <div
          className={`payment-input ${(!isPersonalizedProduct || actualMonthlyTerm - 1 < 0) && 'disabled'}`}
          onClick={() => handleChangeMonthlyTerm(allMonthlyTerms[allMonthlyTerms.findIndex((x) => x == monthlyTerm) - 1])}
        >
          <LessIcon />
        </div>
        <Input
          className='w-100 not-disabled'
          type='number'
          onChange={() => { }}
          value={monthlyTerm}
          disabled
        />
        <div
          className={`payment-input ${(!isPersonalizedProduct || actualMonthlyTerm + 1 > allMonthlyTerms.length - 1) && 'disabled'}`}
          onClick={() => handleChangeMonthlyTerm(allMonthlyTerms[allMonthlyTerms.findIndex((x) => x == monthlyTerm) + 1])}
        >
          <PlusIcon />
        </div>
      </div>
      <Button
        type='primary'
        text={literals.createAppointment}
        disabled={loanAmount < minAmount || loanAmount > maxAmount}
        onClick={() => navigate(`${ROUTE_PATH.APPOINTMENT}?type=${ZOHO_SCRIPT_TYPES.CARS}`)}
      />
    </Box>
  );
};

CarsResume.propTypes = {
  literals: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  quoterResponse: PropTypes.object.isRequired,
};

export default CarsResume;
