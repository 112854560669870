import PropTypes from 'prop-types';
import useLiterals from 'utils/hooks/useLiterals';
import documentIcon from 'assets/icons/document.svg';
import Box from 'components/UI/Box/Box';
import Input from 'components/UI/Input';
import StepsProgress from 'components/UI/StepsProgress';
import Button from 'components/UI/Button';

const ShippingAddress = ({
  step, steps, onChange, changeStep,
}) => {
  const { shippingAddressStep: literals } = useLiterals('paymentGateway');

  const renderForm = () => {
    return literals?.form?.map((formItem) => {
      return (
        <Input
          key={formItem.value}
          placeholder={formItem.label}
          icon={documentIcon}
          required={formItem.required}
          onChange={(v) => onChange(formItem.value, v)}
          className='mb-3'
        />
      );
    });
  };

  return (
    <Box>
      <h3 className='my-0 font-regular'>{literals.title}</h3>
      <h1 className='text-primary my-2'>{literals.address}</h1>
      <StepsProgress steps={steps} current={step} className='w-100 mb-3' />
      {renderForm()}
      <Button
        className='w-100'
        text={literals.continue}
        onClick={() => changeStep(step + 1)}
      />
    </Box>
  );
};

ShippingAddress.propTypes = {
  step: PropTypes.number.isRequired,
  steps: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  changeStep: PropTypes.func.isRequired,
};

export default ShippingAddress;
