import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import useLiterals from 'utils/hooks/useLiterals';
import { useNavigate, useParams } from 'react-router-dom';
import Layout from 'components/Layout';
import { GA_EVENTS } from 'constants/gaEvents';
import { sendGaEvent } from 'utils/sendGaEvent';
import { QUOTER_TYPE } from 'constants/services';
import Quotes from 'components/Services/Quotes';
import { BOTTOM_MENU_ACTIONS } from 'components/Layout/components/BottomMenu';
import { ROUTE_PATH } from 'routes';
import { getVehicleSimulate } from 'modules/quotes/carsQuoter/actions';
import { getJewelsSimulate } from 'modules/quotes/jewelryQuoter/actions';
import { getDiamondsSimulate } from 'modules/quotes/diamondsQuoter/actions';
import { getWatchesSimulate } from 'modules/quotes/watchesQuoter/actions';
import ErrorModal from 'components/FeedbackModals/ErrorModal';
import JewelryQuoter from './components/JewelryQuoter';
import CarsQuoter from './components/CarsQuoter';
import WatchesQuoter from './components/WatchesQuoter';
import DiamondsQuoter from './components/DiamondsQuoter';
import CarsResume from './components/CarsResume';
import CarsAppointment from './components/CarsAppointment';
import ResumeQuoter from './components/ResumeQuoter';
import './styles.scss';

const QUOTER_STEPS = {
  START: 'start',
  RESUME: 'resume',
  APPOINTMENT: 'appointment',
};

const Quoter = () => {
  const { type, step } = useParams();
  const navigate = useNavigate();
  const literals = useLiterals('quoter');
  const specificLiterals = literals[type];
  const [form, setForm] = useState({});
  const [quoterResponse, setQuoterResponse] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const {
    automaticQuoter,
  } = useSelector((state) => state.llms);

  useEffect(() => {
    if (step !== QUOTER_STEPS.START) {
      navigate(ROUTE_PATH.setQuoter(type, QUOTER_STEPS.START));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (automaticQuoter && automaticQuoter?.visionChatbot?.type === 'Relojes') {
      if (automaticQuoter?.brand) {
        setForm({ ...form, brand: automaticQuoter.brand });
      }
    }
    if (automaticQuoter && automaticQuoter?.visionChatbot?.type === 'Autos') {
      const newForm = { ...form };
      if (automaticQuoter?.visionChatbot?.year) {
        newForm.year = automaticQuoter.visionChatbot.year;
      }
      if (automaticQuoter?.brand) {
        newForm.brand = automaticQuoter.brand?.Clave;
      }
      if (automaticQuoter?.model) {
        newForm.model = automaticQuoter.model?.Clave;
      }
      if (automaticQuoter?.version) {
        newForm.version = automaticQuoter.version?.Clave;
      }
      setForm(newForm);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [automaticQuoter]);

  const handleOnChangeInput = (key, value) => {
    setForm({ ...form, [key]: value });
  };

  const handleResetQuote = () => {
    setForm({});
    navigate(ROUTE_PATH.setQuoter(type, QUOTER_STEPS.START));
  };

  const handleSubmit = async (e) => {
    let response = null;
    e.preventDefault();
    setLoading(true);
    switch (type) {
      case QUOTER_TYPE.JEWELRY:
        sendGaEvent(GA_EVENTS.JEWELRY_QUOTE);
        response = await getJewelsSimulate(form);
        break;
      case QUOTER_TYPE.DIAMONDS:
        sendGaEvent(GA_EVENTS.DIAMONDS_QUOTE);
        response = await getDiamondsSimulate(form);
        break;
      case QUOTER_TYPE.WATCHES:
        sendGaEvent(GA_EVENTS.WATCHES_QUOTE);
        response = await getWatchesSimulate(form);
        break;
      case QUOTER_TYPE.CARS:
        sendGaEvent(GA_EVENTS.CARS_QUOTE);
        response = await getVehicleSimulate(form);
        break;
      default:
        break;
    }
    setLoading(false);
    if (response) {
      setQuoterResponse(response);
      navigate(ROUTE_PATH.setQuoter(type, QUOTER_STEPS.RESUME));
    } else {
      setError(true);
    }
  };

  const renderQuoter = () => {
    const props = {
      literals,
      form,
      loading,
      onChange: handleOnChangeInput,
      onSubmit: handleSubmit,
    };

    switch (type) {
      case QUOTER_TYPE.JEWELRY:
        return <JewelryQuoter {...props} />;
      case QUOTER_TYPE.CARS:
        return <CarsQuoter {...props} />;
      case QUOTER_TYPE.WATCHES:
        return <WatchesQuoter {...props} />;
      case QUOTER_TYPE.DIAMONDS:
        return <DiamondsQuoter {...props} />;
      default:
        return null;
    }
  };

  const renderResume = () => {
    const props = {
      literals,
      form,
      onChange: handleOnChangeInput,
      onSubmit: handleSubmit,
      onReset: () => { },
    };

    switch (type) {
      case QUOTER_TYPE.CARS:
        return <CarsResume {...props} quoterResponse={quoterResponse} />;
      default:
        return <ResumeQuoter {...props} type={type} onReset={handleResetQuote} quoterResponse={quoterResponse} />;
    }
  };

  const renderAppointment = () => {
    const props = {
      literals,
      form,
      onChange: handleOnChangeInput,
      onSubmit: handleSubmit,
    };

    if (type === QUOTER_TYPE.CARS) {
      return <CarsAppointment {...props} />;
    }
    return null;
  };

  const renderStep = () => {
    switch (step) {
      case QUOTER_STEPS.RESUME:
        return renderResume();
      case QUOTER_STEPS.APPOINTMENT:
        return renderAppointment();
      default:
        return renderQuoter();
    }
  };

  const renderAllQuotes = () => {
    return (
      <div className='mt-4'>
        <h3 className='mt-0 mb-3'>{literals.title}</h3>
        <Quotes hide={type} />
      </div>
    );
  };

  return (
    <Layout
      title={specificLiterals.title}
      onBack
      bottomMenu={{
        type: BOTTOM_MENU_ACTIONS.AUTOMATIC_QUOTER,
      }}
    >
      <div className='quoter'>
        {renderStep()}
        {renderAllQuotes()}
      </div>
      {error && (
        <ErrorModal
          title={literals.errorTitle}
          description={literals.errorDescription}
          onClose={() => setError(false)}
        />
      )}
    </Layout>
  );
};

export default Quoter;
