/* eslint-disable no-unused-vars */
import ApiFetch from 'utils/apiFetch';

const ApiFetchInstance = ApiFetch(window.LLMS_API_URL);

export const URLS = {
  createConversation: (chatbotId) => `/chatbots/${chatbotId}/conversations`,
  uploadPhoto: (chatbotId, conversationId) => `/chatbots/${chatbotId}/conversations/${conversationId}/documents`,
  sendPrompt: (chatbotId) => `/chatbots/${chatbotId}/prompt`,
};

export default {
  createConversation: (chatbotId) => ApiFetchInstance.post(URLS.createConversation(chatbotId), {}),
  uploadPhoto: (chatbotId, conversationId, formData) => ApiFetchInstance.post(URLS.uploadPhoto(chatbotId, conversationId), formData),
  sendPrompt: (chatbotId, conversationId, prompt) => ApiFetchInstance.post(URLS.sendPrompt(chatbotId), { prompt, conversation_id: conversationId }),
};
