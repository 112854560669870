import PropTypes from 'prop-types';
import Box from 'components/UI/Box/Box';
import Button from 'components/UI/Button';
import Input from 'components/UI/Input';
import Select from 'components/UI/Select';

const CarsAppointment = (props) => {
  const {
    literals,
    form,
    onSubmit,
    onChange,
  } = props;
  const { contact } = form;
  const disabled = !contact?.name || !contact?.lastName || !contact?.email;

  const handleOnChange = (key, value) => {
    onChange('contact', { ...contact, [key]: value });
  };

  const handleReset = () => {
    onChange('contact', {});
  };

  return (
    <Box className='watchsQuoter-box mb-3'>
      <h1 className='mb-3'>{literals.contactForm}</h1>
      <Input
        className='mb-4'
        placeholder={literals.common.name}
        value={contact?.name || ''}
        onChange={(v) => handleOnChange('name', v)}
        required
      />
      <Input
        className='mb-4'
        placeholder={literals.common.lastName}
        value={contact?.lastName || ''}
        onChange={(v) => handleOnChange('lastName', v)}
        required
      />
      <Input
        type='landline'
        className='mb-4'
        placeholder={literals.common.landline}
        value={contact?.landline || ''}
        onChange={(v) => handleOnChange('landline', v)}
      />
      <Input
        type='phone'
        className='mb-4'
        placeholder={literals.common.phone}
        value={contact?.phone || ''}
        onChange={(v) => handleOnChange('phone', v)}
      />
      <Input
        type='email'
        className='mb-4'
        placeholder={literals.common.email}
        value={contact?.email || ''}
        onChange={(v) => handleOnChange('email', v)}
        required
      />
      <Select
        className='mb-4'
        value={contact?.state || ''}
        placeholder={literals.common.state}
        onChange={(v) => handleOnChange('state', v)}
        options={[
          { value: 'A', label: 'A' },
          { value: 'B', label: 'B' },
          { value: 'C', label: 'C' },
        ]}
      />
      <form onSubmit={onSubmit}>
        <Button className='mb-3' onClick={handleReset} type='secondary' text={literals.common.reset} />
        <Button isSubmitBtn text={literals.common.send} disabled={disabled} />
      </form>
    </Box>
  );
};

CarsAppointment.propTypes = {
  literals: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default CarsAppointment;
