import Layout from 'components/Layout';
import useLiterals from 'utils/hooks/useLiterals';
import { ROUTE_PATH } from 'routes';
import Button from 'components/UI/Button';
import Modal from 'components/Modal';

const SessionClosed = () => {
  const literals = useLiterals('sessionClosed');

  return (
    <Layout>
      <Modal showCloseButton={false} closeOnBackgroundClick={false}>
        <div className='text-center'>
          <h1 className='mb-4'>{literals.title}</h1>
          <p className='mb-5'>{literals.description}</p>
        </div>
        <Button to={ROUTE_PATH.LOGIN} text={literals.goToLogin} />
      </Modal>
    </Layout>
  );
};

export default SessionClosed;
