import PropTypes from 'prop-types';

import './styles.scss';

const StepsProgress = ({ className, steps, current }) => {
  const currentIndex = steps.findIndex((step) => step.value === current);

  return (
    <div className={`steps-progress ${className}`}>
      {steps.map(({ label, value }, index) => (
        <div
          key={`step-${value}`}
          className={`${value === current ? 'active' : ''} ${currentIndex > index ? 'done' : ''} ${currentIndex === index - 1 ? 'next' : ''}`}
        >
          <span>{index + 1}</span>
          <p>{label}</p>
        </div>
      ))}
    </div>
  );
};

StepsProgress.propTypes = {
  className: PropTypes.string,
  steps: PropTypes.array.isRequired,
  current: PropTypes.number.isRequired,
};

StepsProgress.defaultProps = {
  className: '',
};

export default StepsProgress;
