/* eslint-disable no-unused-vars */
import ApiFetch from 'utils/apiFetch';

const ApiFetchInstance = ApiFetch(window.CMS_API_URL);

export const URLS = {
  page: 'cms/pages/url',
  popups: 'cms/popups',
  documents: 'cms/documents/public',
};

export default {
  getPage(path) {
    return ApiFetchInstance.get(`${URLS.page}?url=${path}`);
  },
  getPopups() {
    return ApiFetchInstance.get(URLS.popups);
  },
  getDocument(hash) {
    return ApiFetchInstance.get(`${URLS.documents}/${hash}`);
  },
};
