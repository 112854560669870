import { useEffect, useRef, useState } from 'react';
import { getCmsPopups } from 'modules/cms/actions';
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'components/Modal';
import useCmsPreview from 'hooks/useCmsPreview';
import { CMS_MESSAGES } from 'constants/cms';
import useLocalStorage from 'utils/hooks/useLocalStorage';
import { STORAGE_POPUPS } from 'constants/local';

const CmsPopups = () => {
  const dispatch = useDispatch();
  const { popups: { data: popups, error } } = useSelector((state) => state.cms);
  const [popup, setPopup] = useState(null);
  const { value: previewMessage, isPreview } = useCmsPreview(CMS_MESSAGES.UPDATE_POPUP);
  const [saved, setSaved] = useLocalStorage(STORAGE_POPUPS);
  const timeoutRef = useRef(null);

  const checkPopups = () => {
    if (!isPreview && popups?.length) {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      const currentPath = document.location.pathname.replace(/\//g, '');
      const candidates = popups.filter((p) => {
        if (saved[p.id] && p.repeat !== true) {
          return false;
        }
        return !p?.rules?.paths?.length || p.rules.paths.includes(currentPath);
      });
      if (candidates?.length) {
        const newPopup = candidates[0];
        if (newPopup?.rules?.delay) {
          timeoutRef.current = setTimeout(() => {
            setPopup(newPopup);
          }, newPopup.rules.delay * 1000);
        } else {
          setPopup(newPopup);
        }
      }
    }
  };

  useEffect(() => {
    dispatch(getCmsPopups());
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    checkPopups();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [popups, document.location.pathname, saved]);

  useEffect(() => {
    if (previewMessage) {
      setPopup(previewMessage);
    }
  }, [previewMessage]);

  const handleClose = () => {
    setPopup(null);
    if (!isPreview) {
      setSaved({ ...saved, [popup.id]: new Date().getTime() });
    }
  };

  if (error || !popup?.content) {
    return null;
  }

  return (
    <Modal
      onCloseModal={handleClose}
      position={popup?.rules?.position || 'center'}
    >
      <div className='block-rich-text' dangerouslySetInnerHTML={{ __html: popup.content }} />
    </Modal>
  );
};

export default CmsPopups;
