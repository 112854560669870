export const GA_EVENTS = {
  STORES_LIST: {
    category: 'Stores',
    action: 'List',
  },
  APPOINTMENT: {
    category: 'Appointment',
    action: 'Create',
  },
  CARS_QUOTE: {
    category: 'Quote',
    action: 'Cars',
  },
  JEWELRY_QUOTE: {
    category: 'Quote',
    action: 'Jewelry',
  },
  DIAMONDS_QUOTE: {
    category: 'Quote',
    action: 'Diamonds',
  },
  WATCHES_QUOTE: {
    category: 'Quote',
    action: 'Watches',
  },
};
