/* eslint-disable no-param-reassign */
import { configureStore, combineReducers } from '@reduxjs/toolkit';
import session, { SESSION_LOGOUT } from 'modules/session';
import i18n from 'modules/i18n';
import loading from 'modules/loading';
import app from 'modules/app';
import stores from 'modules/stores';
import contracts from 'modules/contracts';
import sections from 'modules/sections';
import configuration from 'modules/configuration';
import cms from 'modules/cms';
import llms from 'modules/llms';

import initialState from './initialState';

const combinedReducer = combineReducers({
  session,
  i18n,
  loading,
  app,
  stores,
  contracts,
  sections,
  configuration,
  cms,
  llms,
});

const rootReducer = (state, action) => {
  if (action.type === SESSION_LOGOUT) {
    state = {
      ...initialState,
      session: {
        ...initialState.session,
        checked: true,
      },
      configuration: state.configuration,
      stores: state.stores,
    };
  }
  return combinedReducer(state, action);
};

export const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV !== 'production',
});
