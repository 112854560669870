import { useMemo, useState } from 'react';
import Layout from 'components/Layout';
import useLiterals from 'utils/hooks/useLiterals';
import ShippingAddress from './components/ShippingAddress';
import PaymentMethod from './components/PaymentMethod';
import './styles.scss';

const STEPS = {
  SHIPPING_ADDRESS: 0,
  PAYMENT_METHOD: 1,
  CONFIRMATION: 2,
};

const PaymentGateway = () => {
  const literals = useLiterals('paymentGateway');
  const [step, setStep] = useState(STEPS.SHIPPING_ADDRESS);
  const [shippingFormData, setShippingFormData] = useState({});
  const [paymentMethod, setPaymentMethod] = useState('');
  const steps = useMemo(() => [
    { label: literals.shippingAddressStep.address, value: STEPS.SHIPPING_ADDRESS },
    { label: literals.paymentMethodStep.paymentMethod, value: STEPS.PAYMENT_METHOD },
    { label: literals.confirmationStep.title, value: STEPS.CONFIRMATION },
  ], [literals]);

  const handleChangeFormData = (key, value) => {
    setShippingFormData({ ...shippingFormData, [key]: value });
  };

  const handleStepChange = (newStep) => {
    if (newStep >= 0 && newStep < steps.length) {
      setStep(newStep);
    }
  };

  const renderSteps = () => {
    switch (step) {
      case STEPS.SHIPPING_ADDRESS:
        return <ShippingAddress step={step} steps={steps} onChange={handleChangeFormData} changeStep={handleStepChange} />;
      case STEPS.PAYMENT_METHOD:
        return <PaymentMethod step={step} steps={steps} onChange={setPaymentMethod} changeStep={handleStepChange} paymentMethod={paymentMethod} />;
      default:
        return null;
    }
  };

  return (
    <Layout
      title={literals.title}
      headerMenu
      onBack
      bottomMenu
    >
      {renderSteps()}
    </Layout>
  );
};

export default PaymentGateway;
