import { useEffect } from 'react';
import Layout from 'components/Layout';
import { openFinsus } from 'utils/events';
import './styles.scss';

const Finsus = () => {
  useEffect(() => {
    openFinsus();
  }, []);

  return (
    <Layout
      bottomMenu
      onBack
    >
      <h1>FINSUS</h1>
    </Layout>
  );
};

export default Finsus;
