import { Component } from 'react';
import { connect } from 'react-redux';
import Lottie from 'react-lottie';
import PropTypes from 'prop-types';
import * as animationData from 'assets/animations/loader.json';

import './styles.scss';

class Loading extends Component {
  render() {
    const { isLoading, force } = this.props;
    return (
      <div className={`loader-wrapper ${isLoading <= 0 && !force ? 'hidden' : ''}`}>
        <div className='blur-background' />
        <Lottie
          options={{
            loop: true,
            autoplay: true,
            animationData,
            rendererSettings: {
              preserveAspectRatio: 'xMidYMid slice',
            },
          }}
          height={300}
          width={300}
        />
      </div>
    );
  }
}

Loading.displayName = 'Loading';
Loading.propTypes = {
  isLoading: PropTypes.number.isRequired,
  force: PropTypes.bool,
};
Loading.defaultProps = {
  force: false,
};

function mapStateToProps(state) {
  return {
    isLoading: state.loading.count,
  };
}

export default connect(mapStateToProps)(Loading);
