import Layout from 'components/Layout';

const Auctions = () => {
  return (
    <Layout
      headerMenu={false}
      onBack
      bottomMenu
    >
      <iframe
        className='embed-page'
        title='Subastas Montepio Luz Savinon'
        src='https://subastas.montepio.org.mx/'
      />
    </Layout>
  );
};

export default Auctions;
