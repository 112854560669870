import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Lottie from 'react-lottie';
import * as animationData from 'assets/animations/done.json';
import Modal from 'components/Modal';
import './styles.scss';

function SuccessModal({
  literals, description, onClose, title, children,
}) {
  return (
    <Modal
      onCloseModal={onClose}
      className='feedback-modal'
    >
      <div className='text-center'>
        <Lottie
          options={{
            loop: false,
            autoplay: true,
            animationData,
            rendererSettings: {
              preserveAspectRatio: 'xMidYMid slice',
            },
          }}
          height={90}
          width={90}
        />
        <h3 className='mt-1'>
          {title || literals.successTitle}
        </h3>
        {description && (
          <h3 className='text-gray font-regular mt-4'>
            {description}
          </h3>
        )}
        {children}
      </div>
    </Modal>
  );
}

SuccessModal.propTypes = {
  literals: PropTypes.object.isRequired,
  description: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string,
  children: PropTypes.node,
};
SuccessModal.defaultProps = {
  title: '',
  description: '',
  children: null,
};

/* *************************************** */
/* ********       CONTAINER       ******** */
/* *************************************** */

function mapStateToProps(state) {
  return {
    literals: {
      ...state.i18n.literals.feedbackModal,
      ...state.i18n.literals.common,
    },
  };
}

export default connect(mapStateToProps)(SuccessModal);
