import documentIcon from 'assets/icons/document.svg';
import Card from 'components/UI/Card';

const Contracts = () => {
  const renderContracts = () => {
    const documents = [
      { title: 'Contrato 1', description: 'Breve descripción del contrato' },
      { title: 'Contrato 2', description: 'Breve descripción del contrato' },
      { title: 'Contrato 3', description: 'Breve descripción del contrato' },
    ];
    return documents.map((document) => {
      return (
        <Card
          key={document.title}
          title={document.title}
          titleClassName='font-size-sm'
          description={document.description}
          descriptionClassName='font-size-xs'
          image={documentIcon}
          showAccessIcon
        />
      );
    });
  };

  return (
    <div className='contracts'>
      {renderContracts()}
    </div>
  );
};

export default Contracts;
