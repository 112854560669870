const symbols = {
  USD: '$',
  EUR: '€',
};

export const formatCurrency = (number, currency = 'USD') => {
  const symbol = symbols[currency] || '';
  let auxNumber = Number(number);
  try {
    auxNumber = new Intl.NumberFormat('en-GB', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(auxNumber);
  } catch (e) {
    auxNumber = Number(number).toFixed(2);
  }

  return `${symbol}${auxNumber}`;
};
