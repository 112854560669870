export const EFFECT_LIST_BOX_UP_IN_VIEW = {
  animate: 'visible',
  initial: { y: 50, opacity: 0 },
  variants: {
    visible: (i) => ({
      y: 0,
      opacity: 1,
      transition: {
        delay: i * 0.15,
        duration: 0.3,
      },
    }),
    hidden: { opacity: 0 },
  },
};

export const EFFECT_LIST_BOX_RIGHT_IN_VIEW = {
  animate: 'visible',
  initial: { x: 50, opacity: 0 },
  variants: {
    visible: (i) => ({
      x: 0,
      opacity: 1,
      transition: {
        delay: i * 0.15,
        duration: 0.3,
      },
    }),
    hidden: { opacity: 0 },
  },
};
