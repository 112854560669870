import PropTypes from 'prop-types';

const ProgressChart = ({ total, pending }) => {
  return (
    <div className='progress-chart-container'>
      <div className='progress-chart-container-value' style={{ width: `${((total - pending) / total) * 100}%` }} />
    </div>
  );
};

ProgressChart.displayName = 'ProgressChart';

ProgressChart.propTypes = {
  total: PropTypes.number.isRequired,
  pending: PropTypes.number.isRequired,
};

export default ProgressChart;
