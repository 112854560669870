export const ZOHO_SCRIPT_TYPES = {
  APPOINTMENT: 'APPOINTMENT',
  CARS: 'CARS',
  DIAMONDS: 'DIAMONDS',
  JEWELRY: 'JEWELRY',
  WATCHES: 'WATCHES',
  DRIVER: 'DRIVER',
};

window.ZOHO_SCRIPTS = {
  [ZOHO_SCRIPT_TYPES.APPOINTMENT]: 'https://crm.zoho.com/crm/WebFormServeServlet?rid=e0fbbf6f5095375b22701319c827a6b7d5868637c649cd9e9f71dbea9522dec465b33e4f9a8d5979cf797a69e9741a47gid3686b9e2458d478b33d7cbdc5f73a5ce299a41eac9b740ea6c1da97aaac04311&script=$sYG',
  [ZOHO_SCRIPT_TYPES.CARS]: 'https://crm.zoho.com/crm/WebFormServeServlet?rid=3606b47fdceb26a72c6a93b7751d12727b3000c1814e3bd439f6e264cf22b8561468d1de5b4d8ed97529ce8e25c550bdgidee8d03944d1f228835db5c9547bcd67f11b6904e1d10436963e6e0da270c5506&script=$sYG',
  [ZOHO_SCRIPT_TYPES.DIAMONDS]: 'https://crm.zoho.com/crm/WebFormServeServlet?rid=ca97281fb6bf1444fa6f4906170c01fea884b40dbfaca1daac80445603039630ea1f8675643ece80c24950159a76e330gid63bf6302da69208d06ac7a06d0394e71b47e6ab422f746df94216db4978d9e33&script=$sYG',
  [ZOHO_SCRIPT_TYPES.JEWELRY]: 'https://crm.zoho.com/crm/WebFormServeServlet?rid=e21d5083ba03635845ff2ab473989624b8a5b68cd41ac00ee0dbafea6f18f32199bd66fc56c7cff392af5bdab269f265gid5fad11a413e823a8245aa9a754d0152dc378054c5da044cd902efb69adda9ebc&script=$sYG',
  [ZOHO_SCRIPT_TYPES.WATCHES]: 'https://crm.zoho.com/crm/WebFormServeServlet?rid=0bdca01c33b6ba499686e332d4b09e8cdea086c9462d305221a1a745fac89eacfb06196075448c5272a7736ce73b85d2gid6a8af40177a37a51f67510c57f43dd9fc13a1e3b1ad647fd15260555e03b5db1&script=$sYG',
  [ZOHO_SCRIPT_TYPES.DRIVER]: 'https://crm.zoho.com/crm/WebFormServeServlet?rid=b6d3f63c91438aa20ec415db05cf2dc89a2ea2ceb1d7449b09a859d475708e95f43fb8e9626fa41a7b6ca06f56e55011gid9e0ea4a38e1c6507752728515a47aadcc480034f00eca51b8eec7bd4409490c0&script=$sYG',
};
