import { useEffect, useMemo } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { getContracts } from 'modules/contracts/actions';
import Carousel from 'components/UI/Carousel';
import { ROUTE_PATH } from 'routes';
import { getSections } from 'modules/sections/actions';
import Box from 'components/UI/Box/Box';
import Button from 'components/UI/Button';
import { ReactComponent as DocumentIcon } from 'assets/icons/document.svg';
import PaymentBox from 'components/PaymentBox';

const LIMIT_LENGTH = 4;

const ContractsAndSections = ({ literals }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const contracts = useSelector((state) => state.contracts);
  const sections = useSelector((state) => state.sections);
  const urlParams = new URLSearchParams(window.location.search);

  const items = useMemo(() => {
    const auxItems = [
      ...(contracts?.list || []),
      ...(sections?.list || []),
    ];

    auxItems.sort((a, b) => a.auxOrder - b.auxOrder);
    return auxItems;
  }, [contracts, sections]);

  useEffect(() => {
    if (!contracts.loaded) {
      dispatch(getContracts());
    }
    if (!sections.loaded) {
      dispatch(getSections());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSlideChange = (e) => {
    const contract = contracts?.list[e.activeIndex]?.Numero;
    if (contract) {
      navigate(`${ROUTE_PATH.HOME}?contract=${contract}`);
    }
  };

  const renderItems = () => {
    if (!items.length) return null;
    const slides = [];

    items.forEach((item) => {
      if (slides.length === LIMIT_LENGTH) {
        // eslint-disable-next-line no-useless-return
        return;
      }
      slides.push((
        <PaymentBox
          type={item.auxType}
          data={item}
          actionText={literals.details}
        />
      ));
    });
    let title = literals.yourContractsAndSections;
    if (!contracts?.list?.length) {
      title = literals.yourSections;
    } else if (!sections?.list?.length) {
      title = literals.yourContracts;
    }
    if (items.length > LIMIT_LENGTH) {
      slides.push((
        <Box className='deadlines-box deadline-show-more mb-3' padding='sm'>
          <Link to={ROUTE_PATH.PAYMENTS} className='text-decoration-none text-center d-block'>
            <div className='p-3'>
              <div className='d-flex align-items-center justify-content-start gap-2 mb-4'>
                <DocumentIcon />
                <p className='h3 text-default text-start mb-0'>{literals.numActiveContracts}</p>
              </div>
              <div className='px-4 py-2'>
                <div className='d-flex justify-content-between align-items-center mb-2'>
                  <p className='h3 text-default font-regular mb-0'>{literals.contracts}</p>
                  <p className='h1 text-default m-0'>{contracts?.list?.length}</p>
                </div>
                <div className='d-flex justify-content-between align-items-center mb-2'>
                  <p className='h3 text-default font-regular mb-0'>{literals.sections}</p>
                  <p className='h1 text-default m-0'>{sections?.list?.length}</p>
                </div>
              </div>
              <Button className='mt-4'>{literals.common.viewAll}</Button>
            </div>
          </Link>
        </Box>
      ));
    }
    return (
      <>
        <div className='d-flex justify-content-between align-items-center mb-3 carousel-title'>
          <h2 className='m-0'>
            {title}
          </h2>
          <Link to={ROUTE_PATH.PAYMENTS} className='font-bold'>
            {literals.showAll}
          </Link>
        </div>
        <Carousel
          slides={slides}
          showNext
          onSlideChange={onSlideChange}
          initialSlide={urlParams.get('contract') ? contracts.list.findIndex((c) => c.Numero === urlParams.get('contract')) : 0}
        />
      </>
    );
  };

  return (
    renderItems()
  );
};

ContractsAndSections.propTypes = {
  literals: PropTypes.object.isRequired,
};

export default ContractsAndSections;
