import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Lottie from 'react-lottie';
import useLiterals from 'utils/hooks/useLiterals';
import Layout from 'components/Layout';
import { getPackage } from 'modules/contracts/actions';
import { formatDate } from 'utils/formatDate';
import * as animationDataSuccess from 'assets/animations/done.json';
import * as animationDataFailure from 'assets/animations/info.json';
import Button from 'components/UI/Button';
import { ROUTE_PATH } from 'routes';
import { formatCurrency } from 'utils/formatCurrency';
import { useDispatch } from 'react-redux';
import { loadingActions } from 'modules/loading';

const MultiplePaymentResponse = () => {
  const literals = useLiterals('contractPay');
  const { packageId, status } = useParams();
  const [info, setInfo] = useState(null);
  const [error, setError] = useState(null);
  const isSuccess = status === 'success';
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(loadingActions.show());
    getPackage(packageId)
      .then((response) => {
        if (response?.length) {
          setInfo(response);
        } else {
          setError(true);
        }
      }).catch(() => {
        setError(true);
      }).finally(() => {
        dispatch(loadingActions.hide());
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formatInfo = useMemo(() => {
    const aux = {};
    if (info?.length) {
      aux.totalAmount = info.reduce((acc, curr) => acc + curr.amount, 0);
      aux.numContracts = info.length;
      aux.date = info[0].paymentDate;
      aux.providerType = info[0].providerType;
      aux.status = info[0].status;
    }
    return aux;
  }, [info]);

  const renderHeader = () => {
    if (isSuccess) {
      return (
        <div className='d-flex justify-content-center align-items-center flex-column'>
          <Lottie
            options={{
              loop: false,
              autoplay: true,
              animationData: animationDataSuccess.default,
              rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice',
              },
            }}
            height={90}
            width={90}
          />
          <h1>{literals.bancomer.successTitle}</h1>
        </div>
      );
    }
    return (
      <div className='d-flex justify-content-center align-items-center flex-column'>
        <Lottie
          options={{
            loop: false,
            autoplay: true,
            animationData: animationDataFailure.default,
            rendererSettings: {
              preserveAspectRatio: 'xMidYMid slice',
            },
          }}
          height={90}
          width={90}
        />
        <h1>{literals.bancomer.failureTitle}</h1>
      </div>
    );
  };

  const handleGoBack = () => {
    navigate(ROUTE_PATH.HOME);
  };

  return (
    <Layout
      onBack={handleGoBack}
      bottomMenu
    >
      {info && (
        <div className='payment-resume'>
          {renderHeader()}
          <h1 className='mb-4 text-primary'>{literals.paymetResume}</h1>
          <div className='d-flex justify-content-between mb-2'>
            <h2 className='font-bold'>{literals.contracts}</h2>
            <h3 className='text-gray'>{info?.length || ''}</h3>
          </div>
          {(info || []).map((payment) => (
            <div key={payment?.contractId} className='d-flex justify-content-between mb-2'>
              <h2 className='font-bold'>{payment?.contractId}</h2>
              <h3 className='text-gray'>{formatCurrency(payment.amount)}</h3>
            </div>
          ))}
          <div className='d-flex justify-content-between mb-2'>
            <h2 className='font-bold'>{literals.date}</h2>
            <h3 className='text-gray'>{formatDate(formatInfo?.date, 'DD/MM/YYYY')}</h3>
          </div>
          <div className='d-flex justify-content-between mb-2'>
            <h2 className='font-bold'>{literals.paymentMethod2}</h2>
            <h3 className='text-gray font-bold'>{formatInfo?.providerType}</h3>
          </div>
          <div className='d-flex justify-content-between mb-2'>
            <h2 className='font-bold'>{literals.status}</h2>
            <h3 className='text-gray font-bold'>{literals.statusValues[formatInfo?.status] || formatInfo?.status}</h3>
          </div>
          <div className='separator my-3' />
          <div className='d-flex justify-content-between mb-2'>
            <h2 className='font-bold'>{isSuccess ? literals.totalAmountPaid : literals.totalAmount}</h2>
            <h3 className='text-primary font-bold'>{formatCurrency(formatInfo?.totalAmount)}</h3>
          </div>
          <Button
            to={ROUTE_PATH.HOME}
            className='mt-4'
            type='primary'
            text={literals.common.back}
          />
        </div>
      )}
      {error && (
        <div>
          <h1 className='mb-4 text-primary text-center'>{literals.paymentNotFound}</h1>
          <Button
            to={ROUTE_PATH.HOME}
            className='mt-4'
            type='primary'
            text={literals.common.back}
          />
        </div>
      )}
    </Layout>
  );
};

export default MultiplePaymentResponse;
