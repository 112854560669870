import Layout from 'components/Layout';
import useLiterals from 'utils/hooks/useLiterals';
import Lottie from 'react-lottie';
import * as animationData from 'assets/animations/maintenance.json';

const Maintenance = () => {
  const literals = useLiterals('maintenance');
  return (
    <Layout rightMenu={false} className='text-center'>
      <Lottie
        options={{
          loop: true,
          autoplay: true,
          animationData,
          rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
          },
        }}
        width={275}
      />
      <h1 className='mb-2'>{literals.title}</h1>
      <p className='mt-3'>
        {literals.description}
      </p>
    </Layout>
  );
};

export default Maintenance;
