/* eslint-disable no-console */
const FLAG_DEBUG = window.DEBUG;

class Debugger {
  static info(message, data) {
    if (FLAG_DEBUG) {
      console.log(
        `%cInfo${message ? `: %c${message}` : '%c'}`,
        'font-weight:bold',
        'font-weight:normal',
      );
      if (data) console.log(data);
    }
  }

  static warning(message, data) {
    if (FLAG_DEBUG) {
      console.log(
        `%cWarning${message ? `: %c${message}` : '%c'}`,
        'font-weight:bold; color: orange',
        'font-weight:normal',
      );
      if (data) console.log(data);
    }
  }

  static error(message, data) {
    if (FLAG_DEBUG) {
      console.log(
        `%cError${message ? `: %c${message}` : '%c'}`,
        'font-weight:bold; color: red;',
        'font-weight:normal',
      );
      if (data) console.error(data);
    }
  }

  static line() {
    if (FLAG_DEBUG) {
      console.log(
        '%c-------------------------------',
        'font-weight:bold',
      );
    }
  }
}

export default Debugger;
