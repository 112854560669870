/* eslint-disable no-unused-vars */
import ApiFetch from 'utils/apiFetch';
import md5 from 'crypto-js/md5';

const ApiFetchInstance = ApiFetch();

export const URLS = {
  notification: '/appointments/notifications',
};

export default {
  async sendNotification(data) {
    ApiFetchInstance.defaults.headers.common['X-Token'] = md5(data.id);
    const response = await ApiFetchInstance.post(URLS.notification, data);
    return response;
  },
};
