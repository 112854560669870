export const ENVIRONMENT = window.ENVIRONMENT || window.ENVIROMENT;

export const ENVIRONMENT_TYPE = Object.freeze({
  PRODUCTION: 'pro',
  PREPRODUCTION: 'pre',
  DEVELOPMENT: 'dev',
});

export const PHONE_MEXICO_CONTRY_CODE = '+52';
export const PHONE_EEUU_CONTRY_CODE = '+1';

export const ROLES = Object.freeze({
  ADMIN: 'ADMIN',
  USER: 'USER',
});

export const TIMEZONE = -6;
