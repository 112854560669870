import PropTypes from 'prop-types';
import CardsCarousel from 'components/CardsCarousel/CardsCarousel';
import { getCMSDocumentSrc } from 'utils/getCMSDocumentSrc';
import './styles.scss';
import Slides from 'components/Slides';
import SimpleCardList from 'components/SimpleCardList';
import Card from 'components/Card';
import WelcomeView from 'routes/Welcome/components/WelcomeView';
import EmbedPdf from 'components/EmbedPdf';
import ZohoForm from 'components/ZohoForm';

const BLOCK_TYPES = {
  RICH_TEXT: 'richText',
  CARDS_CAROUSEL: 'CardsCarousel',
  EMBED_DOCUMENT: 'EmbedDocument',
  ONBOARDING: 'Onboarding',
  SIMPLE_CARD_LIST: 'SimpleCardList',
  CARD: 'Card',
  WELCOME: 'Welcome',
  HTML: 'html',
  ZOHO_FORM: 'ZohoForm',
};

const Block = ({ type, value, blockProps }) => {
  switch (type) {
    case BLOCK_TYPES.RICH_TEXT:
      return <div className='block-rich-text' dangerouslySetInnerHTML={{ __html: value.html }} />;
    case BLOCK_TYPES.CARDS_CAROUSEL:
      return (
        <CardsCarousel
          title={value.title}
          link={value.link}
          cards={(value?.items || []).map((item) => ({
            id: item.id,
            ...item.value,
          }))}
          {...blockProps}
        />
      );
    case BLOCK_TYPES.ONBOARDING:
      return (
        <Slides
          slides={(value?.slides || []).map((slide) => ({
            id: slide.id,
            ...slide.value,
            image: slide?.value?.image?.hash ? getCMSDocumentSrc(slide.value.image) : null,
          }))}
          actions={(value?.actions || []).map((action) => ({
            id: action.id,
            ...action.value,
          }))}
          {...blockProps}
        />
      );
    case BLOCK_TYPES.EMBED_DOCUMENT:
      if (!value?.file?.hash) {
        return null;
      }
      return (
        <div className='block-embed-document'>
          <EmbedPdf
            src={getCMSDocumentSrc(value.file)}
            downloadable
          />
        </div>
      );
    case BLOCK_TYPES.SIMPLE_CARD_LIST:
      return (
        <SimpleCardList
          title={value?.title || ''}
          cards={(value?.items || []).map((item) => ({
            id: item.id,
            ...item.value,
            image: item?.value?.image?.hash ? getCMSDocumentSrc(item.value.image) : null,
          }))}
        />
      );
    case BLOCK_TYPES.CARD:
      return (
        <Card
          {...value}
        />
      );
    case BLOCK_TYPES.WELCOME:
      return (
        <WelcomeView
          preTitle={value?.preTitle || ''}
          title={value?.title || ''}
          cards={(value?.cards || []).map((item) => ({
            id: item.id,
            ...item.value,
          }))}
          actions={(value?.actions || []).map((item) => ({
            id: item.id,
            ...item.value,
          }))}
        />
      );
    case BLOCK_TYPES.HTML:
      return (
        <div className='block-pure-html' dangerouslySetInnerHTML={{ __html: value.html }} />
      );
    case BLOCK_TYPES.ZOHO_FORM:
      return (
        <ZohoForm
          hash={value.hash || ''}
          questions={value.questions || []}
        />
      );
    default:
      return null;
  }
};

Block.propTypes = {
  type: PropTypes.string.isRequired,
  value: PropTypes.object.isRequired,
  blockProps: PropTypes.object,
};

Block.defaultProps = {
  blockProps: {},
};

export default Block;
