export const validatePassword = (password, repeatNewPassword = '') => {
  const data = {
    success: false,
    info: {
      length: password.length >= 8 && password.length <= 15,
      capitalLetter: password.match(/[A-Z]/) || false,
      number: password.match(/[0-9]/) || false,
      specialCharacter: password.match(/[^A-Za-z0-9]/) || false,
      equal: password && password === repeatNewPassword,
    },
  };

  data.success = Object.values(data.info).every((v) => v);

  return data;
};
