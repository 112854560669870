import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useLiterals from 'utils/hooks/useLiterals';
import InputPassword from 'components/UI/InputPassword';
import Button from 'components/UI/Button';
import { editPassword } from 'modules/session/actions';
import SuccessModal from 'components/FeedbackModals/SuccessModal';
import ErrorModal from 'components/FeedbackModals/ErrorModal';
import PasswordRuleList from 'components/PasswordRuleList';
import { validatePassword } from 'utils/validatePassword';
import { ROUTE_PATH } from 'routes';

const ModifyPassword = () => {
  const literals = useLiterals('settings');
  const [form, setForm] = useState({
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
  });
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);
  const { currentPassword, newPassword, confirmPassword } = form;
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const response = await editPassword(currentPassword, newPassword);
    if (response === true) {
      setSuccess(true);
    } else {
      switch (response?.statusCode) {
        case 401:
          setError(literals.wrongPassword);
          break;
        case 400:
          setError(literals.common.passwordRequirements);
          break;
        default:
          setError(literals.recoverPassword.errorDescription);
      }
    }
    setLoading(false);
  };

  const validation = validatePassword(newPassword, confirmPassword);

  return (
    <>
      <h1 className='mb-2'>{literals.modifyPassword}</h1>
      <div>{literals.enterFollowingData}</div>
      <form onSubmit={handleSubmit}>
        <InputPassword
          className='mt-4'
          value={currentPassword}
          placeholder={literals.currentPassword}
          onChange={(v) => setForm({ ...form, currentPassword: v })}
        />
        <InputPassword
          className='mt-3'
          value={newPassword}
          placeholder={literals.newPassword}
          onChange={(v) => setForm({ ...form, newPassword: v })}
        />
        <InputPassword
          className='mt-3 mb-3'
          value={confirmPassword}
          placeholder={literals.confirmPassword}
          onChange={(v) => setForm({ ...form, confirmPassword: v })}
        />
        <PasswordRuleList validation={validation} />
        <Button
          isSubmitBtn
          loading={loading}
          disabled={!validation.success || !currentPassword}
          className='mt-5'
          text={literals.confirm}
        />
      </form>
      {success && (
        <SuccessModal
          description={literals.passwordSaved}
          onClose={() => navigate(ROUTE_PATH.setSettings())}
        />
      )}
      {error && (
        <ErrorModal
          description={error}
          onClose={() => setError(false)}
        />
      )}
    </>
  );
};

export default ModifyPassword;
