import * as Sentry from '@sentry/react';

export const sendSentryLog = (data, message) => {
  try {
    if (Sentry) {
      Sentry.withScope((scope) => {
        scope.setLevel('log');
        if (Array.isArray(data)) {
          data.forEach((item) => {
            scope.addBreadcrumb(item);
          });
        } else {
          scope.addBreadcrumb({
            message: 'Log',
            data,
          });
        }
        Sentry.captureMessage(message);
      });
    }
  } catch (error) {
    console.error('Error sending log to Sentry', error);
  }
};
