import useLiterals from 'utils/hooks/useLiterals';
import PropTypes from 'prop-types';
import './styles.scss';

const PasswordRuleList = ({ validation }) => {
  const literals = useLiterals('auth').resetPassword.passwordRules;
  const { info } = validation;

  return (
    <ul className='password-rules'>
      <li className='flex items-center text-sm'>
        <span className={`dot ${info.length ? 'ok ' : 'ko '}`} />
        {literals.length}
      </li>
      <li className='flex items-center text-sm'>
        <span className={`dot ${info.number ? 'ok ' : 'ko '}`} />
        {literals.number}
      </li>
      <li className='flex items-center text-sm'>
        <span className={`dot ${info.specialCharacter ? 'ok ' : 'ko '}`} />
        {literals.specialCharacter}
      </li>
      <li className='flex items-center text-sm'>
        <span className={`dot ${info.capitalLetter ? 'ok ' : 'ko '}`} />
        {literals.capitalLetter}
      </li>
      <li className='flex items-center text-sm'>
        <span className={`dot ${info.equal ? 'ok ' : 'ko '}`} />
        {literals.equal}
      </li>
    </ul>
  );
};

PasswordRuleList.propTypes = {
  validation: PropTypes.object.isRequired,
};

export default PasswordRuleList;
