import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { loadingActions } from 'modules/loading';
import { useDispatch } from 'react-redux';
import './styles.scss';

const widgetCode = 'siq860890dc970c4dab9db1ff24cc83501683c3ec7a69af5fb2e47432e1a522b4fd';

const Chat = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadingActions.show());
    setTimeout(() => {
      dispatch(loadingActions.hide());
    }, 1000);
    if (window.$zoho) {
      window.$zoho.salesiq.floatwindow.visible('show');
    } else {
      // Crea un script y añade los atributos correspondientes
      const script = document.createElement('script');
      script.src = 'https://salesiq.zohopublic.com/widget';
      script.defer = true;
      script.id = 'zsiqscript';

      window.$zoho = window.$zoho || {};
      window.$zoho.salesiq = window.$zoho.salesiq || {
        widgetcode: widgetCode,
        values: {},
      };

      window.$zoho.salesiq.ready = () => {
        window.$zoho.salesiq.chatbutton.visible('hide');
        window.$zoho.salesiq.floatbutton.visible('hide');
        window.$zoho.salesiq.floatbutton.coin.hidetooltip();
        window.$zoho.salesiq.floatwindow.visible('show');
        window.$zoho.salesiq.floatwindow.minimize(() => {
          navigate(-1);
        });
      };

      document.body.appendChild(script);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default Chat;
