/* eslint-disable no-plusplus */
import { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import SimpleCardList from '.';
import './styles.scss';

const MAX_VISIBLE_PAGES = 5;

const SimpleCardListPaginated = ({ title, cards, pageSize }) => {
  const [page, setPage] = useState(0);
  const totalPages = useMemo(() => Math.ceil((cards || []).length / pageSize), [cards, pageSize]);
  const visiblePages = useMemo(() => {
    if (totalPages <= MAX_VISIBLE_PAGES) {
      return Array.from({ length: totalPages }).map((_, i) => i);
    }
    const current = page + 1;
    let visible = [1];
    const half = Math.floor(MAX_VISIBLE_PAGES / 2);
    for (let i = current - half + 1; i < current + half; i++) {
      if (i > 1 && i < totalPages && !visible.includes(i)) {
        visible.push(i);
      }
    }
    visible.push(totalPages);

    if ((visible[0] + 1) < visible[1]) {
      visible = [visible[0], '...', ...visible.slice(1)];
    }
    if (visible[visible.length - 2] + 1 < visible[visible.length - 1]) {
      visible = [...visible.slice(0, -1), '...', visible[visible.length - 1]];
    }

    return visible;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);
  const cardsPaginated = useMemo(() => {
    return (cards || []).slice(page * pageSize, (page + 1) * pageSize);
  }, [page, pageSize, cards]);

  const handleChangePage = (value) => () => {
    let newPage = 0;
    switch (value) {
      case '...':
        return;
      case '+':
        newPage = page + 1;
        break;
      case '-':
        newPage = page - 1;
        break;
      default:
        newPage = value;
        break;
    }
    if (newPage >= 0 && newPage < totalPages) {
      setPage(newPage);
    }
  };

  return (
    <div className='simple-card-list-paginated'>
      <SimpleCardList
        title={title}
        cards={cardsPaginated}
        startIndex={page * pageSize}
      />
      {totalPages > 1 && (
        <div className='pager'>
          <div className='pager-list'>
            <div
              key='page-prev'
              className={page === 0 ? 'disabled' : ''}
              onClick={handleChangePage('-')}
            >
              {'<'}
            </div>
            {visiblePages.map((i, it) => (
              <div
                key={`page-${i}-${it}`}
                className={i === page ? 'active' : ''}
                onClick={handleChangePage(i)}
              >
                {i + 1}
              </div>
            ))}
            <div
              key='page-next'
              className={page === (totalPages - 1) ? 'disabled' : ''}
              onClick={handleChangePage('+')}
            >
              {'>'}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

SimpleCardListPaginated.propTypes = {
  title: PropTypes.string,
  cards: PropTypes.array,
  pageSize: PropTypes.number,
};

SimpleCardListPaginated.defaultProps = {
  title: '',
  cards: [],
  pageSize: 10,
};

export default SimpleCardListPaginated;
