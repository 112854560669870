import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import Loading from 'components/Loading';

const SplashScreen = ({ onFinish }) => {
  const [isHidden, setIsHidden] = useState(false);
  const [isFinished, setIsFinished] = useState(false);
  const refHidden = useRef(null);
  const refForce = useRef(null);
  const refVideo = useRef(null);
  const isIOS = window.onIOSApp();

  useEffect(() => {
    refHidden.current = setTimeout(() => {
      setIsHidden(true);
    }, 1000);

    // In ios the video it ever crash, so we need to force the finish
    refForce.current = setTimeout(() => {
      if (!isFinished) {
        setIsFinished(true);
      }
    }, isIOS ? 1500 : 4000);

    return () => {
      if (refHidden.current) {
        clearTimeout(refHidden.current);
      }
      if (refForce.current) {
        clearTimeout(refForce.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isFinished) {
      onFinish();
      if (refVideo.current) {
        refVideo.current.remove();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFinished]);

  const handleFinish = () => {
    setIsFinished(true);
  };

  return (
    <div
      className={`splash-screen ${isHidden ? 'splash-hidden' : ''} ${isFinished ? 'd-none' : ''}`}
      onTransitionEnd={handleFinish}
    >
      {isIOS ? (
        <div className='splash-ios'>
          <Loading force />
        </div>
      ) : (
        <video ref={refVideo} autoPlay muted style={{ width: '100vw' }} poster='/files/video_poster.jpg' preload='auto'>
          <source src='/files/video_intro.mp4' type='video/mp4' />
        </video>
      )}
    </div>
  );
};

SplashScreen.propTypes = {
  onFinish: PropTypes.func.isRequired,
};

export default SplashScreen;
