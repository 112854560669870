import Debugger from 'utils/debugger';
import AppointmentApi from './repository';

export async function sendNotification(id, date, time, type, officeName) {
  try {
    const response = await AppointmentApi.sendNotification({
      id,
      date,
      time,
      type,
      office_name: officeName,
    });
    return response;
  } catch (error) {
    Debugger.error(error);
    return false;
  }
}
