import Box from 'components/UI/Box/Box';
import PropTypes from 'prop-types';
import './styles.scss';

const MenuSwitch = ({ options, onChange, selected }) => {
  return (
    <Box className='mb-3 menu-switch-box'>
      <div className='menu-switch-container'>
        {options.map((option) => (
          <div
            key={option.value}
            className={`menu-option ${selected === option.value ? 'selected' : ''}`}
            onClick={() => onChange(option.value)}
          >
            <span>{option.label}</span>
            {option.notification && (
              <span className='notification'>{option.notification}</span>
            )}
          </div>
        ))}
      </div>
    </Box>
  );
};

MenuSwitch.propTypes = {
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  selected: PropTypes.string,
};

MenuSwitch.defaultProps = {
  selected: '',
};

export default MenuSwitch;
