import PropTypes from 'prop-types';
import Carousel from 'components/UI/Carousel';
import { Link } from 'react-router-dom';
import Card from 'components/Card';
import './styles.scss';

const CardsCarousel = (props) => {
  const {
    className,
    title,
    link,
    cards,
  } = props;

  const slides = cards.map((card) => (
    <Card key={`card-${card.id}`} {...card} />
  ));

  return (
    <div className={`cards-carousel ${className}`}>
      <div className='d-flex justify-content-between align-items-center mb-3 carousel-title'>
        {title && (<h2 className='m-0'>{title}</h2>)}
        {link?.archor && (
          <Link to={link.link} className='font-bold'>
            {link?.archor}
          </Link>
        )}
      </div>
      {slides.length > 1 ? (
        <Carousel slides={slides} showNext />
      ) : slides[0]}
    </div>
  );
};

CardsCarousel.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  link: PropTypes.object,
  cards: PropTypes.array.isRequired,
};

CardsCarousel.defaultProps = {
  className: '',
  title: '',
  link: {
    link: '',
    archor: '',
  },
};

export default CardsCarousel;
