import Debugger from 'utils/debugger';
import { getUserPosition } from './getUserPosition';

export const DEFAULT_COORDS = { // Cd. de México
  latitude: 19.4326077,
  longitude: -99.13320799999997,
};

export const GOOGLE_API_KEY = window.GOOGLE_API_KEY;

export const DEFAULT_ZOOM = 11;
export const SEARCH_ZOOM = 16;

window.initGMap = () => {
  Debugger.info(' -- GOOGLE MAPS SCRIPT LOADED -- ');
};

export function acquireUserLocation() {
  return getUserPosition(DEFAULT_COORDS);
}
