import useLiterals from 'utils/hooks/useLiterals';
import Layout from 'components/Layout';
import Contracts from './components/Contracts';
import AccountStatements from './components/AccountStatements';
import './styles.scss';

const Documents = () => {
  const literals = useLiterals('documents');
  return (
    <Layout
      bottomMenu
      onBack
    >
      <div className='documents'>
        <h3 className='mb-3'>{literals.contracts}</h3>
        <Contracts />
        <h3 className='my-3'>{literals.accountStatements}</h3>
        <AccountStatements />
      </div>
    </Layout>
  );
};

export default Documents;
