import useLiterals from 'utils/hooks/useLiterals';
import { useNavigate } from 'react-router-dom';
import Layout from 'components/Layout';
import { ROUTE_PATH } from 'routes';
import CmsBlocks from 'components/CmsBlocks';
import { EFFECT_LIST_BOX_UP_IN_VIEW } from 'constants/effects';
import { BOTTOM_MENU_ACTIONS } from 'components/Layout/components/BottomMenu';

const Services = () => {
  const navigate = useNavigate();
  const literals = useLiterals('services');

  const handleGoBack = () => {
    navigate(ROUTE_PATH.HOME);
  };

  return (
    <Layout
      title={literals.title}
      onBack={handleGoBack}
      bottomMenu={{
        type: BOTTOM_MENU_ACTIONS.AUTOMATIC_QUOTER,
      }}
    >
      <div>
        <CmsBlocks animate={EFFECT_LIST_BOX_UP_IN_VIEW} />
      </div>
    </Layout>
  );
};

export default Services;
