import PropTypes from 'prop-types';
import Button from 'components/UI/Button';
import Input from 'components/UI/Input';
import Select from 'components/UI/Select';

const StoreContact = (props) => {
  const {
    literals,
    store,
    form,
    onChange,
    onAppointment,
    onCancel,
  } = props;

  const disabled = !form.name || !form.email || !form.product || !form.date;

  const handleSubmit = (e) => {
    e.preventDefault();
    onAppointment(form);
  };

  return (
    <div>
      <p className='h3 font-regular'>{literals.shop}</p>
      <h1 className='mb-4'>{store.Name}</h1>
      <form onSubmit={handleSubmit}>
        <Input
          className='mb-3'
          placeholder={literals.common.name}
          value={form.name || ''}
          onChange={(v) => onChange('name', v)}
          required
        />
        <Input
          className='mb-3'
          type='email'
          placeholder={literals.common.email}
          value={form.email || ''}
          onChange={(v) => onChange('email', v)}
          required
        />
        <Input
          className='mb-3'
          type='tel'
          placeholder={literals.common.phone}
          value={form.phone || ''}
          onChange={(v) => onChange('phone', v)}
        />
        <Select
          className='mb-3'
          placeholder={literals.common.product}
          value={form.product || ''}
          onChange={(v) => onChange('product', v)}
          options={[
            { label: 'Producto 1', value: '1' },
            { label: 'Producto 2', value: '2' },
            { label: 'Producto 3', value: '3' },
          ]}
          required
        />
        <Input
          className='mb-3'
          type='date'
          placeholder={literals.common.date}
          value={form.date || ''}
          onChange={(v) => onChange('date', v)}
          required
        />
        <Input
          className='mb-3'
          type='time'
          placeholder={literals.common.hour}
          value={form.hour || ''}
          onChange={(v) => onChange('hour', v)}
        />
        <Button
          type='secondary'
          text={literals.common.cancel}
          className='mt-4'
          onClick={onCancel}
        />
        <Button
          isSubmitBtn
          text={literals.common.send}
          disabled={disabled}
          className='mt-3'
        />
      </form>
    </div>
  );
};

StoreContact.propTypes = {
  literals: PropTypes.object.isRequired,
  store: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onAppointment: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default StoreContact;
