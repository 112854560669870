/* eslint-disable no-param-reassign */
/* eslint-disable no-use-before-define */
import {
  useEffect, useMemo, useRef, useState,
} from 'react';
import SuccessModal from 'components/FeedbackModals/SuccessModal';
import { loadingActions } from 'modules/loading';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import useLiterals from 'utils/hooks/useLiterals';
import { translate } from 'utils/translate';
import ErrorModal from 'components/FeedbackModals/ErrorModal';
import './styles.scss';

const ZohoForm = (props) => {
  const {
    hash,
    questions: questionsProp,
  } = props;

  const literals = useLiterals('zohoForm');
  const dispatch = useDispatch();
  const formContainerRef = useRef(null);
  const urlParams = new URLSearchParams(window.location.search);
  const [statusModal, setStatusModal] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const formTimeout = useRef(null);
  const questionsTimeout = useRef(null);

  const questions = useMemo(() => {
    const map = {};
    (questionsProp || []).forEach((question) => {
      if (question?.value?.id) map[question.value.id] = question.value;
    });
    return map;
  }, [questionsProp]);

  const onInputChange = (event) => {
    let inputValue = event.target.value;
    const questionCfg = questions[event.target.id];
    switch (questionCfg?.validation) {
      case 'phone':
        if (/\D/.test(inputValue)) {
          inputValue = inputValue.replace(/\D/g, '');
        }
        if (inputValue.length > 10) {
          inputValue = inputValue.slice(0, 10);
        }
        event.target.value = inputValue;
        break;
      case 'number':
        if (/\D/.test(inputValue)) {
          event.target.value = inputValue.replace(/\D/g, '');
        }
        break;
      case 'alphabetic':
        if (/[^a-zA-Z\s]/.test(inputValue)) {
          event.target.value = inputValue.replace(/[^a-zA-Z\s]/g, '');
        }
        break;
      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  function handleFormSubmit(event) {
    event.preventDefault();
    event.stopPropagation();

    const formData = new FormData(event.target);

    let formError = null;
    Object.keys(questions).forEach((questionId) => {
      if (!formError) {
        const cfg = questions[questionId];
        const value = formData.get(questionId);
        if (cfg?.mandatory && !value) {
          formError = translate(literals.mandatoryField, { field: cfg.label || cfg.id });
        }
        if (!formError) {
          switch (cfg?.validation) {
            case 'email':
              if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
                formError = translate(literals.invalidEmail, { field: cfg.label || cfg.id });
              }
              break;
            case 'phone':
              if (!/^\d{10}$/.test(value)) {
                formError = translate(literals.invalidPhone, { field: cfg.label || cfg.id });
              }
              break;
            default:
              break;
          }
        }
      }
    });

    if (!formError) {
      fetch(event.target.action, {
        method: 'POST',
        body: formData,
      }).then(() => {
        setStatusModal('success');
      }).catch((err) => {
        setStatusModal('error');
        console.error('Error al enviar el formulario:', err);
      });
    } else {
      // eslint-disable-next-line no-alert
      alert(formError);
    }
  }

  useEffect(() => {
    clearTimeout(formTimeout.current);
    if (hash) {
      formTimeout.current = setTimeout(() => {
        if (formContainerRef.current) {
          formContainerRef.current.innerHTML = '';
        }
        dispatch(loadingActions.show());
        const script = document.createElement('script');
        script.src = hash;
        script.async = true;
        script.onload = () => {
          // Espera a que el contenido sea creado por el script de Zoho CRM.
          clearTimeout(questionsTimeout.current);
          questionsTimeout.current = setTimeout(() => {
            const zohoContent = document.querySelector('.crmWebToEntityForm');
            if (formContainerRef.current && zohoContent) {
              formContainerRef.current.appendChild(zohoContent);
            }

            const formsubmitElements = document.getElementsByClassName('formsubmit');
            for (let i = 0; i < formsubmitElements.length; i += 1) {
              const element = formsubmitElements[i];

              // Eliminar todas las clases del elemento
              element.className = 'formsubmit';

              element.classList.add('btn');
              element.classList.add('btn-primary');
              element.classList.add('mb-4');
              element.classList.add('btn-size-md');
              element.classList.add('font-size-md');

              element.value = literals[element?.value?.toLowerCase()] || element.value;
            }

            const secondaryButtons = document.getElementsByClassName('zcwf_button');
            for (let i = 0; i < secondaryButtons.length; i += 1) {
              const element = secondaryButtons[i];

              // Eliminar todas las clases del elemento
              element.className = '';

              element.classList.add('btn');
              element.classList.add('btn-secondary');
              element.classList.add('mb-4');
              element.classList.add('btn-size-md');
              element.classList.add('font-size-md');

              element.value = literals[element?.value?.toLowerCase()] || element.value;
            }

            const inputs = [
              ...document.querySelectorAll('input'),
              ...document.querySelectorAll('select'),
            ];

            for (let i = 0; i < inputs.length; i += 1) {
              const element = inputs[i];
              if (element.id && element.id !== 'formsubmit') {
                element.className = 'form-control';
                const labels = document.querySelectorAll(`label[for="${element.id}"]`);
                if (element.type === 'select-one' && labels.length > 0 && element.options.length > 0) {
                  element.options[0].textContent = labels[0].textContent;
                } else if (labels.length > 0 && element.getAttribute('ftype') !== 'date') {
                  const label = labels[0];
                  element.placeholder = literals[element.id] || label.textContent;
                }
                const paramValue = urlParams.get(element.id);
                if (paramValue) {
                  element.value = paramValue;
                }
                const questionCfg = questions[element.id];
                if (questionCfg) {
                  if (questionCfg.label) {
                    let newPlaceholder = questionCfg.label;
                    if (element.placeholder.endsWith('*')) {
                      newPlaceholder += '*';
                    }
                    element.placeholder = newPlaceholder;
                  }
                  if (questionCfg.mandatory) {
                    const newPlaceholder = element.placeholder;
                    if (!newPlaceholder.endsWith('*')) {
                      element.placeholder += `${element.placeholder}*`;
                    }
                  }
                  const parent = element.closest('.zcwf_row');
                  if (parent) {
                    parent.style.display = questionCfg.hidden ? 'none' : 'block';
                  }
                }
                element.addEventListener('input', onInputChange);
              }
            }

            const cols = [
              ...document.getElementsByClassName('zcwf_col_fld'),
              ...document.getElementsByClassName('zcwf_col_fld_slt'),
            ];
            for (let i = 0; i < cols.length; i += 1) {
              const element = cols[i];
              // Eliminar todas las clases del elemento
              element.className = 'd-flex gap-2';
            }

            const helpTexts = formContainerRef.current.getElementsByClassName('zcwf_col_help');

            for (let i = 0; i < helpTexts.length; i += 1) {
              const element = helpTexts[i];
              if (element.textContent !== '') {
                element.className = 'btn-link font-size-sm help-link';
              }
            }

            const zohoForm = formContainerRef.current.querySelector('.crmWebToEntityForm form');
            if (zohoForm) {
              const onsubmitAttribute = zohoForm.getAttribute('onsubmit');
              if (onsubmitAttribute) {
                const match = onsubmitAttribute.match(/checkMandatory\d+/);
                const checkMandatoryFunctionName = match ? match[0] : null;
                zohoForm.removeAttribute('onsubmit');
                zohoForm.addEventListener('submit', (event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  if (checkMandatoryFunctionName) {
                    const checkMandatoryFunction = window[checkMandatoryFunctionName];
                    const isFormValid = checkMandatoryFunction();
                    if (isFormValid || isFormValid === undefined) {
                      handleFormSubmit(event);
                    }
                  } else {
                    handleFormSubmit(event);
                  }
                });
              }
            }

            dispatch(loadingActions.hide());
            setLoaded(true);
          }, !loaded ? 1000 : 1); // El tiempo de espera depende de cuánto tarda Zoho CRM en cargar el contenido, podrías necesitar ajustar este valor.
        };

        document.body.appendChild(script);
      }, 250);
    } else {
      setLoaded(true);
    }

    return () => {
      const messagesLayers = document.querySelectorAll('.messages-layer');
      messagesLayers.forEach((layer) => {
        layer.remove(); // Elimina el elemento div con la clase "messages-layer"
      });
      const chatFormLayers = document.querySelectorAll('.chat-form');
      chatFormLayers.forEach((layer) => {
        layer.remove(); // Elimina el elemento div con la clase "messages-layer"
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hash, questions]);

  return (
    <div>
      <div className='zoho-form' ref={formContainerRef} id='form-container' />
      {statusModal === 'success' && (
        <SuccessModal
          title={literals.formSent}
          description=''
          onClose={() => setStatusModal(null)}
        />
      )}
      {statusModal === 'error' && (
        <ErrorModal
          title={literals.formSentError}
          description=''
          onClose={() => setStatusModal(null)}
        />
      )}
    </div>
  );
};

ZohoForm.propTypes = {
  hash: PropTypes.string,
  questions: PropTypes.array,
};

ZohoForm.defaultProps = {
  hash: '',
  questions: [],
};

export default ZohoForm;
