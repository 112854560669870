import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import './styles.scss';

const DEFAULT_Z_INDEX = 1500;

function ModalWrapper({
  children, onClose, darkBackground, className, zIndex, position,
}) {
  return ReactDOM.createPortal(
    <div
      className={`modal-wrapper justify-content-${position} ${className}`}
      style={{ '--z-index': zIndex || DEFAULT_Z_INDEX }}
    >
      {children}
      <div onClick={onClose} className={`modal-wrapper__background ${darkBackground ? 'modal-wrapper__background--dark' : ''}`} />
    </div>,
    document.getElementById('root-modal'),
  );
}

ModalWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func,
  darkBackground: PropTypes.bool,
  className: PropTypes.string,
  zIndex: PropTypes.number,
  position: PropTypes.oneOf(['center', 'start', 'end']),
};
ModalWrapper.defaultProps = {
  onClose: undefined,
  darkBackground: true,
  className: '',
  zIndex: null,
  position: 'center',
};

export default ModalWrapper;
