/* eslint-disable no-unused-vars */
import ApiFetch from 'utils/apiFetch';

const ApiFetchInstance = ApiFetch();

export const URLS = {
  createReference: '/payments/bancomer/references',
  createMultipleReference: '/payments/bancomer/references/batch',
  bancomerPDF: (externalOrder) => `/payments/bancomer/references/${externalOrder}/pdf`,
};

export default {
  async createReference(data) {
    const response = await ApiFetchInstance.post(URLS.createReference, data);
    return response;
  },
  async createMultipleReference(data) {
    const response = await ApiFetchInstance.post(URLS.createMultipleReference, data);
    return response;
  },
  async getBancomerPDF(externalOrder) {
    const response = await ApiFetchInstance.get(URLS.bancomerPDF(externalOrder), { responseType: 'blob' });
    return response;
  },
};
