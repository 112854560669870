import PropTypes from 'prop-types';
import useLiterals from 'utils/hooks/useLiterals';
import Box from 'components/UI/Box/Box';
import StepsProgress from 'components/UI/StepsProgress';
import Button from 'components/UI/Button';
import MercadoLibreImg from 'assets/images/mercado_libre.jpg';
import MercadoPagoImg from 'assets/images/mercado_pago.jpg';
import { ReactComponent as FinsusLogo } from 'assets/logos/logo_finsus.svg';

const ShippingAddress = ({
  step, steps, changeStep,
}) => {
  const { paymentMethodStep: literals } = useLiterals('paymentGateway');

  const renderPaymentMethods = () => {
    return (
      <>
        <div className='d-flex gap-2 mb-2'>
          <Box className='w-50'>
            <img src={MercadoLibreImg} alt='Mercado Libre' className='payment-logo w-100' />
          </Box>
          <Box className='w-50'>
            <img src={MercadoPagoImg} alt='Mercado Libre' className='payment-logo w-100' />
          </Box>
        </div>
        <div className='d-flex gap-2 mb-2'>
          <Box className='w-50'>
            <FinsusLogo className='payment-logo' />
          </Box>
        </div>
      </>
    );
  };

  return (
    <Box>
      <div className='payment-methods'>
        <h3 className='my-0 font-regular'>{literals.title}</h3>
        <h1 className='text-primary my-2'>{literals.paymentMethod}</h1>
        <StepsProgress steps={steps} current={step} className='w-100 mb-4' />
        <h3 className='mb-2'>{literals.subtitle}</h3>
        {renderPaymentMethods()}
        <Button
          className='w-100 mt-3'
          text={literals.payWithCard}
          onClick={() => changeStep(step + 1)}
        />
      </div>
    </Box>
  );
};

ShippingAddress.propTypes = {
  step: PropTypes.number.isRequired,
  steps: PropTypes.array.isRequired,
  changeStep: PropTypes.func.isRequired,
};

export default ShippingAddress;
