export const preparePath = (path, pathParams = {}, queryParams = {}) => {
  let newPath = path;
  Object.keys(pathParams || {}).forEach((key) => {
    newPath = newPath.replace(`:${key}`, pathParams[key]);
  });
  if (Object.keys(queryParams).length) {
    const cleanParams = Object.entries(queryParams).reduce((acc, [clave, valor]) => {
      if (valor !== undefined) {
        acc[clave] = valor;
      }
      return acc;
    }, {});
    if (Object.keys(cleanParams).length) {
      const params = Object.keys(cleanParams).map((key) => `${key}=${cleanParams[key]}`).join('&');
      newPath += `?${params}`;
    }
  }
  return newPath;
};
