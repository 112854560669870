/* eslint-disable no-mixed-operators */

function toRad(degrees) {
  return degrees * Math.PI / 180;
}

function calculateDistance(lat1, lon1, lat2, lon2) {
  const R = 6371; // Radio de la Tierra en kilómetros
  const dLat = toRad(lat2 - lat1);
  const dLon = toRad(lon2 - lon1);
  const a = Math.sin(dLat / 2) * Math.sin(dLat / 2)
    + Math.cos(toRad(lat1)) * Math.cos(toRad(lat2))
    * Math.sin(dLon / 2) * Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c; // Distancia en kilómetros
  return d;
}

export const getNearest = (lat, lon, places, n = 1) => {
  let nearest = [];
  let minDistances = [];

  // Calcular distancias para todas las tiendas y almacenarlas en un array
  places.forEach((place) => {
    const placeLat = place.lat || place.latitude || place.location.latitude || place.Latitud;
    const placeLon = place.lon || place.longitude || place.location.longitude || place.Longitud;

    if (!placeLat || !placeLon) {
      return;
    }

    const distance = calculateDistance(
      lat,
      lon,
      placeLat,
      placeLon,
    );

    // Insertar la distancia y la tienda en el array ordenadamente
    let i = 0;
    while (i < minDistances.length && minDistances[i] < distance) {
      i += 1;
    }
    minDistances.splice(i, 0, distance);
    nearest.splice(i, 0, place);
  });

  // Obtener las n tiendas más cercanas
  nearest = nearest.slice(0, n);
  minDistances = minDistances.slice(0, n);

  // Retornar las tiendas y sus distancias
  return nearest.map((place, index) => ({ ...place, distance: minDistances[index] }));
};

export const formatDistance = (distanceInKm) => {
  if (distanceInKm > 2) {
    return `${distanceInKm.toFixed(1)} km`;
  }
  const distanceInMeters = distanceInKm * 1000;
  return `${Math.round(distanceInMeters)} m`;
};
