import Debugger from 'utils/debugger';
import JewelsQuoterApi from './repository';

export async function getDiamondsSimulate(form) {
  try {
    const response = await JewelsQuoterApi.diamondsSimulate(form.weight, form.amount, form.purity, form.color?.id, form.cut);
    return response?.obj_data || null;
  } catch (error) {
    Debugger.error(error);
    return false;
  }
}

export async function getDiamondsCatalog() {
  try {
    const response = await JewelsQuoterApi.diamondsCatalog();
    return response;
  } catch (error) {
    Debugger.error(error);
    return false;
  }
}
