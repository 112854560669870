import PropTypes from 'prop-types';
import { useState } from 'react';
import classnames from 'classnames';
import './styles.scss';

function InputPassword(props) {
  const {
    label,
    value,
    onChange,
    name,
    required,
    className,
    autoComplete,
    placeholder,
    disabled,
    error,
  } = props;

  const [showPassword, setShowPassword] = useState(false);

  const handleChange = (e) => {
    onChange(e.target.value);
  };

  return (
    <div className={`ui-input-password ${className} ${error !== '' ? 'error' : ''}`}>
      {(label && false) && (
        <label htmlFor={name} className='form-label'>
          {label}
          {required && '*'}
        </label>
      )}
      <div className='position-relative'>
        <input
          id={name}
          required={required}
          value={value}
          onChange={handleChange}
          name={name}
          type={showPassword ? 'text' : 'password'}
          className='form-control ui-input-password__input'
          placeholder={placeholder}
          autoComplete={autoComplete}
          disabled={disabled}
        />
        {error !== '' && (
          <label htmlFor={name} className='form-label'>
            {error}
          </label>
        )}
        <div
          className='btn-toggle-password position-absolute top-0'
          onClick={() => setShowPassword(!showPassword)}
        >
          <i className={classnames('fa', { 'fa-eye': showPassword }, { 'fa-eye-slash': !showPassword })} />
        </div>
      </div>
    </div>
  );
}

InputPassword.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  autoComplete: PropTypes.string,
  placeholder: PropTypes.string,
  error: PropTypes.string,
};

InputPassword.defaultProps = {
  name: '',
  label: '',
  required: false,
  disabled: false,
  className: '',
  autoComplete: '',
  placeholder: '',
  error: '',
};

export default InputPassword;
