import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getNearest } from 'utils/distance';
import { DEFAULT_COORDS } from 'utils/googleMaps';

const useNearestStores = ({ latitude = DEFAULT_COORDS.latitude, longitude = DEFAULT_COORDS.longitude }, size = 5) => {
  const [list, setList] = useState([]);
  const { list: stores } = useSelector((state) => state.stores);

  useEffect(() => {
    setList(getNearest(latitude, longitude, stores, size));
  }, [latitude, longitude, stores, size]);

  return list;
};

export default useNearestStores;
