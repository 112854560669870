import { useEffect, useState } from 'react';
import Collapse from 'react-bootstrap/Collapse';
import Lottie from 'react-lottie';
import Button from 'components/UI/Button';
import useLiterals from 'utils/hooks/useLiterals';
import shopIcon from 'assets/icons/shop.svg';
import accessIcon from 'assets/icons/access.svg';
import StoresMap from 'components/StoresMap';
import Box from 'components/UI/Box/Box';
import * as animationData from 'assets/animations/notification.json';
import { formatDistance } from 'utils/distance';
import { ROUTE_PATH } from 'routes';
import { ZOHO_SCRIPT_TYPES } from 'constants/zohoScripts';
import { useDispatch, useSelector } from 'react-redux';
import { getUserPosition } from 'utils/getUserPosition';
import NearestStores from 'components/NearestStores';
import { setStore, updateCoords } from 'modules/stores/actions';
import { useNavigate } from 'react-router-dom';
import useNearestsStores from 'utils/hooks/useNearestsStores';

const FLAGS_OPTIONS = {
  loop: true,
  autoplay: true,
  animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const ShopBox = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const literals = useLiterals('home');
  const [userPosition, setUserPosition] = useState({});
  const nearestsStores = useNearestsStores(userPosition);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { store } = useSelector((state) => state.stores);
  const mainStore = store || (nearestsStores?.length && nearestsStores[0]) || null;

  useEffect(() => {
    const getLocationPermissions = async () => {
      const position = await getUserPosition();
      if (position) {
        setUserPosition({ ...position });
      }
    };
    getLocationPermissions();
  }, []);

  const handleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const handleClickStore = (data, to = null) => {
    if (!data?.id) return;
    dispatch(updateCoords({ latitude: data.latitude, longitude: data.longitude }));
    dispatch(setStore(data));
    navigate(to || ROUTE_PATH.STORES);
  };

  const renderBasicInfo = () => {
    if (isExpanded) return null;
    return (
      <div className='shop-box-header' onClick={handleExpand}>
        <div className='shop-box-header-info'>
          <Lottie
            options={FLAGS_OPTIONS}
            height={40}
            width={40}
          />
          <h4 className='shop-box-header-info-container-name'>
            {literals.shopNear1}
            {' '}
            <span className='text-primary text-decoration-underline'>{literals.shopNear2}</span>
            {' '}
            {literals.shopNear3}
          </h4>
        </div>
        <img src={accessIcon} alt='access-icon' />
      </div>
    );
  };

  const renderHeader = () => {
    if (!isExpanded) {
      return renderBasicInfo();
    }
    let address = mainStore?.address || mainStore?.name || literals.storesNotFound;
    if (address?.length > 75) {
      address = `${address.slice(0, 75)}...`;
    }
    return (
      <div className='shop-box-header mb-3'>
        <div className='shop-box-header-info' onClick={() => mainStore && handleClickStore(mainStore)}>
          <img src={shopIcon} alt='userImg' />
          <div className='shop-box-header-info-container'>
            <h3 className='shop-box-header-info-container-name'>{address}</h3>
            {mainStore?.distance ? (
              <span className='shop-box-header-info-container-address'>{`a ${formatDistance(mainStore?.distance)} de tu ubicación`}</span>
            ) : null}
          </div>
        </div>
      </div>
    );
  };

  const renderMap = () => {
    return (
      <div className='shop-box-map'>
        <StoresMap
          height={110}
          userPosition={userPosition}
          showInfo={false}
          showNearests={false}
        />
        <NearestStores
          className='mt-4'
          nearests={nearestsStores}
          ignoreFirst={!store}
          showTypes
          onClick={(s) => handleClickStore(s)}
        />
        {!mainStore ? (
          <Button
            size='sm'
            className='mt-3'
            type='secondary'
            to={ROUTE_PATH.STORES}
            text={literals.goToStores}
          />
        ) : null}
      </div>
    );
  };

  const renderShopActions = () => {
    if (!mainStore) return null;
    return (
      <div className='shop-box-actions mb-4'>
        <Button
          text={literals.common.showDetails}
          size='sm'
          type='secondary'
          onClick={() => handleClickStore(mainStore)}
        />
        <Button
          size='sm'
          text={literals.schedule}
          onClick={() => handleClickStore(mainStore, `${ROUTE_PATH.APPOINTMENT}?type=${ZOHO_SCRIPT_TYPES.APPOINTMENT}`)}
        />
      </div>
    );
  };

  if (!userPosition) {
    return null;
  }

  return (
    <Box className='shop-box mb-3' padding='sm'>
      {renderHeader()}
      <Collapse in={isExpanded}>
        {isExpanded ? (
          <div>
            {renderShopActions()}
            {renderMap()}
          </div>
        ) : <div />}
      </Collapse>
    </Box>
  );
};

export default ShopBox;
