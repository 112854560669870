/* eslint-disable no-unused-expressions */
import { CMS_URL } from 'constants/apiConf';
import { CMS_MESSAGES } from 'constants/cms';
import { useEffect, useMemo, useState } from 'react';

const useCmsPreview = (waitFor) => {
  const isPreview = useMemo(() => new URLSearchParams(window.location.search).get('previewMode') || false, []);

  const [value, setValue] = useState(
    typeof waitFor === 'string'
      ? null
      : (waitFor || []).reduce((acc, type) => ({ ...acc, [type]: null }), {}),
  );

  const sendMessage = (type, payload = {}) => {
    window.parent.postMessage({ type, payload }, CMS_URL);
  };

  useEffect(() => {
    const onMessageRecieved = (event) => {
      const message = event?.data || {};
      const oneType = typeof (waitFor) === 'string';
      if (
        ((oneType && message?.type === waitFor) || (!oneType && waitFor.includes(message?.type)))
        && message?.payload
      ) {
        try {
          const newValue = JSON.parse(message.payload);
          setValue(oneType ? newValue : { ...value, [message.type]: JSON.parse(message.payload) });
        } catch (e) {
          /* */
        }
      }
    };

    if (isPreview) {
      window.addEventListener('message', onMessageRecieved);
      setTimeout(() => {
        sendMessage(CMS_MESSAGES.PREVIEW_READY, { component: waitFor });
      }, 500);
    }
    return () => {
      isPreview && window.removeEventListener('message', onMessageRecieved);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { value, isPreview, sendMessage };
};

export default useCmsPreview;
