import PropTypes from 'prop-types';
import ModalWrapper from 'components/ModalWrapper';
import * as animationData from 'assets/animations/done.json';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import './styles.scss';
import Lottie from 'react-lottie';
import { useEffect } from 'react';

function Modal({
  children,
  onCloseModal,
  className,
  title,
  closeOnBackgroundClick,
  noContentPadding,
  darkBackground,
  showCloseButton,
  showSuccessIcon,
  zIndex,
  fullScreen,
  bottomExtraContent,
  position,
  animation,
}) {
  const isTitleVisible = title !== undefined;
  const fullScreenClass = fullScreen ? 'generic-modal--full-screen' : '';
  const mainContentPadding = !isTitleVisible ? 'generic-modal__main-content--full-padding' : '';
  const mainContentNoPadding = noContentPadding ? 'generic-modal__main-content--no-content-padding' : '';

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  return (
    <ModalWrapper
      onClose={() => closeOnBackgroundClick && onCloseModal()}
      darkBackground={darkBackground}
      zIndex={zIndex}
      position={position}
    >
      <section
        className={`generic-modal ${fullScreenClass} ${className} ${animation}`}
      >
        {isTitleVisible ? (
          <div className='generic-modal__header-content'>
            {title && <h2 className='generic-modal__title'>{title}</h2>}
            {showCloseButton && (
              <button className='generic-modal__close-button' type='button' onClick={onCloseModal}>
                <CloseIcon />
              </button>
            )}
          </div>
        ) : (showCloseButton && (
          <div className='generic-modal__header-content'>
            <button className='generic-modal__close-button generic-modal__close-button--no-header' type='button' onClick={onCloseModal}>
              <CloseIcon />
            </button>
          </div>
        )
        )}
        <div className={
          `generic-modal__main-content ${mainContentPadding} ${mainContentNoPadding}`
        }
        >
          {showSuccessIcon && (
            <Lottie
              options={{
                loop: false,
                autoplay: true,
                animationData,
                rendererSettings: {
                  preserveAspectRatio: 'xMidYMid slice',
                },
              }}
              height={90}
              width={90}
            />
          )}
          {children}
        </div>
      </section>

      {bottomExtraContent && (
        <div className='generic-modal__bottom-extra-content'>
          {bottomExtraContent}
        </div>
      )}
    </ModalWrapper>
  );
}

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  className: PropTypes.string,
  title: PropTypes.string,
  closeOnBackgroundClick: PropTypes.bool,
  noContentPadding: PropTypes.bool,
  darkBackground: PropTypes.bool,
  showCloseButton: PropTypes.bool,
  showSuccessIcon: PropTypes.bool,
  zIndex: PropTypes.number,
  fullScreen: PropTypes.bool,
  bottomExtraContent: PropTypes.node,
  position: PropTypes.oneOf(['center', 'start', 'end']),
  animation: PropTypes.oneOf(['', 'slide-up-enter']),
};
Modal.defaultProps = {
  className: '',
  title: undefined,
  closeOnBackgroundClick: true,
  noContentPadding: false,
  darkBackground: true,
  showCloseButton: true,
  showSuccessIcon: false,
  zIndex: null,
  fullScreen: false,
  bottomExtraContent: null,
  position: 'center',
  animation: 'slide-up-enter',
};

export default Modal;
