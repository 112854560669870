import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link, useNavigate } from 'react-router-dom';
import LogoImg from 'assets/logos/logo.png';
import backIcon from 'assets/icons/back.svg';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import { ROUTE_PATH } from 'routes';
import { EVENT_TYPE_BACK_BUTTON } from 'utils/events';

const Header = (props) => {
  const {
    title,
    onBack,
    rightMenu,
    openRightMenu,
    onRightMenu,
  } = props;

  const navigate = useNavigate();

  useEffect(() => {
    const onEvent = () => {
      if (typeof onBack === 'function') {
        onBack();
      } else {
        navigate(-1);
      }
    };
    document.addEventListener(EVENT_TYPE_BACK_BUTTON, onEvent);
    return () => {
      document.removeEventListener(EVENT_TYPE_BACK_BUTTON, onEvent);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClickBack = () => {
    if (openRightMenu) {
      onRightMenu();
    }
    if (typeof onBack === 'function') {
      onBack();
    } else {
      navigate(-1);
    }
  };

  const renderBackButton = () => {
    if (!onBack) {
      return <div className='actions-left' />;
    }
    return (
      <div className='actions-left' onClick={handleClickBack}>
        <img src={backIcon} alt='back-icon' />
      </div>
    );
  };

  const renderLogo = () => {
    if (!title) {
      return (
        <Navbar.Brand>
          <Link to={ROUTE_PATH.HOME}>
            <img src={LogoImg} className='d-inline-block align-top main-logo' alt='Platform logo' />
          </Link>
        </Navbar.Brand>
      );
    }
    return (
      <Navbar.Text className='header-title'>
        <h3 className='mb-0'>{title}</h3>
      </Navbar.Text>
    );
  };

  const renderActions = () => {
    return (
      <div className='actions-right' onClick={onRightMenu}>
        {rightMenu && (
          <div className={`menu-icon ${openRightMenu ? 'active' : ''}`}>
            <span className='bar' />
            <span className='bar' />
            <span className='bar' />
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      <Navbar expand='lg' className='header-wrapper'>
        <Container>
          {renderBackButton()}
          {renderLogo()}
          {renderActions()}
        </Container>
      </Navbar>
      {/* <div className='header-space' /> */}
    </>
  );
};

Header.propTypes = {
  title: PropTypes.string.isRequired,
  onBack: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  rightMenu: PropTypes.bool,
  openRightMenu: PropTypes.bool,
  onRightMenu: PropTypes.func,
};

Header.defaultProps = {
  onBack: null,
  rightMenu: true,
  openRightMenu: false,
  onRightMenu: null,
};

export default Header;
