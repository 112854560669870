import Debugger from 'utils/debugger';
import BancomerApi from './repository';

export async function createReference(data) {
  try {
    const response = await BancomerApi.createReference(data);
    return response;
  } catch (error) {
    Debugger.error(error);
    return false;
  }
}

export async function createMultipleReference(totalAmount, orders) {
  try {
    const response = await BancomerApi.createMultipleReference({
      totalAmount,
      orders,
    });
    return response;
  } catch (error) {
    Debugger.error(error);
    return false;
  }
}

export function getBancomerPDF(externalOrder) {
  BancomerApi.getBancomerPDF(externalOrder)
    .then((blob) => {
      window.share('bancomer.pdf', blob, 'application/pdf');
    });
}
