/* eslint-disable no-unused-vars */
import ApiFetch from 'utils/apiFetch';

const ApiFetchInstance = ApiFetch();

export const URLS = {
  diamondsSimulate: '/valuations/diamonds/simulate',
  diamondsCatalog: '/valuations/diamonds/catalog',
};

export default {
  async diamondsSimulate(size, amount, clarity, colour, cut) {
    return ApiFetchInstance.get(`${URLS.diamondsSimulate}?size=${size}&amount=${amount}&clarity=${clarity}&colour=${colour}&cut=${cut}`);
  },
  async diamondsCatalog() {
    return ApiFetchInstance.get(URLS.diamondsCatalog);
  },
};
