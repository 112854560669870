/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import initialState from 'store/initialState';

export const cmsSlice = createSlice({
  name: 'cms',
  initialState: initialState.cms,
  reducers: {
    getPage: (state, action) => {
      state.pages = {
        ...state.pages,
        data: {
          ...state.pages.data,
          [action.payload]: {
            blocks: null,
            loaded: false,
            loading: true,
            error: null,
          },
        },
      };
    },
    getPopups: (state) => {
      state.popups = {
        ...state.popups,
        data: null,
        error: null,
        loaded: false,
        loading: true,
      };
    },
    getPageSuccess: (state, action) => {
      state.pages = {
        ...state.pages,
        data: {
          ...state.pages.data,
          [action.payload.path]: {
            blocks: action.payload.blocks,
            loaded: true,
            loading: false,
          },
        },
      };
    },
    getPopupsSuccess: (state, action) => {
      state.popups = {
        ...state.popups,
        data: action.payload,
        loaded: true,
        loading: false,
      };
    },
    getPageFail: (state, action) => {
      state.pages = {
        ...state.pages,
        data: {
          ...state.pages.data,
          [action.payload.path]: {
            blocks: null,
            loaded: true,
            loading: false,
            error: true,
          },
        },
      };
    },
    getPopupsFail: (state, action) => {
      state.popups = {
        ...state.popups,
        error: action.payload,
        loaded: false,
        loading: false,
      };
    },
    clearPage: (state) => {
      state.page = {
        ...initialState.cms.page,
      };
    },
    clearPopups: (state) => {
      state.popups = {
        ...initialState.cms.popups,
      };
    },
  },
});

export const cmsAction = cmsSlice.actions;

export default cmsSlice.reducer;
