export const LOGIN_TYPES = {
  CLIENT_ID: 'CLIENT_ID',
  EMAIL: 'EMAIL',
};

export const STEPS = {
  IDENTIFIER: 'identifier',
  VALIDATION: 'validation',
  CONTACT: 'contact',
  AUTH: 'auth',
};

export const OTP_TYPES = {
  EMAIL: 'EMAIL_VERIFICATION',
  SMS: 'SMS_VERIFICATION',
};

export const FORMS = {
  [STEPS.IDENTIFIER]: [
    {
      literal: 'ine',
      type: 'string',
      required: true,
      key: 'ine',
      maxLength: 20,
    },
    {
      literal: 'clientNumber',
      type: 'string',
      required: true,
      key: 'clientNumber',
      maxLength: 20,
    },
  ],
  [STEPS.CONTACT]: [
    {
      literal: 'name',
      type: 'text',
      required: true,
      key: 'firstname',
      maxLength: 240,
      pattern: /^[A-Za-zÀ-ÿ '-]+$/,
    },
    {
      literal: 'lastname',
      type: 'text',
      required: true,
      key: 'lastname',
      maxLength: 240,
      pattern: /^[A-Za-zÀ-ÿ '-]+$/,
    },
    {
      literal: 'cellNumber',
      type: 'tel',
      required: true,
      key: 'phone',
      maxLength: 30,
    },
    {
      literal: 'email',
      type: 'email',
      required: true,
      key: 'email',
      maxLength: 240,
    },
  ],
};
