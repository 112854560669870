import { ROUTE_PATH } from 'routes';
import Layout from 'components/Layout';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import CmsBlocks from 'components/CmsBlocks';

const Onboarding = () => {
  const { authenticated } = useSelector((state) => state.session);
  const navigate = useNavigate();

  useEffect(() => {
    if (authenticated) {
      navigate(ROUTE_PATH.HOME);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (authenticated) {
    return null;
  }

  return (
    <Layout>
      <div className='d-flex flex-column justify-content-between h-100'>
        <CmsBlocks />
      </div>
    </Layout>
  );
};

export default Onboarding;
