import SimpleCard from 'components/SimpleCard';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import { EFFECT_LIST_BOX_UP_IN_VIEW } from 'constants/effects';

const SimpleCardList = ({ title, cards, startIndex }) => {
  return (
    <div className='simple-card-list mb-4'>
      {title && <h3>{title}</h3>}
      <div>
        {cards.map((card, i) => (
          <motion.div
            key={`card-${i + startIndex}`}
            {...EFFECT_LIST_BOX_UP_IN_VIEW}
            custom={i}
          >
            <SimpleCard
              key={`simple-card-${i}`}
              {...card}
            />
          </motion.div>
        ))}
      </div>
    </div>
  );
};

SimpleCardList.propTypes = {
  title: PropTypes.string,
  cards: PropTypes.array,
  startIndex: PropTypes.number,
};

SimpleCardList.defaultProps = {
  title: '',
  cards: [],
  startIndex: 0,
};

export default SimpleCardList;
