/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import initialState from 'store/initialState';

export const llmsSlice = createSlice({
  name: 'llms',
  initialState: initialState.stores,
  reducers: {
    init: (state) => {
      state.automaticQuoter = {};
    },
    error: (state) => {
      state.automaticQuoter = {};
    },
    clearError: (state) => {
      state.automaticQuoter = {};
    },
    success: (state, action) => {
      state.automaticQuoter = action.payload;
    },
    clear: (state) => {
      state.automaticQuoter = {};
    },
  },
});

export const llmsActions = llmsSlice.actions;

export default llmsSlice.reducer;
