export const PAWNED_CATEGORY = {
  WATCHES: 'Relojes',
  JEWELRY: 'Alhajas',
  CARS: 'Autos',
  DIAMONDS: 'Diamantes',
};

export const RESPONSE_STATUS = {
  SUCCESS: 'success',
  ERROR: 'error',
};

export const OTP_LENGTH = window.OTP_LENGTH || 4;

export const PROVIDER_TYPE = {
  BANCOMER: 'BANCOMER',
  PAYCASH: 'PAYCASH',
};

export const CONTRACT_STATUS = {
  COMPLETED: 'COMPLETED',
  PENDING: 'PENDING',
  CANCELED: 'CANCELED',
  PAYMENT_SAP_PENDING: 'PAYMENT_SAP_PENDING',
  FAILED: 'FAILED',
};

export const CONTRACT = 'CONTRACT';
export const SECTION = 'SECTION';

export const PAYCASH_MAX_AMOUNT = 8000;
