import useLiterals from 'utils/hooks/useLiterals';
import shoppingCartImg from 'assets/images/shopping-cart.jpg';
import auctionImg from 'assets/images/auction.jpg';
import { Link } from 'react-router-dom';
import Box from 'components/UI/Box/Box';
import Button from 'components/UI/Button';

const Notifications = () => {
  const { shopping: shoppingLiterals } = useLiterals('home');

  return (
    <>
      <Box padding='sm' className='mb-3'>
        <div className='d-flex justify-content-between'>
          <div className='d-flex flex-column justify-content-between'>
            <div>
              <h2>{shoppingLiterals.onlineStore}</h2>
              <p className='mb-1 text-gray font-bold'>{shoppingLiterals.smartShopping}</p>
            </div>
            <Link className='font-bold'>
              {shoppingLiterals.visitStore}
            </Link>
          </div>
          <img src={shoppingCartImg} alt='shopping-cart' className='no-right-padding no-bottom-padding' />
        </div>
      </Box>
      <Box padding='sm' className='mb-3'>
        <h2>
          {`${shoppingLiterals.auction} `}
          <span style={{ color: '#AE7C32' }}>Gama Alta 1</span>
        </h2>
        <h2 className='font-regular'>16 de Enero 2024 - 10:00 A.M.</h2>
        <div className='d-flex justify-content-between gap-3'>
          <div className='d-flex flex-column justify-content-between w-100'>
            <div>
              <p className='mb-1 text-gray font-bold'>{shoppingLiterals.online}</p>
            </div>
            <div className='d-flex flex-column justify-content-between gap-2'>
              <Button size='xs' type='secondary' text={shoppingLiterals.register} />
              <Button size='xs' text={shoppingLiterals.catalog} />
            </div>
          </div>
          <img src={auctionImg} alt='auction' className='no-right-padding no-bottom-padding' />
        </div>
      </Box>
    </>
  );
};

export default Notifications;
