import Layout from 'components/Layout';
import { useParams } from 'react-router-dom';
import SETTINGS_SECTIONS from 'constants/settings';
import Settings from './components/Settings';
import Language from './components/Language';
import Notifications from './components/Notifications';
import BiometricAccess from './components/BiometricAccess';
import ModifyPassword from './components/ModifyPassword';
import SAPAssociate from './components/SAPAssociate';
import './styles.scss';

const SettingsList = () => {
  const { section } = useParams();

  const renderSection = () => {
    switch (section) {
      case SETTINGS_SECTIONS.GENERAL:
        return <Settings />;
      case SETTINGS_SECTIONS.LANGUAGE:
        return <Language />;
      case SETTINGS_SECTIONS.NOTIFICATIONS:
        return <Notifications />;
      case SETTINGS_SECTIONS.BIOMETRIC:
        return <BiometricAccess />;
      case SETTINGS_SECTIONS.MODIFY_PASSWORD:
        return <ModifyPassword />;
      case SETTINGS_SECTIONS.SAP_ASSOCIATE:
        return <SAPAssociate />;
      default:
        return <Settings />;
    }
  };

  return (
    <Layout
      bottomMenu
      onBack
    >
      <div className='settings'>
        {renderSection()}
      </div>
    </Layout>
  );
};

export default SettingsList;
