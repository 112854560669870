import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getPayments } from 'modules/contracts/actions';
import useLiterals from 'utils/hooks/useLiterals';
import Layout from 'components/Layout';
import { BOTTOM_MENU_ACTIONS } from 'components/Layout/components/BottomMenu';
import Box from 'components/UI/Box/Box';
import MenuSwitch from 'components/UI/MenuSwitch';
import PayCash from 'components/Paycash';
import { getPaycashPDF } from 'modules/payCash/actions';
import { getBancomerPDF } from 'modules/bancomer/actions';
import { formatDate } from 'utils/formatDate';
import { formatCurrency } from 'utils/formatCurrency';
import { ROUTE_PATH } from 'routes';
import { motion } from 'framer-motion';
import { EFFECT_LIST_BOX_UP_IN_VIEW } from 'constants/effects';
import PaymentBox from 'components/PaymentBox';
import {
  CONTRACT, CONTRACT_STATUS, PROVIDER_TYPE, SECTION,
} from 'constants/global';
import './styles.scss';
import { useContractOrSection } from 'utils/hooks/useContractOrSection';
import { getSectionNotePDF, getSectionPayments } from 'modules/sections/actions';
import Button from 'components/UI/Button';
import ErrorModal from 'components/FeedbackModals/ErrorModal';

const Payment = () => {
  const literals = useLiterals('contract');
  const contractPayLiterals = useLiterals('contractPay');
  const navigate = useNavigate();
  const params = useParams();
  const [payments, setPayments] = useState(null);
  const [pendingPayments, setPendingPayments] = useState(null);
  const [selectedOption, setSelectedOption] = useState('payments');
  const [selectedPayment, setSelectedPayment] = useState(null);
  const { type, contract, section } = useContractOrSection(ROUTE_PATH.CONTRACT, params.id);
  const [error, setError] = useState(false);

  const fetchPayments = () => {
    const functionGetPayments = type === CONTRACT ? getPayments : getSectionPayments;
    functionGetPayments(params.id, [CONTRACT_STATUS.COMPLETED, CONTRACT_STATUS.FAILED, CONTRACT_STATUS.CANCELED])
      .then((response) => {
        setPayments(response || []);
      }).catch(() => {
        setPayments([]);
      });
    functionGetPayments(params.id, [CONTRACT_STATUS.PAYMENT_SAP_PENDING, CONTRACT_STATUS.PENDING])
      .then((response) => {
        setPendingPayments((response || []).filter((p) => !(p.providerType === PROVIDER_TYPE.BANCOMER && p.status === CONTRACT_STATUS.PENDING)));
      }).catch(() => {
        setPendingPayments([]);
      });
  };

  useEffect(() => {
    fetchPayments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDownloadSectionNote = () => {
    if (type === SECTION) {
      getSectionNotePDF(params.id, selectedPayment.id).then((success) => {
        if (!success) {
          setError(literals.errorDownloadNote);
        }
      });
    }
  };

  const renderPayment = () => {
    if (selectedPayment?.providerType === PROVIDER_TYPE.PAYCASH) {
      return (
        <PayCash
          payment={selectedPayment}
          id={params.id}
          type={type}
          amount={selectedPayment?.amount}
          onClose={() => setSelectedPayment(null)}
          onDelete={fetchPayments}
          onDownloadSectionNote={handleDownloadSectionNote}
        />
      );
    }
    if (selectedPayment?.providerType === PROVIDER_TYPE.BANCOMER) {
      return (
        <div className='payment-resume'>
          <h1 className='mb-4 text-primary'>{contractPayLiterals.paymetResume}</h1>
          <div className='d-flex justify-content-between mb-2'>
            <h2 className='font-bold'>{contractPayLiterals.common[type.toLowerCase()]}</h2>
            <h3 className='text-gray'>{params.id}</h3>
          </div>
          <div className='d-flex justify-content-between mb-2'>
            <h2 className='font-bold'>{contractPayLiterals.date}</h2>
            <h3 className='text-gray'>{formatDate(selectedPayment?.createdAt, 'DD/MM/YYYY')}</h3>
          </div>
          <div className='d-flex justify-content-between mb-2'>
            <h2 className='font-bold'>{contractPayLiterals.paymentMethod2}</h2>
            <h3 className='text-gray font-bold'>{selectedPayment?.providerType}</h3>
          </div>
          <div className='d-flex justify-content-between mb-2'>
            <h2 className='font-bold'>{contractPayLiterals.status}</h2>
            <h3 className='text-gray font-bold'>{contractPayLiterals.statusValues[selectedPayment?.status] || selectedPayment?.status}</h3>
          </div>
          <div className='separator my-3' />
          <div className='d-flex justify-content-between mb-2'>
            <h2 className='font-bold'>{contractPayLiterals.totalAmount}</h2>
            <h3 className='text-primary font-bold'>{formatCurrency(selectedPayment?.amount)}</h3>
          </div>
          {type === SECTION && selectedPayment?.status === CONTRACT_STATUS.COMPLETED && (
            <div>
              <Button
                onClick={handleDownloadSectionNote}
                className='mt-4'
                type='primary'
                text={contractPayLiterals.downloadNote}
              />
            </div>
          )}
        </div>
      );
    }
    return null;
  };

  const renderPayments = () => {
    const actualPayments = selectedOption === 'payments' ? payments : pendingPayments;
    if (!actualPayments?.length) {
      return (
        <motion.div {...EFFECT_LIST_BOX_UP_IN_VIEW}>
          <Box padding='sm' className='mb-2'>
            <p className='text-center mt-3 mb-3'>
              {actualPayments === null ? literals.common.loading : contractPayLiterals.noPayments}
            </p>
          </Box>
        </motion.div>
      );
    }
    return actualPayments.sort((a, b) => {
      const dateA = new Date(a.createdAt);
      const dateB = new Date(b.createdAt);
      return dateB - dateA;
    }).map((payment, index) => (
      <motion.div key={`payment-${payment.id}`} {...EFFECT_LIST_BOX_UP_IN_VIEW} custom={index}>
        <Box padding='sm' className='mb-2' onClick={() => setSelectedPayment(payment)}>
          <div className='d-flex justify-content-between'>
            <div>
              <div className='font-bold'>{`Pago ${actualPayments.length - index}`}</div>
              <div className='font-size-sm text-gray'>{formatDate(payment.createdAt, 'DD/MM/YYYY')}</div>
            </div>
            <div>
              <div className='font-bold text-end'>{formatCurrency(payment.amount)}</div>
              <div className='font-size-sm text-gray text-end'>{contractPayLiterals.statusValues[payment.status] || payment.status}</div>
            </div>
          </div>
        </Box>
      </motion.div>
    ));
  };

  const handleGoToPayment = (id) => {
    navigate(
      type === CONTRACT
        ? ROUTE_PATH.setContractPayment(id)
        : ROUTE_PATH.setSectionPayment(id),
    );
  };

  const handleBack = () => {
    if (selectedPayment) {
      setSelectedPayment(null);
      return;
    }
    navigate(-1);
  };

  const handleDownloadPDF = () => {
    if (selectedPayment?.providerType === PROVIDER_TYPE.PAYCASH) {
      getPaycashPDF(selectedPayment.externalOrderString);
      return;
    }
    if (selectedPayment?.providerType === PROVIDER_TYPE.BANCOMER) {
      getBancomerPDF(selectedPayment.externalOrderString);
    }
  };

  const renderContractSectionInfo = () => {
    const paymentDone = contract?.seRealizoPago || section?.paymentDone;
    return (
      <PaymentBox
        type={contract ? CONTRACT : SECTION}
        data={contract || section}
        action={handleGoToPayment}
        showAction={contract?.OpcionesPago?.length > 0 || type === SECTION}
        actionDisabled={paymentDone}
        actionText={paymentDone ? contractPayLiterals.onlyOnePayment : null}
      />
    );
  };

  return (
    <Layout
      title={literals.title}
      bottomMenu={selectedPayment && selectedPayment?.status === CONTRACT_STATUS.COMPLETED ? {
        type: BOTTOM_MENU_ACTIONS.DOWNLOAD,
        onClick: handleDownloadPDF,
      } : true}
      onBack={handleBack}
    >
      <div className='contract__container'>
        {selectedPayment && (
          renderPayment()
        )}
        {!selectedPayment && (
          <>
            {renderContractSectionInfo()}
            <MenuSwitch
              selected={selectedOption}
              options={[
                { label: literals.payments, value: 'payments' },
                { label: literals.pendingPayments, value: 'pendingPayments', notification: pendingPayments?.length || null },
              ]}
              onChange={setSelectedOption}
            />
            {renderPayments()}
          </>
        )}
      </div>
      {error && (
        <ErrorModal
          title={error}
          onClose={() => setError(false)}
        />
      )}
    </Layout>
  );
};

export default Payment;
