import { useState } from 'react';
import Input from 'components/UI/Input';
import Button from 'components/UI/Button';
import useLiterals from 'utils/hooks/useLiterals';
import { recoverPassword } from 'modules/session/actions';
import Layout from 'components/Layout';
import Select from 'components/UI/Select';
import SuccessModal from 'components/FeedbackModals/SuccessModal';
import ErrorModal from 'components/FeedbackModals/ErrorModal';

const IDENTIFIER = {
  email: 'email',
  phone: 'phone',
};

const STATUS = {
  SUCCESS: 'success',
  ERROR: 'error',
};

const RecoverPassword = () => {
  const literals = useLiterals('auth');

  const [form, setForm] = useState({ identifier: IDENTIFIER.email, phone: '', email: '' });
  const [status, setStatus] = useState(null);
  const [sending, setSending] = useState(false);

  const handleChange = (attr, value) => {
    setForm({ ...form, [attr]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus(null);
    const data = {
      identifier: form.identifier,
      [form.identifier]: form[form.identifier],
    };
    setSending(true);
    const success = await recoverPassword(data);
    setSending(false);
    setStatus(success ? STATUS.SUCCESS : STATUS.ERROR);
  };

  const formValid = () => {
    const { identifier } = form;
    return !!form[identifier];
  };

  return (
    <Layout onBack>
      <h1 className='mb-2'>{literals.recoverPassword.restorePassword}</h1>
      <p className='mb-4'>{literals.recoverPassword.enterFields}</p>
      <form className='form-wrapper' onSubmit={handleSubmit}>
        <Select
          required
          value={form.identifier}
          onChange={(v) => handleChange('identifier', v)}
          label={literals.common.identifier}
          placeholder={literals.common.identifier}
          className='mb-4'
          options={[
            { value: IDENTIFIER.email, label: literals.common.email },
            { value: IDENTIFIER.phone, label: literals.common.phone },
          ]}
        />
        {form.identifier === IDENTIFIER.email ? (
          <Input
            type='email'
            required
            value={form.email}
            onChange={(v) => handleChange(form.identifier, v)}
            label={literals.common.email}
            placeholder={literals.common.email}
            autoComplete='email'
            className='mb-3'
          />
        ) : (
          <Input
            type='phone'
            required
            value={form.phone}
            onChange={(v) => handleChange(form.identifier, v)}
            label={literals.common.phone}
            placeholder={literals.common.phone}
            autoComplete='phone'
            className='mb-3'
          />
        )}
        <div className='pt-5 mb-5'>
          <Button
            disabled={!formValid()}
            loading={sending}
            isSubmitBtn
            text={literals.recoverPassword.restore}
          />
        </div>
      </form>
      {status === STATUS.SUCCESS && (
        <SuccessModal
          title={literals.recoverPassword.successTitle}
          description={literals.recoverPassword.successDescription}
          onClose={() => setStatus(null)}
        />
      )}
      {status === STATUS.ERROR && (
        <ErrorModal
          description={literals.recoverPassword.errorDescription}
          onClose={() => setStatus(null)}
        />
      )}
    </Layout>
  );
};

export default RecoverPassword;
