import PropTypes from 'prop-types';
import Button from './Button';

const Buttons = ({ list, className }) => {
  return (
    <div className={`d-flex flex-column gap-3 mb-3 ${className}`}>
      {list.map((button) => (
        <Button key={button.id} {...button} />
      ))}
    </div>
  );
};

Buttons.propTypes = {
  list: PropTypes.array.isRequired,
  className: PropTypes.string,
};

Buttons.defaultProps = {
  className: '',
};

export default Buttons;
