import PropTypes from 'prop-types';
import useLiterals from 'utils/hooks/useLiterals';
import { formatDistance } from 'utils/distance';
import { PAWNED_CATEGORY } from 'constants/global';
import watchIcon from 'assets/icons/watch.svg';
import ringIcon from 'assets/icons/ring.svg';
import carIcon from 'assets/icons/car.svg';

const NearestStores = ({
  className,
  nearests,
  size,
  ignoreFirst,
  showTypes,
  onClick,
}) => {
  const literals = useLiterals('home');

  if ((nearests?.length || 0) - (ignoreFirst ? 1 : 0) <= 0) {
    return null;
  }
  const auxSize = size + (ignoreFirst ? 1 : 0);

  const renderPawnedCategories = (branches) => {
    return (branches || '').split(',').map((item, index) => {
      let imgSrc = '';
      switch (item.trim()) {
        case PAWNED_CATEGORY.WATCHES:
          imgSrc = watchIcon;
          break;
        case PAWNED_CATEGORY.JEWELRY:
          imgSrc = ringIcon;
          break;
        case PAWNED_CATEGORY.CARS:
          imgSrc = carIcon;
          break;
        default:
          return null;
      }
      return (
        <img key={index} src={imgSrc} alt='product-icon' />
      );
    });
  };

  return (
    <div className={`shop-box-list ${className}`}>
      <p className='font-bold font-size-sm mb-2'>{literals.nearbyShops}</p>
      <ul>
        {nearests.map((shop, index) => {
          if (index >= auxSize || (index === 0 && ignoreFirst)) return null;
          let name = shop.name || shop.address;
          if (name?.length > 50) {
            name = `${name.slice(0, 50)}...`;
          }
          return (
            <li key={shop.id}>
              <div className='shop-box-list-item mb-1 flex flex-items-center' onClick={() => onClick(shop)}>
                <div>
                  <p className='text-primary font-bold d-block mb-1'>{name}</p>
                  <p className='text-gray mb-0 font-size-sm'>
                    {`a ${formatDistance(shop.distance)}`}
                  </p>
                </div>
                {showTypes && (
                  <div className='shop-box-list-item-products'>
                    {renderPawnedCategories(shop.branches)}
                  </div>
                )}
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

NearestStores.propTypes = {
  className: PropTypes.string,
  nearests: PropTypes.array,
  size: PropTypes.number,
  ignoreFirst: PropTypes.bool,
  showTypes: PropTypes.bool,
  onClick: PropTypes.func,
};

NearestStores.defaultProps = {
  className: '',
  nearests: [],
  size: 3,
  ignoreFirst: false,
  showTypes: false,
  onClick: () => { },
};

export default NearestStores;
