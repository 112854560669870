import { useState, useEffect } from 'react';

const useLocalStorage = (key, defaultValue = null, isObject = true) => {
  const lsKey = `mls-${key}`;
  const [value, setValue] = useState(() => {
    let currentValue;

    try {
      currentValue = localStorage.getItem(lsKey) || defaultValue || (isObject ? '{}' : '');
      if (isObject && typeof currentValue === 'string') {
        currentValue = JSON.parse(currentValue);
      }
    } catch (error) {
      currentValue = defaultValue;
    }

    return currentValue;
  });

  const auxSetValue = (v) => {
    const newValue = (typeof v === 'function') ? v(value) : v;
    setValue(newValue);
    localStorage.setItem(lsKey, isObject ? JSON.stringify(newValue) : newValue);
  };

  useEffect(() => {
    localStorage.setItem(lsKey, isObject ? JSON.stringify(value) : value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [value, auxSetValue];
};

export default useLocalStorage;
