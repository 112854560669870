import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import useLiterals from 'utils/hooks/useLiterals';
import ErrorLayout from './components/ErrorLayout';
import './styles.scss';

const Error = () => {
  const literals = useLiterals('pageError');
  const params = useParams();
  const status = params?.status;
  const availableStatus = ['500', '401', '404', '400'];
  const { boundaryError } = useSelector((state) => state.app);

  if (!availableStatus.includes(status)) {
    return (
      <ErrorLayout
        title={literals.unknownStatus}
        status={status}
        error={boundaryError}
        literals={literals}
      />
    );
  }
  if (status && status === '500') {
    return (
      <ErrorLayout
        title={literals.title500}
        status={status}
        error={boundaryError}
        literals={literals}
      >
        <p>{literals.description500}</p>
      </ErrorLayout>
    );
  }

  return (
    <ErrorLayout
      title={literals[`title${status}`] || `title${status}`}
      status={status}
      error={boundaryError}
      literals={literals}
    />
  );
};

export default Error;
