/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable no-use-before-define */
import {
  useEffect, useMemo, useRef, useState,
} from 'react';
import { useDispatch } from 'react-redux';
import useLiterals from 'utils/hooks/useLiterals';
import { useSearchParams, useNavigate } from 'react-router-dom';
import Webcam from 'react-webcam';
import Layout from 'components/Layout';
import { QUOTER_TYPE } from 'constants/services';
import { BOTTOM_MENU_ACTIONS } from 'components/Layout/components/BottomMenu';
import { ROUTE_PATH } from 'routes';
import { getCameraPermission } from 'utils/events';
import { llmsIdentifyPhoto } from 'modules/llms/actions';
import { PAWNED_CATEGORY } from 'constants/global';
import SuccessModal from 'components/FeedbackModals/SuccessModal';
import ErrorModal from 'components/FeedbackModals/ErrorModal';
import Button from 'components/UI/Button';
import ScanIcon from 'assets/icons/scanIcon.svg';
import { ReactComponent as CarIcon } from 'assets/icons/car-2.svg';
import { ReactComponent as WatchIcon } from 'assets/icons/watch.svg';
import { ReactComponent as RingIcon } from 'assets/icons/ring.svg';
import { ReactComponent as DiamonIcon } from 'assets/icons/diamon.svg';
import Modal from 'components/Modal';
import './styles.scss';

const AutomaticQuoter = () => {
  const webcamRef = useRef(null);
  const [search] = useSearchParams();
  const from = search.get('from');
  const literals = useLiterals('automaticQuoter');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [permissions, setPermissions] = useState(null);
  const [error, setError] = useState(null);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [llmResult, setLlmResult] = useState(null);
  const [showCamera, setShowCamera] = useState(false);
  const [image, setImage] = useState(null);
  const [loadingScan, setLoadingScan] = useState(false);
  const [showInfoModal, setShowInfoModal] = useState(true);

  useEffect(() => {
    if (!showInfoModal) {
      getCameraPermission()
        .then((permission) => {
          setPermissions(permission);
        });
    }
  }, [showInfoModal]);

  useEffect(() => {
    // if (showCamera) {
    //   dispatch(loadingActions.hide());
    // } else {
    //   dispatch(loadingActions.show());
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showCamera]);

  const base64ToBlob = (base64String, contentType) => {
    const byteCharacters = atob(base64String.split(',')[1]);
    const byteArrays = [];

    for (let i = 0; i < byteCharacters.length; i += 1) {
      byteArrays.push(byteCharacters.charCodeAt(i));
    }

    const byteArray = new Uint8Array(byteArrays);
    return new Blob([byteArray], { type: contentType });
  };

  const takePhoto = async () => {
    if (webcamRef.current) {
      const imageSrc = webcamRef.current.getScreenshot();
      if (imageSrc) {
        const blob = base64ToBlob(imageSrc, 'image/jpeg');
        setImage(imageSrc);
        setLoadingScan(true);
        dispatch(llmsIdentifyPhoto(blob))
          .then((result) => {
            setLlmResult(result);
            setLoadingScan(false);
            if (Object.values(PAWNED_CATEGORY).includes(result?.visionChatbot?.type) || (result?.visionChatbot?.subtype && result?.visionChatbot?.subtype !== 'Otros')) {
              setShowSuccessModal(true);
            } else {
              setError(literals.errorIdentifyingPhoto);
            }
          })
          .catch(() => {
            setError(literals.errorIdentifyingPhoto);
            setLoadingScan(false);
          });
      }
    }
  };

  const handleUserMedia = () => {
    setTimeout(() => {
      setShowCamera(true);
    }, 300);
  };

  const title = useMemo(() => {
    switch (from) {
      case ROUTE_PATH.setQuoter(QUOTER_TYPE.CARS):
        return literals.takePhotoCars;
      case ROUTE_PATH.setQuoter(QUOTER_TYPE.DIAMONDS):
        return literals.takePhotoDiamonds;
      case ROUTE_PATH.setQuoter(QUOTER_TYPE.WATCHES):
        return literals.takePhotoWatches;
      case ROUTE_PATH.setQuoter(QUOTER_TYPE.JEWELRY):
        return literals.takePhotoJewelry;
      default:
        return literals.takePhoto;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [from]);

  const renderSuccessModalContent = () => {
    if (llmResult?.visionChatbot?.type === PAWNED_CATEGORY.CARS) {
      return (
        <div>
          <h2>{literals.vehucleIdentified}</h2>
          <div className='text-center p-3'>
            {llmResult?.brand?.Nombre && (
              <>
                <div className='text-primary font-bold mb-1'>{literals.brand}</div>
                <div className='text-gray mb-2'>{llmResult?.brand?.Nombre}</div>
              </>
            )}
            {llmResult?.model?.Nombre && (
              <>
                <div className='text-primary font-bold mb-1'>{literals.model}</div>
                <div className='text-gray mb-2'>{llmResult?.model?.Nombre}</div>
              </>
            )}
            {llmResult?.version?.Nombre && (
              <>
                <div className='text-primary font-bold mb-1'>{literals.version}</div>
                <div className='text-gray mb-2'>{llmResult?.version?.Nombre}</div>
              </>
            )}
            {llmResult?.visionChatbot?.year && (
              <>
                <div className='text-primary font-bold mb-1'>{literals.year}</div>
                <div className='text-gray mb-2'>{llmResult?.visionChatbot?.year}</div>
              </>
            )}
          </div>
        </div>
      );
    } if (llmResult?.visionChatbot?.type === PAWNED_CATEGORY.WATCHES) {
      return (
        <div>
          <h2>{literals.watchIdentified}</h2>
          <div className='text-center p-3'>
            {llmResult?.brand?.Descripcion && (
              <>
                <div className='text-primary font-bold mb-1'>{literals.brand}</div>
                <div className='text-gray mb-2'>{llmResult?.brand?.Descripcion}</div>
              </>
            )}
          </div>
        </div>
      );
    } if (llmResult?.visionChatbot?.type === PAWNED_CATEGORY.JEWELRY) {
      return (
        <div>
          <h2>{literals.jewelryIdentified}</h2>
          <div className='text-center p-3'>
            {llmResult?.brand?.Nombre && (
              <>
                <div className='text-primary font-bold mb-1'>{literals.brand}</div>
                <div className='text-gray mb-2'>{llmResult?.brand?.Nombre}</div>
              </>
            )}
          </div>
        </div>
      );
    } if (llmResult?.visionChatbot?.type === PAWNED_CATEGORY.DIAMONDS) {
      return (
        <div>
          <h2>{literals.diamondIdentified}</h2>
          <div className='text-center p-3'>
            {llmResult?.brand?.Nombre && (
              <>
                <div className='text-primary font-bold mb-1'>{literals.brand}</div>
                <div className='text-gray mb-2'>{llmResult?.brand?.Nombre}</div>
              </>
            )}
          </div>
        </div>
      );
    } if (llmResult?.visionChatbot?.type === 'Otros') {
      return (
        <div>
          <h2 dangerouslySetInnerHTML={{ __html: literals.otherIdentified.replace('OTHER_SUBTYPE', llmResult?.visionChatbot?.subtype) }} />
          <div className='text-center p-3'>
            <span dangerouslySetInnerHTML={{ __html: literals.otherIdentifiedSubtype.replace('OTHER_SUBTYPE', llmResult?.visionChatbot?.subtype) }} />
            {llmResult?.visionChatbot?.brand && llmResult?.visionChatbot?.brand !== 'Otros' && (
              <span dangerouslySetInnerHTML={{ __html: literals.otherIdentifiedBrand.replace('OTHER_BRAND', llmResult?.visionChatbot?.brand) }} />
            )}
            {llmResult?.visionChatbot?.model && llmResult?.visionChatbot?.model !== 'Otros' && (
              <span dangerouslySetInnerHTML={{ __html: literals.otherIdentifiedModel.replace('OTHER_MODEL', llmResult?.visionChatbot?.model) }} />
            )}
          </div>
          <div className='px-3'>
            {literals.otherIdentifiedInfo}
          </div>
        </div>
      );
    }
    return null;
  };

  const handleCloseSuccessModal = () => {
    setShowSuccessModal(false);
    if (llmResult?.visionChatbot?.type === 'Otros') {
      navigate(ROUTE_PATH.STORES);
    } else if (llmResult?.visionChatbot?.type === PAWNED_CATEGORY.CARS) {
      navigate(ROUTE_PATH.setQuoter(QUOTER_TYPE.CARS));
    } else if (llmResult?.visionChatbot?.type === PAWNED_CATEGORY.WATCHES) {
      navigate(ROUTE_PATH.setQuoter(QUOTER_TYPE.WATCHES));
    } else if (llmResult?.visionChatbot?.type === PAWNED_CATEGORY.JEWELRY) {
      navigate(ROUTE_PATH.setQuoter(QUOTER_TYPE.JEWELRY));
    } else if (llmResult?.visionChatbot?.type === PAWNED_CATEGORY.DIAMONDS) {
      navigate(ROUTE_PATH.setQuoter(QUOTER_TYPE.DIAMONDS));
    }
  };

  const renderCamera = () => {
    if (showSuccessModal || error) {
      return null;
    }
    if (loadingScan) {
      return (
        <Modal showCloseButton={false} closeOnBackgroundClick={false}>
          <div className='image-processing'>
            <div className='scan-animation'>
              <img src={ScanIcon} className='scan-icon' alt='' />
            </div>
            <img src={image} alt='' />
          </div>
          <p className='text-center mt-3'>
            {literals.scanningImage}
          </p>
        </Modal>
      );
    }
    if (permissions === true) {
      return (
        <Webcam
          key='webcam'
          width='100%'
          ref={webcamRef}
          onUserMedia={handleUserMedia}
          screenshotFormat='image/jpeg'
          videoConstraints={{
            facingMode: 'environment',
          }}
          style={{ visibility: !showCamera ? 'hidden' : 'visible' }}
        />
      );
    }
    return (
      <div className='text-center mt-5'>
        <p>{permissions === null ? literals.cameraPermissionPending : literals.cameraPermissionDenied}</p>
      </div>
    );
  };

  return (
    <Layout
      title={literals.camera}
      className='automatic-quoter'
      onBack
      bottomMenu={{
        type: BOTTOM_MENU_ACTIONS.AUTOMATIC_QUOTER,
        onClick: () => takePhoto(),
      }}
    >
      {showInfoModal && (
        <Modal
          title={literals.title}
          onCloseModal={() => setShowInfoModal(false)}
          position='end'
          className='w-100 p-3'
        >
          <div className='automatic-quoter__info-modal'>
            <div className='my-3'>{literals.subtitle}</div>
            <div className='d-flex gap-2 mb-2 benefit-container'>
              <CarIcon className='benefit-icon' width={30} height={30} />
              <div>{literals.cars}</div>
            </div>
            <div className='d-flex gap-2 mb-2 benefit-container'>
              <WatchIcon className='benefit-icon' width={30} height={30} />
              <div>{literals.watches}</div>
            </div>
            <div className='d-flex gap-2 mb-2 benefit-container'>
              <RingIcon className='benefit-icon' width={30} height={30} />
              <div>{literals.jewelry}</div>
            </div>
            <div className='d-flex gap-2 mb-2 benefit-container'>
              <DiamonIcon className='benefit-icon' width={30} height={30} />
              <div>{literals.diamonds}</div>
            </div>
            <p className='mt-3 text-gray-dark font-size-sm'>
              {literals.infoStorageImages}
            </p>
            <Button
              text={literals.common.continue}
              onClick={() => setShowInfoModal(false)}
              className='w-100 mt-3'
            />
          </div>
        </Modal>
      )}
      <h1 className='mt-0'>{title}</h1>
      {renderCamera()}
      <div className='camera-preview-border-tl' />
      <div className='camera-preview-border-tr' />
      <div className='camera-preview-border-br' />
      <div className='camera-preview-border-bl' />
      {showSuccessModal && (
        <SuccessModal
          onClose={handleCloseSuccessModal}
        >
          <>
            {renderSuccessModalContent()}
            <Button
              text={llmResult?.visionChatbot?.type !== 'Otros' ? literals.common.continue : literals.searchStore}
              className='w-100 mt-3'
              onClick={handleCloseSuccessModal}
            />
          </>
        </SuccessModal>
      )}
      {error && (
        <ErrorModal
          description={error}
          onClose={() => setError(null)}
        />
      )}
    </Layout>
  );
};

export default AutomaticQuoter;
