import { CONTRACT, SECTION } from 'constants/global';
import Debugger from 'utils/debugger';
import PayCashApi from './repository';

export async function createReference(paymentKey, monto, amount, type, id) {
  try {
    const response = await PayCashApi.createReference({
      paymentKey: paymentKey || null,
      monto,
      amount,
      contractType: type,
      contract_id: type === CONTRACT ? id : null,
      section_id: type === SECTION ? id : null,
    });
    return response;
  } catch (error) {
    Debugger.error(error);
    return false;
  }
}

export async function createMultipleReference(totalAmount, orders) {
  try {
    const response = await PayCashApi.createMultipleReference({
      totalAmount,
      orders,
    });
    return response;
  } catch (error) {
    Debugger.error(error);
    return false;
  }
}

export function getPaycashPDF(externalOrder) {
  PayCashApi.getPaycashPDF(externalOrder)
    .then((blob) => {
      window.share('paycash.pdf', blob, 'application/pdf');
    });
}

export async function deleteReference(referenceId) {
  try {
    const response = await PayCashApi.deleteReference(referenceId);
    return response;
  } catch (error) {
    Debugger.error(error);
    return false;
  }
}

export async function verifyHour() {
  try {
    const response = await PayCashApi.verifyHour();
    return response || true;
  } catch (error) {
    return false;
  }
}
