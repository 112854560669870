import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import useLiterals from 'utils/hooks/useLiterals';
import carsImg from 'assets/images/cars.jpg';
import Box from 'components/UI/Box/Box';
import Button from 'components/UI/Button';
import Select from 'components/UI/Select';
import StepsProgress from 'components/UI/StepsProgress';
import StoresMap from 'components/StoresMap';
import { ReactComponent as GarageIcon } from 'assets/icons/garage.svg';
import { ReactComponent as CalendarIcon } from 'assets/icons/calendar.svg';
import { ReactComponent as ClockIcon } from 'assets/icons/clock.svg';
import { ReactComponent as CarIcon } from 'assets/icons/car-2.svg';
import {
  getVehiclesYears,
  getVehiclesBrands,
  getVehiclesModels,
  getVehiclesVersions,
  getProducts,
} from 'modules/quotes/carsQuoter/actions';
import { useDispatch, useSelector } from 'react-redux';
import { cleanStore } from 'modules/stores/actions';

const QUOTER_SECTIONS = {
  YEAR: 'year',
  BRAND: 'brand',
  MODEL: 'model',
  VERSION: 'version',
  PRODUCT: 'product',
};

const STEPS = {
  INFORMATION: 0,
  STORE: 1,
  QUOTER: 2,
};

const CarsQuoter = (props) => {
  const {
    literals,
    form,
    loading,
    onChange,
    onSubmit,
  } = props;
  const literalsStores = useLiterals('stores');
  const { store } = useSelector((state) => state.stores);
  const { cars: literalsCars } = literals;
  const disabled = !form.year || !form.brand || !form.model || !form.version || !form.product;
  const [sectionLoading, setSectionLoading] = useState(false);
  const [years, setYears] = useState([]);
  const [brands, setBrands] = useState([]);
  const [models, setModels] = useState([]);
  const [versions, setVersions] = useState([]);
  const [step, setStep] = useState(STEPS.INFORMATION);
  const [products, setProducts] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchYears = async () => {
      setSectionLoading(QUOTER_SECTIONS.YEAR);
      const response = await getVehiclesYears();
      setYears(response);
      setSectionLoading(false);
    };
    fetchYears();
  }, []);

  useEffect(() => {
    if (form.year) {
      const fetchBrands = async () => {
        setSectionLoading(QUOTER_SECTIONS.BRAND);
        const response = await getVehiclesBrands(form.year);
        setBrands(response);
        setSectionLoading(false);
      };
      fetchBrands();
    }
  }, [form?.year]);

  useEffect(() => {
    if (form.year && form.brand) {
      const fetchModels = async () => {
        setSectionLoading(QUOTER_SECTIONS.MODEL);
        const response = await getVehiclesModels(form.year, form.brand);
        setModels(response);
        setSectionLoading(false);
      };
      fetchModels();
    }
  }, [form.year, form?.brand]);

  useEffect(() => {
    let brandName = '';
    if (form.brand && brands?.obj_data) {
      brandName = brands?.obj_data?.find((brand) => brand?.Clave === form.brand)?.Nombre;
    }
    onChange('brandName', brandName);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.brand, brands?.obj_data]);

  useEffect(() => {
    let modelName = '';
    if (form.model && models?.obj_data) {
      modelName = models?.obj_data?.find((model) => model?.Clave === form.model)?.Nombre;
    }
    onChange('modelName', modelName);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.model, models?.obj_data]);

  useEffect(() => {
    let versionName = '';
    if (form.version && versions?.obj_data) {
      versionName = versions?.obj_data?.find((version) => version?.Clave === form.version)?.Nombre;
    }
    onChange('versionName', versionName);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.version, versions?.obj_data]);

  useEffect(() => {
    if (form.year && form.brand && form.model) {
      const fetchVersions = async () => {
        setSectionLoading(QUOTER_SECTIONS.VERSION);
        const response = await getVehiclesVersions(form.year, form.brand, form.model);
        setVersions(response);
        setSectionLoading(false);
      };
      fetchVersions();
    }
  }, [form.year, form.brand, form.model]);

  useEffect(() => {
    if (form?.office) {
      getProducts(form.office?.id)
        .then((response) => {
          setProducts(response);
        });
    }
  }, [form?.office]);

  useEffect(() => {
    if (form.product) {
      const product = products?.obj_data?.find((p) => p?.ClaveProducto === form.product);
      onChange('productObject', product);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.product, products]);

  const renderInformation = () => {
    return (
      <>
        <h3 className='font-regular' dangerouslySetInnerHTML={{ __html: literalsCars.loanInfo }} />
        <div className='d-flex gap-2 mb-2 benefit-container'>
          <GarageIcon className='benefit-icon' width={30} height={30} />
          <div dangerouslySetInnerHTML={{ __html: literalsCars.loanBenefit1 }} />
        </div>
        <div className='d-flex gap-2 mb-2 benefit-container'>
          <CarIcon className='benefit-icon' width={30} height={30} />
          <div dangerouslySetInnerHTML={{ __html: literalsCars.loanBenefit2 }} />
        </div>
        <div className='d-flex gap-2 mb-2 benefit-container'>
          <CalendarIcon className='benefit-icon' width={30} height={30} />
          <div dangerouslySetInnerHTML={{ __html: literalsCars.loanBenefit3 }} />
        </div>
        <div className='d-flex gap-2 mb-2 benefit-container'>
          <ClockIcon className='benefit-icon' width={30} height={30} />
          <div dangerouslySetInnerHTML={{ __html: literalsCars.loanBenefit4 }} />
        </div>
        {/* <div className='d-flex gap-2 mb-2 benefit-container'>
          <CalculatorIcon className='benefit-icon' width={30} height={30} />
          <div dangerouslySetInnerHTML={{ __html: literalsCars.loanBenefit5 }} />
        </div> */}
        <Button
          type='primary'
          className='mt-4'
          text={literals.common.continue}
          onClick={() => setStep(STEPS.STORE)}
        />
      </>
    );
  };

  const renderStore = () => {
    return (
      <div>
        <StoresMap
          searcher={{
            title: literalsStores.search,
            placeholder: literalsStores.addressSearch,
          }}
          showMap={!store}
        />
        {store && (
          <>
            <Button
              type='secondary'
              className='mt-3'
              text={literalsStores.selectOther}
              onClick={() => {
                dispatch(cleanStore());
                window.scrollTo(0, 0);
              }}
            />
            <Button
              className='mt-3'
              text={literals.common.continue}
              onClick={() => {
                onChange('office', store);
                setStep(STEPS.QUOTER);
                window.scrollTo(0, 0);
              }}
            />
          </>
        )}
      </div>
    );
  };

  const renderQuoter = () => {
    return (
      <>
        <img className='mb-4 no-left-padding' src={carsImg} alt='cars' />
        <h2 className='mb-3'>{literalsCars.title}</h2>
        <p className='mb-4'>{literalsCars.info}</p>
        <form onSubmit={onSubmit}>
          <Select
            className='mb-4'
            value={form.year || ''}
            placeholder={literalsCars.year}
            onChange={(v) => onChange(QUOTER_SECTIONS.YEAR, v)}
            options={years?.obj_data?.map((year) => ({ value: year?.Clave, label: year?.Nombre }))}
            loading={sectionLoading === QUOTER_SECTIONS.YEAR}
            required
          />
          <Select
            className='mb-4'
            value={form.brand || ''}
            placeholder={literalsCars.brand}
            onChange={(v) => onChange(QUOTER_SECTIONS.BRAND, v)}
            options={brands?.obj_data?.map((brand) => ({ value: brand?.Clave, label: brand?.Nombre }))}
            loading={sectionLoading === QUOTER_SECTIONS.BRAND}
            required
          />
          <Select
            className='mb-4'
            value={form.model || ''}
            placeholder={literalsCars.model}
            onChange={(v) => onChange(QUOTER_SECTIONS.MODEL, v)}
            options={models?.obj_data?.map((model) => ({ value: model?.Clave, label: model?.Nombre }))}
            loading={sectionLoading === QUOTER_SECTIONS.MODEL}
            required
          />
          <Select
            className='mb-4'
            value={form.version || ''}
            placeholder={literalsCars.version}
            onChange={(v) => onChange(QUOTER_SECTIONS.VERSION, v)}
            options={versions?.obj_data?.map((version) => ({ value: version?.Clave, label: version?.Nombre }))}
            loading={sectionLoading === QUOTER_SECTIONS.VERSION}
            required
          />
          <Select
            className='mb-4'
            value={form.product || ''}
            placeholder={literalsCars.product}
            onChange={(v) => onChange(QUOTER_SECTIONS.PRODUCT, v)}
            options={products?.obj_data?.map((product) => {
              if (product?.DescripcionProducto !== 'PRODUCTO INACTIVO') {
                return { value: product?.ClaveProducto, label: product?.DescripcionProducto };
              }
              return null;
            }).filter(Boolean)}
            loading={sectionLoading === QUOTER_SECTIONS.PRODUCT}
            required
          />
          <Button isSubmitBtn type='primary' text={literalsCars.getQuote} disabled={disabled} loading={loading} />
        </form>
      </>
    );
  };

  return (
    <Box className='cars-quoter-box mb-3'>
      <StepsProgress
        className='mb-4 w-100'
        steps={[
          { label: literalsCars.information, value: STEPS.INFORMATION },
          { label: literalsCars.store, value: STEPS.STORE },
          { label: literalsCars.quoter, value: STEPS.QUOTER },
        ]}
        current={step}
      />
      {step === STEPS.INFORMATION && renderInformation()}
      {step === STEPS.STORE && renderStore()}
      {step === STEPS.QUOTER && renderQuoter()}
    </Box>
  );
};

CarsQuoter.propTypes = {
  literals: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default CarsQuoter;
