import Layout from 'components/Layout';
import useLiterals from 'utils/hooks/useLiterals';
import { useSelector } from 'react-redux';
import { EFFECT_LIST_BOX_UP_IN_VIEW } from 'constants/effects';
import CmsBlocks from 'components/CmsBlocks';
import { BOTTOM_MENU_ACTIONS } from 'components/Layout/components/BottomMenu';
import UserBox from './components/UserBox';
import ShopBox from './components/ShopBox';
import ContractsAndSections from './components/ContractsAndSections';
import './styles.scss';

const Home = () => {
  const literals = useLiterals('home');
  const { user, authenticated } = useSelector((state) => state.session);

  return (
    <Layout
      bottomMenu={{
        type: BOTTOM_MENU_ACTIONS.AUTOMATIC_QUOTER,
      }}
    >
      <div className='home'>
        <CmsBlocks animate={EFFECT_LIST_BOX_UP_IN_VIEW}>
          <UserBox
            user={user}
            literals={literals}
          />
          <ShopBox />
          {authenticated ? (
            <ContractsAndSections literals={literals} />
          ) : null}
        </CmsBlocks>
      </div>
    </Layout>
  );
};

export default Home;
