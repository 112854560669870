import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import 'styles/index.scss';
import App from 'routes/App';
import { store } from 'store';
import { Provider } from 'react-redux';
import 'utils/nativeScripts';
import { ENVIRONMENT } from 'constants/common';
import * as Sentry from '@sentry/react';
import * as serviceWorker from './serviceWorker';

if (window.SENTRY_DSN) {
  Sentry.init({
    dsn: window.SENTRY_DSN,
    environment: ENVIRONMENT || 'dev',
    tracesSampleRate: window.SENTRY_SAMPLE_RATE || 0,
  });
}

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
