import PropTypes from 'prop-types';
import Button from 'components/UI/Button';
import useLiterals from 'utils/hooks/useLiterals';
import Modal from 'components/Modal';
import { OTP_LENGTH } from 'constants/global';
import Translate from 'components/Translate';
import OtpInput from './OtpInput';

const OtpModal = ({
  onClose,
  show,
  otpType,
  otp,
  setOtp,
  handleVerifyOtp,
  isLoading,
}) => {
  const literals = useLiterals('auth');
  if (!show) return null;

  return (
    <Modal position='end' className='w-100' onCloseModal={onClose} animation='slide-up-enter'>
      <h1 className='text-primary mb-4'>{literals.otpModal.title}</h1>
      <Translate
        component='h3'
        className='font-regular mb-4'
        literal={literals.otpModal.info}
        vars={{ type: literals[otpType], length: OTP_LENGTH }}
      />
      <h3 className='mb-4'>{literals.otpModal.introduceCode}</h3>
      <OtpInput setOtp={setOtp} />
      <Button
        className='mt-5'
        onClick={handleVerifyOtp}
        loading={isLoading}
        text={literals.common.continue}
        disabled={!otp || otp.length < OTP_LENGTH}
      />
      <div className='mt-4 mb-5 text-end text-decoration-underline cursor-pointer'>
        {literals.otpModal.codeNotReceived}
      </div>
    </Modal>
  );
};

OtpModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  otpType: PropTypes.string.isRequired,
  setOtp: PropTypes.func.isRequired,
  handleVerifyOtp: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  otp: PropTypes.string,
};

OtpModal.defaultProps = {
  isLoading: false,
  otp: '',
};

export default OtpModal;
